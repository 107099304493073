<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>

        <div class="inner">
            <div id="content">
            <div class="content">
                <h4>{{selectedItem.cnName}}</h4>
                <div id="text">
                    <p style="text-align:center;"></p>
                    <p style="text-indent:2em;">
                        <span style="line-height:2;"></span><span style="line-height:2;">{{selectedItem.cnDescription}}</span>
                    </p>
                    <h4 >{{selectedItem.enName}}</h4>
                    <div id="texten">
                        <p style="text-align:center;"></p>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;"></span><span style="line-height:2;">{{selectedItem.enDescription}}</span>
                        </p>
                    </div>
                </div>

            </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "aboutSubsidiarylist",
        data() {
            return {
                selectedItem: null,
                subsidiaryItem: [
                    {
                        id: 1,
                        cnName:'耀华（秦皇岛）玻璃技术开发有限公司',
                        cnDescription:'公司成立于 2017 年 5 月\n' +
                            '\n' +
                            '注册资金 5 亿元人民币，现有员工 200 余人\n' +
                            '\n' +
                            '目前拥有一条 560t/d 在线 Low-E 节能智能制造生产线及配套家电深加工生产线，在国内首次采用宽板等速的先进技术，突破了生产薄板玻璃需降低拉引量的技术限制，属国内领先水平。公司具备生产 1.6mm 以上厚度高品质白玻和颜色玻璃以及钢化家电隔板玻璃、彩晶印刷玻璃的能力，产品广泛用于汽车、电子、高档制镜、光伏产业、家电产业玻璃等领域，深受国内外客户好评。\n' +
                            '\n' +
                            '2021 年获得国家科技部高新技术企业、国家工信部绿色工厂、河北省重污染天气环保绩效评级 A 级企业等殊荣。',
                        enName:'Yaohua (Qinhuangdao) Glass Technology Development Co., Ltd.',
                        enDescription:'The company was established in May 2017, with a registered capital of 500 million yuan and more than 200 employees.\n' +
                            '\n' +
                            'At present, it has a 560t/d online Low-E energy-saving intelligent manufacturing production line and supporting home appliance deep processing production line. It is the first time to adopt the advanced technology of wide plate constant speed in China, breaking through the technical limit of reducing the pulling amount in the production of sheet glass, and is a leading level in China. The company has the ability to produce high-quality clear glass and tinted glass with a thickness of more than 1.6mm, as well as tempered appliance partition glass and colorful glittering glass. The products are widely used in the fields of automobile, electronics, high-grade mirror making, photovoltaic industry, home appliance industry glass and so on, and are well received by domestic and foreign customers.\n' +
                            '\n' +
                            'In 2021, it won the titles of high-tech enterprise of the Ministry of Science and Technology of the People\'s Republic of China, Green Factory of the Ministry of Industry and Information Technology, and Grade A Enterprise of Environmental Performance Rating in Heavy Pollution Weather of Hebei Province.'
                    },
                    {
                        id: 2,
                        cnName:'耀华（秦皇岛）节能玻璃有限公司',
                        cnDescription:'公司成立于 2010 年 5 月\n' +
                            '\n' +
                            '    注册资金 7 亿余元人民币，占地面积 327 亩，现有员工 200 余人\n' +
                            '\n' +
                            '拥有一条 600t/d 优质浮法玻璃生产线。 主要产品包括粉红、灰（深灰、浅灰、耀华灰）、茶（金茶、欧茶）、福特蓝等本体着色玻璃及在线热反射膜玻璃。 依托秦皇岛北方天然不冻良港及玻璃产品集散地优势，出口直接或借助内支线便利，经由大连港或天津港，产品销往东南亚、欧洲、南美等近百个国家和地区。\n' +
                            '\n' +
                            '    目前，在建新园区项目占地 1000 亩，将重点发展优质透明浮法玻璃、高端颜色玻璃、特殊难熔玻璃及深加工产品等，打造全新的耀华玻璃新材料高新技术产业园。',
                        enName:'Yaohua (Qinhuangdao) Energy Saving Glass Co., Ltd.',
                        enDescription:'Founded in May 2010, the company has a registered capital of more than 700 million yuan, covers an area of 21.8 hectares, and has more than 200 employees.\n' +
                            '\n' +
                            '    The company has a 600t/d high-quality float glass production line. The main products include tinted glass and online heat reflective glass, such as pink, gray (dark gray, euro gray, Yaohua gray), bronze (golden bronze, euro bronze), ford blue, etc. Relying on the advantages of Qinhuangdao’s natural ice-free port and glass product distribution center, its products are sold directly or through the convenience of internal branch lines, via Dalian Port or Tianjin Port, the products are sold to nearly 100 countries and regions in Southeast Asia, Europe, and South America.\n' +
                            '\n' +
                            '    At present, the new park project under construction covers an area of 66.7 hectares. The company will focus on the development of high-quality clear float glass, high-end tinted glass, special refractory glass and deep-processing products, etc., to create a brand-new Yaohua Glass New Material High-tech Industrial Park.'
                    },
                    {
                        id: 3,
                        cnName:'河南省中联玻璃有限责任公司',
                        cnDescription:'   公司坐落于河南省商丘市，占地 523 亩，注册资金 5.17 亿元，总资产 25.3 亿元，员工 660 余人。\n' +
                            '\n' +
                            '    拥有两条 600 吨 / 日国家“二代浮法”示范线及配套深加工生产线。 多年来，以“创新、节能、绿色、智能化”为发展方向，围绕超薄、超白、超厚、超大系列产品形成自有特色。主导产品包括汽车玻璃、光伏玻璃等原片及离线 LOW-E 镀膜玻璃、中空玻璃、钢化玻璃、家电玻璃等，产品主要销往豫、鲁、苏、皖地区。\n' +
                            '\n' +
                            '    公司是国家高新技术企业，先后获得河南省绿色工厂、制造业头雁企业和“专精特新” 小巨人企业等荣誉称号。',
                        enName:'Henan Zhonglian Glass Co., Ltd.',
                        enDescription:'    The company is located in Shangqiu City, Henan Province, covering an area of 34.9 hectares, with a registered capital of 517 million yuan, total assets of 2.53 billion yuan, and more than 660 employees.\n' +
                            '\n' +
                            '    The company has two 600t/d national “second-generation float” demonstration lines and supporting deep processing production lines. Over the years, with the development direction of “innovation, energy saving, environmental protection and intelligent”, it has formed its own characteristics around series of products, for examples, ultra-thin, ultra-clear, ultra-thick and ultra-large. The leading products include automobile glass, photovoltaic glass and other original sheets, as well as offline Low-E coated glass, insulating glass, tempered glass, home appliance glass, etc. The products are mainly sold to Henan, Shandong, Jiangsu and Anhui.\n' +
                            '\n' +
                            '    The company is a national high-tech enterprise, and has won the honorary titles of “Province Green Factory”, “Manufacturing Leading Enterprise” and “ ‘Specialized, Special and New’ Little Giant Enterprise” in Henan Province.'
                    },
                    {
                        id: 4,
                        cnName:'洛玻集团洛阳龙昊玻璃有限公司',
                        cnDescription:'    公司成立于2005 年 6月，位于河南省洛阳市汝阳县大安工业区，占地 420 余亩， 注册资金 4.63 亿元。\n' +
                            '\n' +
                            '    专业从事高档浮法玻璃生产、销售，公司现有 600t/d、650t/d 生产线各一条，产品主要应用于汽车、镀膜、家电三大领域，涵盖光伏、电子及其他相关领域，产品质量通过 ISO9001:2015 质量管理体系认证，现已拥有 18 项专利。\n' +
                            '\n',
                        enName:'Luoyang Longhao Glass Co. Ltd.',
                        enDescription:'    Founded in June 2005, the company is located in Da’an Industrial Zone, Ruyang County, Luoyang City, Henan Province, covering an area of 28 hectares, with a registered capital of 463 million yuan.\n' +
                            '\n' +
                            '    The company specializes in the production and sales of high-grade float glass, and has two production lines of 600t/d and 650t/d. The products are mainly used in the three fields of automobile, coating and home appliances, covering photovoltaic, electronics and other related fields. The product quality has passed the ISO 9001:2015 quality management system certification, and now has 18 patents.'
                    },
                    {
                        id: 5,
                        cnName:'凯盛晶华玻璃有限公司',
                        cnDescription:'    凯盛晶华玻璃有限公司坐落于山东省德州市，占地面积 37 万平方米，资产总额 13 亿元。\n' +
                            '\n' +
                            '    现有 1 条 600T/D 浮法玻璃生产线，主要应用于汽车风挡、产业家电面板、高档门窗、高端制镜、发电玻璃背板等领域。 另在建 1 条 800T/D 特种玻璃生产线，采用了国内领先、世界一流的浮法玻璃二代技术，计划2022 年底竣工投产。\n' +
                            '\n' +
                            '    公司将致力于做优做强优质浮法玻璃产业，建设中国建材高新技术成果转化基地、中国建材高端玻璃示范基地、京津冀及雄安新区玻璃深加工和门窗配送基地，全力打响高端玻璃品牌。',
                        enName:'Triumph Jinghua Glass Co., Ltd.',
                        enDescription:'    The company is located in Dezhou City, Shandong Province. It covers an area of 370,000 square meters and has total assets of 1.3 billion yuan.\n' +
                            '\n' +
                            '    The company has a 600t/d float glass production line, which is mainly used in many fields, such as automobile windshield, industrial appliance panel, high-end doors and windows, high-grade mirror, power generation glass back plate, etc. Another 800t/d special glass production line is under construction, which adopts the domestic leading and world-class second-generation float glass technology, and is scheduled to be completed and put into operation by the end of 2022.\n' +
                            '\n' +
                            '    The company will be committed to improving and strengthening the high-quality float glass industry, building CNBM high-tech achievements transformation base, CNBM high-end glass demonstration base, Beijing-Tianjin-Hebei and Xiongan New Area glass deep processing and doors and Windows distribution base, promoting to build a high-end glass brand.'
                    },
                    {
                        id: 6,
                        cnName:'中建材佳星玻璃（黑龙江）有限公司',
                        cnDescription:'    中建材佳星玻璃（黑龙江）有限公司始建于 1982 年 5 月，前身为佳木斯工农玻璃厂，位于黑龙江省佳木斯市高新技术产业园区，企业占地面积 43 公顷，是黑龙江省“老字号”企业，2020 年被认定为省级“高新技术企业”，也是省内唯一一家浮法玻璃生产及深加工综合性企业，注册资金 5.6 亿元，资产总额 11 亿元，现有员工 550 人，其中专业技术人员 58 人，管理人员 99 人。\n' +
                            '\n' +
                            '    企业拥有 600t/d 浮法玻璃生产线 1 条，年产 1900 万平方米薄膜玻璃生产线 1 条，Low-E 玻璃生产线 1 条、钢化玻璃生产线 2 条、中空玻璃生产线 3 条、夹层玻璃生产线 1 条。并配套了多台套深加工玻璃切、磨、洗设备，可生产离线镀膜、平弯钢化、中空、夹胶、装饰五大类 30 多个品种的高档深加工产品。\n' +
                            '\n' +
                            '    产品覆盖黑龙江市场，辐射吉林、辽宁、内蒙古及俄罗斯远东地区，年产普通平板玻璃 88 万标箱 / 年，省内浮法玻璃市场占有率 64.65%，深加工玻璃省内市场占有率 40% 以上，外贸收入占总收入 5% 左右，处省内领先地位。',
                        enName:'CNBM Jiaxing Glass（Heilongjiang）Co.,Ltd.',
                        enDescription:'    The company was founded in May 1982, formerly known as Jiamusi Gongnong glass factory, and was located in Jiamusi high and new technology\n' +
                            '\n' +
                            'industrial park in Heilongjiang province. In addition, it is the “old” enterprise of Heilongjiang province, which was identified as the provincial “high-tech  enterprise” in 2020. It is also the only comprehensive enterprise in float glass production and deep processing in Heilongjiang. The enterprise covers an area of 43 acres with registered capital of 560 million yuan and total assets of 1.1 billion yuan. It has 550 employees, including 58 professional and technical personnel and 99 management personnel.\n' +
                            '\n' +
                            '    The company has a 600t/d float glass production line and a film glass production line with an annual output of 19 million square meters, a low-E glass production line, two tempered glass production lines, three insulating glass production lines and a laminated glass production line. Meanwhile, with the supporting of a number of deep processing equipment of cutting, grinding, washing, it can produce five categories of more than 30 varieties of high-grade deep processing products such as off-line coating, flat bending tempered glass, insulating glass, laminated glass and decorative glass.\n' +
                            '\n' +
                            '    The products cover Heilongjiang market and expand to Jilin, Liaoning, Inner Mongolia and the Far East of Russia. Annual output of ordinary flat glass is 880,000 Teus/year, accounting for 64.65% of the float glass market in Heilongjiang. Deep processing glass in the provincial market share of more than 40%, foreign trade income accounted for about 5% of the total income, which make it lead the market in the province.'
                    },
                    {
                        id: 7,
                        cnName:'耀华（宜宾）玻璃有限公司',
                        cnDescription:'    耀华（宜宾）玻璃有限公司成立于 2012 年 7 月，位于四川省宜宾市珙县余箐工业园区，占地 590 亩（余箐园区 450 亩，矿山洗选厂 140 亩），注册资金 2.22 亿元，现有员工 250 人，建设 900t/d+600t/d 优质浮法及深加工离线 Low-E 生产线各一条、配套年发电量 4000 万 KWH 余热发电项目和 50 万吨 / 年石英砂生产线。',
                        enName:'YAOHUA (YIBIN) GLASS CO., LTD.',
                        enDescription:'    The company was established in July 2012, located in Yuqing Industrial Park, Gongxian, Yibin City, Sichuan Province, covering an area of 39.3 hectares (30 hectares in Yuqing park and 10 hectares in Mine washing plant), with a registered capital of 222 million yuan and 250 employees. It has built a 900t/d + 600t/d high quality float glass production line and a deep processing off-line Low-E production line, and has a waste heat power generation project with annual generating capacity of 40 million KWH and a 500,000 tons/year quartz sand production line.'
                    },
                    {
                        id: 8,
                        cnName:'中建材（内江）玻璃高新技术有限公司',
                        cnDescription:'    公司位于四川省内江市资中县水南镇，隶属于中国建材集团凯盛科技公司，成立于 2012 年 6 月，是一家以低辐射（Low-E）节能玻璃、深加工玻璃生产制造为主的国家级高新技术企业。公司注册资金 6000 万元，总占地面积近 500 亩，现有员工 180 余名。拥有大型磁控溅射镀膜生产线且被纳入国家科技支撑计划，具有世界先进水平。拥有建筑幕墙工程和建筑装修装饰工程总承包资质，业务涵盖建筑节能玻璃、门窗幕墙及装饰工程。\n' +
                            '\n' +
                            '    主要产品：低辐射（Low-E）双银、三银节能玻璃、热反射镀膜玻璃、中空玻璃、钢化玻璃、夹层玻璃、高端防火玻璃、高硼硅玻璃、发电幕墙玻璃、家电玻璃、光伏背板玻璃等产品。',
                        enName:'CNBM (NEIJIANG) HI-TECH CO., LTD.',
                        enDescription:'    The company is a state-level high-tech enterprise specializing in the production and manufacturing of low-E energy-saving glass and deep processing glass which is located in Shuinan Town, Zizhong County, Neijiang City, Sichuan Province. It is affiliated to CNBM Triumph Co., Ltd and established in June 2012. The company has a registered capital of 60 million yuan, a total area of nearly 33.3 hectares, and more than 180 existing  employees. It has a large magnetron sputtering coating production line with the world\'s advanced level, which has been included in the national science  and technology support plan. With the building wall engineering and building decoration engineering general contracting qualification, the business of the company covers building energy-saving glass, door and window curtain wall and decoration engineering.\n' +
                            '\n' +
                            '    Main products: Low-E double silver, triple silver energy-saving glass, reflective coated glass, insulating glass, tempered glass, laminated glass, high-end fireproof glass, high borosilicate glass, powering curtain wall glass, home appliance glass, photovoltaic backplane glass and other products.'
                    },
                    {
                        id: 9,
                        cnName:'蚌埠兴科玻璃有限公司',
                        cnDescription:'    蚌埠兴科玻璃有限公司是凯盛光伏材料有限公司的全资子公司，现托管于中国耀华玻璃集团有限公司。公司成立于 1999 年，位于蚌埠市国家级高新技术产业开发区内，占地 175 亩，总投资 5.8 亿元 , 拥有两条世界一流大型磁控溅射镀膜生产线，配有国内先进的玻璃深加工设备，为建筑业及光伏行业提供各种优质玻璃产品，是中国建材集团在华东地区的低辐射（Low-E）节能玻璃、深加工玻璃和高效薄膜太阳能电池背电极的生产、研发基地。\n' +
                            '\n' +
                            '    主要产品：低辐射（Low-E）单银 / 双银节能玻璃、热反射镀膜玻璃、CIGS 薄膜太阳能电池背电极、Low-E 中空玻璃、普通中空玻璃、平 / 弯钢化玻璃、夹胶玻璃、防弹玻璃、防火玻璃、调光玻璃、LED 发光玻璃、电动中空百叶玻璃等。',
                        enName:'BENGBU XINGKE GLASS CO., LTD    ',
                        enDescription:'    The company is a wholly-owned subsidiary of Triumph Photovoltaic Materials Co., Ltd., which is now managed by China Yaohua Glass Group Co., Ltd. It is founded in 1999, and located in Bengbu National high-tech Industrial Development Zone, covering an area of 11.7 hectares, with a total investment of 580 million yuan. The company has two world-class large-scale magnetron sputtering coating production lines, equipped with domestic advanced glass deep processing equipment, providing various high-quality glass products for the construction industry and photovoltaic industry. It is the production and R&D base of Low-E glass, deeply processed glass and high-efficiency thin-film solar cell back electrode of China National Building Materials Group Corporation.\n' +
                            '\n' +
                            '    Main products: Low radiation (Low-E) single silver/double silver energy-saving glass, reflective coated glass, glass for CIGS thin-film solar cell module, Low-E insulating glass, ordinary insulating glass, flat/bent tempered glass, laminated glass, bulletproof glass, fireproof glass, switchable  transparent Glass, LED luminous glass, electric hollow shutter glass, etc.'
                    },
                    {
                        id: 10,
                        cnName:'凯盛晶华玻璃有限公司',
                        cnDescription:'    凯盛晶华玻璃有限公司坐落于山东省德州市，占地面积 37 万平方米，资产总额 13 亿元。\n' +
                            '\n' +
                            '    现有 1 条 600T/D 浮法玻璃生产线，主要应用于汽车风挡、产业家电面板、高档门窗、高端制镜、发电玻璃背板等领域。 另在建 1 条 800T/D 特种玻璃生产线，采用了国内领先、世界一流的浮法玻璃二代技术，计划2022 年底竣工投产。\n' +
                            '\n' +
                            '    公司将致力于做优做强优质浮法玻璃产业，建设中国建材高新技术成果转化基地、中国建材高端玻璃示范基地、京津冀及雄安新区玻璃深加工和门窗配送基地，全力打响高端玻璃品牌。\n' +
                            '\n',
                        enName:'Triumph Jinghua Glass Co., Ltd.',
                        enDescription:'    The company is located in Dezhou City, Shandong Province. It covers an area of 370,000 square meters and has total assets of 1.3 billion yuan.\n' +
                            '\n' +
                            '    The company has a 600t/d float glass production line, which is mainly used in many fields, such as automobile windshield, industrial appliance panel, high-end doors and windows, high-grade mirror, power generation glass back plate, etc. Another 800t/d special glass production line is under construction, which adopts the domestic leading and world-class second-generation float glass technology, and is scheduled to be completed and put into operation by the end of 2022.\n' +
                            '\n' +
                            '    The company will be committed to improving and strengthening the high-quality float glass industry, building CNBM high-tech achievements transformation base, CNBM high-end glass demonstration base, Beijing-Tianjin-Hebei and Xiongan New Area glass deep processing and doors and Windows distribution base, promoting to build a high-end glass brand.\n' +
                            '\n'
                    },
                    {
                        id: 11,
                        cnName:'蚌埠凯盛玻璃有限公司',
                        cnDescription:'    蚌埠凯盛玻璃有限公司位于蚌埠市淮上经济开发区，成立于 2021 年 11 月 1 日，注册资本 25000 万元，职工 290 人。\n' +
                            '\n' +
                            '    公司拥有一条日熔化 250 吨的高效薄膜太阳能电池高温玻璃原片生产线和两条玻璃深加工生产线，占地177.43 亩，主导产品为高效薄膜太阳能电池用高温玻璃，是世界上仅有的铜铟镓硒薄膜太阳能电池玻璃基板的供应商，产品远销德国、日本、台湾等光伏企业。\n' +
                            '\n' +
                            '    公司目前尚有 194 亩土地和 700t/d 的产能指标，计划建设一条 700t/d 一条薄膜电池用在线 TCO 玻璃及汽车玻璃生产线， 预计 2023 年建成并投入使用。\n' +
                            '\n' +
                            '    根据企业的发展，公司将通过升级产品结构，以颜色节能玻璃、工业显示玻璃、TCO 玻璃、汽车用玻璃为产品导向。',
                        enName:'Triumph Bengbu Glass Co., Ltd.',
                        enDescription:'    The company is located in Huaishang Economic Development Zone, Bengbu City, was established on November 1, 2021 with a registered capital of 250 million yuan and has 290 employees.\n' +
                            '\n' +
                            '    The company has a high-efficiency thin-film solar cell high temperature glass raw sheet production line and two glass deep processing production lines, covering an area of 11.83 hectares. Its main products are high temperature glass for high-efficiency thin-film solar cells, and it is the only supplier of glass substrates for copper-indium-gallium-selenide thin-film solar cells in the world. The products are exported to photovoltaic enterprises in Germany, Japan, Taiwan and so on.\n' +
                            '\n' +
                            '     At present, the company has 12.9 hectares of land and 700t/d production capacity. It plans to build a 700t/d online TCO glass and automobile glass production line for thin film batteries, which is expected to be completed and put into use in 2023.\n' +
                            '\n' +
                            '    According to its development , the company will upgrade its product structure to focus on tinted energy-saving glass, industrial display glass, TCO glass, and automotive glass.'
                    },
                    {
                        id: 12,
                        cnName:'秦皇岛弘华特种玻璃有限公司',
                        cnDescription:'    秦皇岛弘华特种玻璃有限公司成立于 2009 年，注册资金为 6547 万，员工人数为163人，前身为“秦皇岛耀华特种玻璃股份有限公司”，是国内第一家采用全电熔技术生产硼硅平板玻璃产品的高新技术企业。目前国内市场占有率行业排名第一。该生产线核心技术全部为自主研发，关键设备均为当前最先进的工业产品。\n' +
                            '\n' +
                            '    公司主导产品为硼硅平板玻璃，年生产能力能达到 16425 吨，产品以 3.3 硼硅平板玻璃为主。公司集硼硅平板玻璃生产、加工、销售、服务为一体，销售网络已遍及全国各地以及世界多个国家和地区。\n' +
                            '\n' +
                            '    弘华公司新园区位于秦皇岛市抚宁区，预计全年产能 17520 吨，计划生产 2.6 硼硅和 3.3 硼硅家电玻璃、4.0 硼硅防火玻璃，项目预计 2023 年底落成。\n' +
                            '\n',
                        enName:'Qinhuangdao Scinan Speicalty Glass Co., Ltd',
                        enDescription:'    The company was founded in 2009, with a registered capital of 65.47 million yuan and 163 employees, formerly known as “Qinhuangdao Yaohua Special Glass Co., LTD”. It is the first high-tech enterprise in China to produce borosilicate flat glass adopting all electric fusion technology. At present, it ranks first in the domestic market. The control technologies of the production line are all self-developed and the key equipment are the most advanced industrial products.\n' +
                            '\n' +
                            '    The company’s leading product is borosilicate flat glass, with an annual production capacity of 16,425 tons. The main products are 3.3 borosilicate flat glass. It integrates the production, processing, sales and service of borosilicate flat glass, and the sales network has spread all over the world.\n' +
                            '\n' +
                            '    Honghua’s new industrial park is located in Funing District, Qinhuangdao City, with an estimated annual production capacity of 17,520 tons. It plans to produce 2.6 borosilicate glass, 3.3 borosilicate home appliance glass, and 4.0 borosilicate fireproof glass. The project is expected to be completed by the end of 2023.'
                    },
                    {
                        id: 13,
                        cnName:'凤阳凯盛硅材料有限公司',
                        cnDescription:'    凤阳凯盛硅材料有限公司坐落在凤阳经济开发区产业园，成立于 2009 年 10 月 26 日，占地 200 亩，注册资金 3.33 亿元，职工人数 185 人。一期为硼硅酸盐特种玻璃生产线项目，2017 年 5 月开工建设，2019 年10 月顺利建成并投产第一条 50 吨 /d，年产 122 万平方米的硼硅酸盐特种玻璃生产线，主营产品为硼硅 4.0防火玻璃和硼硅 3.3 耐热玻璃。\n' +
                            '\n' +
                            '    公司硼硅防火玻璃原片生产线采用拥有自主知识产权的全氧燃烧 + 电助熔技术 + 铂金系统工艺技术，配备有适合硼硅玻璃生产的熔窑、锡槽、退火窑、冷端切割系统。\n' +
                            '\n' +
                            '    公司拟通过现有土地利用，共用现有公辅设施，新建一条熔化量30t/d 的全电熔硼硅 3.3 耐热玻璃生产线。目前二期新建项目各项流程在审批中，预计 2022 年 12 月底厂房主体可以竣工，2023 年 3 月份具备点火条件。',
                        enName:'Fengy Yang Triumph Silicon Materials Co., Ltd.',
                        enDescription:'    The company is located in Fengyang Economic Development Zone Industrial Park. It was established in October 2009, covering an area of 13.3 heacares, with a registered capital of 333 million yuan and 185 employees. The  phase I project is borosilicate special glass production line, which started construction in May 2017. In October 2019, the first borosilicate special glass production line of 50 t/d with an annual output of 1.22 million square meters was successfully completed and put into production. The main products are borosilicate 4.0 fireproof glass and borosilicate 3.3 heat-resistant  glass.\n' +
                            '\n' +
                            '    The company\'s borosilicate fireproof glass original production line adopts the technology of all-oxygen combustion + electric boosting technology + platinum system process with independent intellectual property rights, and is equipped with melting furnace, tin bath, annealing kiln and cold end cutting system suitable for it.\n' +
                            '\n' +
                            '    The company plans to build a fully electric fused borosilicate 3.3 heat-resistant glass production line with a melting capacity of 30t/d. At present, all processes of the new project phase II are under approval, and it is expected that the ignition conditions will be available in March 2023.'
                    }

                ],
                items: [
                    {image: '5e156e89e6e27.jpg', title: 'Title 1', description: 'Description 1'}
                ],
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
        created() {
            // 获取路由参数
            const id = this.$route.params.id; // 获取路由参数中的 id
            this.selectedItem = this.subsidiaryItem.find(item => item.id == id); // 根据 id 匹配对应的数据项
        }
    }
</script>

<style >

</style>
