<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="content">
                    <div id="title">
                        <h4>对行业（企业）发展的几点感悟</h4>
                        <span class="source">来源：</span>
                        <span class="date">发布时间：2019-11-12</span>
                    </div>
                    <div id="text">
                        <p><strong><span style="line-height:2;">1、从高速增长阶段转为高质量发展阶段</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">改革开放40年国民经济快速发展，史无前例的大规模经济建设为我国玻璃工业创造了千载难逢的发展机遇，行业发展取得令人瞩目的辉煌成就。但与此同时由于发展方式粗放和不可持续，也产生了制约进一步发展的深层次问题。特别是普通平板玻璃近年来上的太多，发展过快，导致供需失衡，产能严重过剩。由于过来的无序发展和盲目扩张导致了资源浪费、市场恶性竞争，企业效益低下的严重后果。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">为此我们应彻底摆脱“粗放”发展模式，不宜再扩大普通浮法产能规模，而应在“增品种、创品牌、提品质”上下功夫，化解需求与供给的矛盾，进而实现“转型升级，由高速增长阶段转为高质量发展阶段”，由“增量扩张向提质增效转变”，产品由“中低端迈向中高端”。</span></p>

                        <p><strong><span style="line-height:2;">2、强化行业自律，化解产能过剩，改善供求关系</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">强化以协会为主导的行业自律。协会是联系政府、企业、市场之间的桥梁和纽带。通过行业自律建立公平、透明、公正的市场环境。应深刻理解“行业利益高于企业利益，企业利益寓于行业利益之中”的辩证关系，尊重市场规律，遵守行规行约，尊重价值规律。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">彻底摆脱粗放的经济增长模式；严格控制新增产能；有效发挥规划、产业政策、标准的引导和约束作用；坚决淘汰环保不达标及低效产能，通过实施差别电价、水价、环保、税收等经济杠杆倒逼低效产能退出；充分发挥大企业作用，由大企业通过兼并重组整合市场。</span></p>

                        <p><strong><span style="line-height:2;">3、转型升级与结构调整</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">新常态下，我国经济面临增速放缓，需求不足，产能过剩。由于供给和需求不匹配、不协调、不平衡，导致低端产品严重过剩，而高端的有技术含量和市场竞争力的产品严重不足。企业急需结构调整和转型升级。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">当前转型升级、结构调整的重点应围绕“优质、节能、绿色环保、功能化和智能化”进行。一是改造提升传统产业，搞好现有生产线的改造升级，以适应新的产业政策和市场需求；二是培育壮大新兴产业，发展新型玻璃拓展新的功能。围绕发展“节能环保、电子玻璃、太阳能产业、特种玻璃等”开拓新领域，从“同质化”向“多元化、差异化”转型；三是大力发展生产性服务业。要在“研发、设计、咨询、规划”等产业前端和“流通、销售、检测、认证”等产业后端上下功夫，构筑完整产业链。</span></p>

                        <p><strong><span style="line-height:2;">4、有效推进节能减排，实现绿色发展</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">平板玻璃行业属热工生产，号称“矿业+窑业”，对资源、能源依赖性强，环境负荷大，“节能减排”问题突出。平板玻璃年耗能约1000万吨标煤，占全国工业能耗0.5%，属耗能大户；年排放SO2、NOX近30万吨左右。近年来国家对环保要求愈来愈严，环境制约增强，节能减排力度不断加大。随着国家对环保要求的不断提高，自2014年以来玻璃熔窑烟气治理进入了“快车道”。大多安装了治理设施，但距全面有效治理和达标排放还有一定差距。要实现“十三五”节能减排目标（单位产品能耗降低10%，污染物排放降低30%），必须抓好以下几方面工作，一是搞好结构调整；二是实施生产全过程节能减排技术；三是继续做好污染末端控制技术；四是落实政策保障。</span></p>

                        <p><strong><span style="line-height:2;">5、实施兼并重组，发展大集团</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">提高产业集中度有利于消化过剩产能，加快落后产能退出；有利于消除企业间过度竞争；有利于科技研发提升企业竞争力。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">我国玻璃行业虽然总量居世界第一，但生产过度分散。据国际惯例，按前4家统计，CR4＜30%为竞争型市场，CR4＜50%为低集中度，50%＜CR4＜80%为中集中度，CR4＞80%为高集中度，按此衡量我国CR4不足30%；如按前10家统计也不足60%，属低集中度竞争型市场。同质竞争严重，抗风险能力弱，缺少技术及市场引领能力强，品牌影响力强，具有国际竞争力的行业领军企业。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">我国“十三五”提高集中度目标为：“前10家浮法玻璃企业生产集中度达70%，有国际竞争力的知名企业3～5个”。要实现这一目标应重点做好：一是突破所有制限制，发展混合经济，积极探索和创新不同所有制之间的兼并重组；二是突破体制机制障碍，打破区域界线，实施企业之间跨地区兼并重组；三是探索联合重组的多种模式（即横向、纵向并购，大企业之间强强联合以及市场之间、研发成果之间的合作）；四是强化政策支持。优化信贷融资服务，开展多渠道融资，实施税收优惠政策，帮助优势企业解决“并购资金难”的问题。</span></p>

                        <p><strong><span style="line-height:2;">6、搞好“二代”研发，坚持创新驱动和人才培养</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">十八届五中全会指出“创新是引领发展的第一动力”；改革给企业带来活力，创新给企业带来动力！创新可使老企业焕发青春！</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">进入新时代以后，单靠“市场容量”的发展模式已经难以为继了，必须依靠创新驱动走“差异化、精细化”的道路，企业才能有效发展。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">“二代”浮法创新研发是我国玻璃行业转型升级、技术进步的重要支撑，代表着浮法生产全过程先进技术的集成和创新。其总任务是实现“一个目标、二个升级、三个飞跃”。一个目标即赶上超越国际先进及领先水平；二个升级即浮法玻璃行业整体技术与装备水平的全面升级，浮法玻璃产品质量和深加工率全面升级；三个飞跃即实现节能减排指标、经济技术指标、信息化管理水平达到国际先进及领先水平的飞跃。其创新研发的重点，一是传统产业升级换代技术；二是新产品研发；三是节能减排技术研发，促进企业向绿色低碳转型。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">与此同时应加强人才队伍建设，加大科技投入（达到大型骨干企业占比2%的要求）。因为企业竞争力的根本问题是人的竞争力，一个企业技术、产品、装备水平提升都要人来实现，如果一个企业人不行，企业肯定不行。企业应力争形成“产学研用”联合人才培养机制，推动各类创新要素，融合互动，不断提高企业科技创新能力和整体竞争力！</span></p>

                        <p><strong><span style="line-height:2;">7、不断加大改革力度</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">企业改革应沿着市场化方向，不断促使企业与市场相融合。一是企业所有制改造，发展“混改模式”，形成所有者、经营者、员工共享的企业创富机制；二是促进管理整合和机制创新；三是资本运作及联合重组。常言道：资本运作解决钱的问题，联合重组解决资源问题，管理整合解决效益问题，集成创新解决技术来源问题。</span></p>

                        <p><strong><span style="line-height:2;">8、从管理者到经营者</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">自上世纪九十年代之后，我国经济实施转型——由计划经济转向有中国特色社会主义市场经济。作为企业主要领导，必须从繁杂的日常管理工作转向核心经营工作，由“管理者转为经营者”。对企业来说，“管理和经营并重”，缺一不可。管理是基础，通过管理可降低成本、提高效率、保证质量；而经营层面需研究新的商业模式、转型路径、价格策略等。作为企业一把手必须把主要精力放在经营上，要更加重视“企业发展战略的研究、制定和实施”。</span></p>

                        <p><strong><span style="line-height:2;">9、重视企业文化建设</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">习近平总书记十九大报告指出“文化是一个国家一个民族的灵魂”。文化是企业的核心竞争力，也是企业的灵魂，没有文化就没有未来。企业应提倡“以厂为家”的文化，用“家文化”把大家紧紧凝聚在一起；企业党建工作也需高度融合“企业文化”。从历史看企业竞争的沿革过程：从原始的产品竞争，到技术竞争、营销模式竞争、品牌的竞争，最高层级是文化的竞争。一个重视企业文化的单位，企业职工一定充满朝气、团结向上，也一定是个讲诚信的企业，产品质量也一定有保证。“人才、品牌和企业文化”是企业持续发展的“三大支撑”。</span></p>


                        <table style="width:100%;" cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000">
                            <tbody>
                            <tr>
                                <td style="text-align:center;width:50%">
                                    <img src="@/../uploads/5eb4ba121c41e.jpg" title="5eb4ba121c41e.jpg" alt="5eb4ba121c41e.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:50%">
                                    <img src="@/../uploads/5eb4ba1963aa6.jpg" title="5eb4ba1963aa6.jpg" alt="5eb4ba1963aa6.jpg" width="" height="" align=""><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center;width:50%">
                                    <span>图为2002年耀华建厂80周年俱乐部门前合影</span><br>
                                </td>
                                <td style="text-align:center;width:50%">
                                    <span>图为九十年代初耀华升旗仪式</span><br>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <p><strong><span style="line-height:2;">10、实施“走出去”，拓展国外市场，稳步实现国际化</span></strong></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">目前我国玻璃行业虽有部分产品对外出口，以及对外开展设计、总承包等，但还没有像发达国家大跨国公司那样真正走向国际，实现全球布局。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">行业“十三五”规划确定的国际化目标为：“第二代浮法玻璃技术装备进军国际高端市场；2～3家企业实现全球布局；国外营业收入达到总收入10%以上；国外平板玻璃产能达5000万重箱”。为实现上述目标，一是鼓励企业依托国内资本与技术，利用国外资源和市场，在国外布局新的生产能力；二是支持具有国际竞争力的企业，通过资本市场联合重组国外企业，参与国际市场的投资与经营，更好地在全球范围内配置资源，实现从产品输出，技术输出到资本输出的不断升级，稳步实现国际化；三是从政策上积极争取世界银行项目贷款、技术援助贷款，中国政府对外无偿贷款、无息贷款和优惠贷款等资金支持。引导大型优势企业走向国际。</span></p>
                        <p style="text-indent:2em;"><span style="line-height:2;">特别是“一带一路”战略的实施为企业“创新转型”和实现国际化提供了更大平台和空间，国家全方位的为企业“走出去”鸣锣开道，是千载难逢的好机会。</span></p>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "aboutHistorylist4"
    }
</script>

<style scoped>

</style>
