import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Layout from '@/components/layout/Layout.vue'
import HomePage from '@/views/homepage/HomePage.vue';
import Aboutyaohua from "@/views/aboutyaohua/Aboutyaohua";
import Organization from "@/views/aboutyaohua/Organization";
import Culture from "@/views/aboutyaohua/Culture";
import Honor from "@/views/aboutyaohua/Honor";
import History from "@/views/aboutyaohua/History";
import Subsidiary from "@/views/aboutyaohua/Subsidiary";
import Honorlist from "@/views/aboutyaohua/honor/Honorlist";
import Historylist1 from "@/views/aboutyaohua/history/Historylist1";
import Historylist2 from "@/views/aboutyaohua/history/Historylist2";
import Historylist3 from "@/views/aboutyaohua/history/Historylist3";
import Historylist4 from "@/views/aboutyaohua/history/Historylist4";
import Subsidiarylist from "@/views/aboutyaohua/subsidiary/Subsidiarylist";
import CenterAndStation from "@/views/technology/CenterAndStation";
import Achievement from "@/views/technology/Achievement";
import AchievementList from "@/views/technology/achievement/AchievementList";
import ProductCenter from '@/views/leadingproduct/productcenter/ProductCenter.vue';
import ProductInfo from '@/views/leadingproduct/productcenter/ProductInfo.vue';
import DomesticSales from "@/views/leadingproduct/domesticsales/DomesticSales.vue";
import CompanyNews from "@/views/news/CompanyNews";
import VReport from "@/views/news/VReport";
import imgNews from "@/views/news/imgNews";
import Notice from "@/views/news/Notice";
import ForeignSales from "@/views/leadingproduct/foreignsales/ForeignSales.vue";
import HumanResources from "@/views/management/HumanResources";
import Purchase from "@/views/management/Purchase";
import Security from "@/views/management/Security";
import System from "@/views/management/System";
import Statute from "@/views/management/Statute";
import Learn20 from "@/views/party/Learn20";
import Mentor from "@/views/party/Mentor";
import Special from "@/views/party/Special";
import YouthWork from "@/views/party/YouthWork";
import AfterSalesService from "@/views/contact/AfterSalesService.vue";
import ContactInformation from "@/views/contact/ContactInformation.vue";
import OnlineMessage from "@/views/contact/OnlineMessage.vue";
import CommonQuestion from "@/views/contact/CommonQuestion.vue";
import Communication from "@/views/party/Communication";
Vue.use(VueRouter);
Vue.use(ElementUI);

export const constantRoutes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        children: [
            {
                path: '',
                component: HomePage,
                name: 'HomePage',
            },
            {
                path: '/about',
                component: Aboutyaohua,
                name: 'Aboutyaohua',
            },
            {
                path: '/about/organization',
                component: Organization,
                name: 'Organization',
            },
            {
                path: '/about/culture',
                component: Culture,
                name: 'aboutCulture',
            },
            {
                path: '/about/honor',
                component: Honor,
                name: 'aboutHonor',
            },
            {
                path: '/about/honor/:id',
                component: Honorlist,
                name: 'aboutHonorlist',
            },
            {
                path: '/about/history',
                component: History,
                name: 'aboutHistory',
            },
            {
                path: '/about/history/1',
                component: Historylist1,
                name: 'aboutHistorylist1',
            },
            {
                path: '/about/history/2',
                component: Historylist2,
                name: 'aboutHistorylist2',
            },
            {
                path: '/about/history/3',
                component: Historylist3,
                name: 'aboutHistorylist3',
            },
            {
                path: '/about/history/4',
                component: Historylist4,
                name: 'aboutHistorylist4',
            },
            {
                path: '/about/subsidiary',
                component: Subsidiary,
                name: 'aboutSubsidiary'
            },
            {
                path: '/about/subsidiary/:id',
                component: Subsidiarylist,
                name: 'aboutSubsidiarylist'
            },
            {
                path: '/technology/center/:id',
                component: CenterAndStation,
                name: 'technologyCenterAndStation'
            },
            {
                path: '/technology/achievement',
                component: Achievement,
                name: 'technologyAchievement'
            },
            {
                path: '/technology/achievement/:id',
                component: AchievementList,
                name: 'technologyAchievementList'
            },
            {
                path: '/news/companyNews',
                component: CompanyNews,
                name: 'CompanyNews'
            },
            {
                path: '/news/vReport',
                component: VReport,
                name: 'vReport'
            },
            {
                path: '/news/imgNews',
                component: imgNews,
                name: 'imgNews'
            },
            {
                path: '/news/notice',
                component: Notice,
                name: 'newsNotice'
            },
            {
                path: '/management/hr',
                component: HumanResources,
                name: 'HumanResources'
            },
            {
                path: '/management/purchase',
                component: Purchase,
                name: 'managementPurchase'
            },
            {
                path: '/management/security',
                component: Security,
                name: 'managementSecurity'
            },
            {
                path: '/management/system',
                component: System,
                name: 'managementSystem'
            },
            {
                path: '/management/statute',
                component: Statute,
                name: 'managementStatute'
            },
            {
                path: '/party/learn20',
                component: Learn20,
                name: 'partyLearn20'
            },
            {
                path: '/party/mentor',
                component: Mentor,
                name: 'partyMentor'
            },
            {
                path: '/party/special',
                component: Special,
                name: 'partySpecial'
            },
            {
                path: '/party/youthWork',
                component: YouthWork,
                name: 'partyYouthWork'
            },
            {
                path: '/product/ProductCenter',
                component: ProductCenter,
                name: 'ProductCenter',
            },
            {
                path: '/product/ProductCenter/ProductInfo',
                component: ProductInfo,
                name: 'ProductInfo',
            },
            {
                path: '/product/DomesticSales',
                component: DomesticSales,
                name: 'DomesticSales',
            },
            {
                path: '/product/ForeignSales',
                component: ForeignSales,
                name: 'ForeignSales',
            },
            {
                path: '/contact/AfterSalesService',
                component: AfterSalesService,
                name: 'AfterSalesService',
            },
            {
                path: '/contact/ContactInformation',
                component: ContactInformation,
                name: 'ContactInformation',
            },
            {
                path: '/contact/OnlineMessage',
                component: OnlineMessage,
                name: 'OnlineMessage',
            },
            {
                path: '/contact/CommonQuestion',
                component: CommonQuestion,
                name: 'CommonQuestion',
            },
        ]
    },
    {
        path: '/party/communication',
        component: Communication,
        name: 'partyCommunication'
    },
];

const router = new VueRouter({
    routes: constantRoutes
});

export default router;
