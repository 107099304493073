<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/news/notice">通知公告</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/news/companyNews">企业新闻</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/news/vReport">微报道</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/news/imgNews">图片新闻</a></h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">
            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.newsName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="20"
                           layout="prev, pager, next, jumper"
                           :total="newsItems.length"
            />
        </div>
    </div>
</template>

<script>
    import {newsItems} from './item/newsItems'
    export default {
        name: "CompanyNews",
        data() {
            return {
                activeNames: [],
                items: [
                    {image: '5e0d91686ddb4.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                currentPage: 1, // 当前页数
                newsItems:newsItems
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 20;
                const endIndex = startIndex + 20;
                return this.newsItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style >
    #subnav,
    .custom-collapse {
        margin-bottom: 20px; /* 调整模块之间的垂直间距，根据需要进行调整 */
    }
    .custom-collapse {
        background-color: #f0f0f0; /* 这里更换为您想要的灰色背景色 */
    }

    #main {
        width: 60%; /* 或者您想要的任何宽度 */
        margin: 0 auto; /* 将元素水平居中 */
    }
    #main {
        background-color: white; /* 设置背景色为灰色 */
    }
    .title-container {
        display: flex;
        align-items: center; /* 垂直居中对齐 */
    }

    .title-container img {
        margin-right: 10px; /* 图片与文字之间的间距，根据需要调整 */
    }
    #collapse {
        background-color: rgba(255, 255, 255, 0);
    }
    .more {
        color: orange;
        transition: color 0.3s ease;
    }
    .more:hover {
        color: darkorange; /* Change to a slightly darker shade on hover */
    }
    #pagination {
        display: flex;
        justify-content: center;
    }
    .el-pagination .number.active {
        color: grey !important;
    }
</style>
