<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/technology/center/1">国家企业技术中心</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/technology/center/2">博士后科研工作站</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/technology/center/3">工程技术创新中心</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/technology/achievement">科研成果</a></h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="content">
                    <div id="title">
                        <h4>{{selectedItem.name}}</h4>
                        <span class="source">来源：</span>
                        <span class="date">发布时间：{{selectedItem.date}}</span>
                    </div>
                    <div id="text">
                        <div style="text-align:center;">
                            <img v-for="(src, index) in selectedItem.src" :key="index" :src="src"
                                 style="max-width: 100%; height: auto; object-fit: cover;">
                        </div>
                        <div style="text-align:center;">
                            <span class="description" style="white-space: pre-line; line-height: 1.5; text-indent: 0;">
                                <template v-if="selectedItem.id !== 4">
                                     {{selectedItem.description}}
                                </template>
                                <template v-else>
<table style="width:100%;" cellpadding="0" cellspacing="0" border="1" bordercolor="#333333">
		<tbody>
			<tr>
				<td style="text-align:center;">
					<span><span>编号</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>实用新型专利名称</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>发明人</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 专利号</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>专利申请日</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 专利权人</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>授权公告日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>1</span>
				</td>
				<td style="text-align:center;">
					<span><span>一种在线镀膜色差调整装置</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 马玉聪、吕应成等</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>ZL 2017 2 0768554.5</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2017年6月29日</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年1月5日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>2 </span>
				</td>
				<td style="text-align:center;">
					<span>在线硅膜废气处理系统<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>吕应成、陈鹏等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2017 2 0768591.6<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2017年6月29日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年1月5日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>3 </span>
				</td>
				<td style="text-align:center;">
					<span>一种改进的在线镀膜器底板<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>刘卫东、吕应成等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2017 2 0768655.2<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2017年6月29日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年2月9日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>4 </span>
				</td>
				<td style="text-align:center;">
					<span>锡槽水包温控系统<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>吕应成、陈鹏等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2017 2 0768661.8<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2017年6月29日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年2月23日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>5 </span>
				</td>
				<td style="text-align:center;">
					<span>一种镀膜器排废调整装置<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>姚振华、吕应成等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2017 2 0768553.0<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2017年6月29日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年2月23日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>6 </span>
				</td>
				<td style="text-align:center;">
					<span>在线镀膜防变形系统<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>李超、吕应成等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2017 2 0768592.0<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2017年6月29日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年7月27日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>7 </span>
				</td>
				<td style="text-align:center;">
					<span>用于在线Low-E玻璃生产线的安全装置<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>吕应成、陈鹏等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2017 2 0768552.6<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2017年6月29日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2019年1月29日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>8 </span>
				</td>
				<td style="text-align:center;">
					<span>可同时生产浮法玻璃和压延玻璃的生产线<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>赵琪、刘卫东等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2017 2 1119516.3<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2017年9月1日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年3月27日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>9 </span>
				</td>
				<td style="text-align:center;">
					<span>一种浮法玻璃料道着色设备<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>刘卫东、逯芳等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2018 2 1432301.1<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2018年9月3日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2019年4月2日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>10</span>
				</td>
				<td style="text-align:center;">
					<span>一种可稳定浮法玻璃锡槽气流的装置<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>刘卫东、马玉聪等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2018 2 1432972.8<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2018年9月3日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2019年4月2日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>11</span>
				</td>
				<td style="text-align:center;">
					<span>一种浮法玻璃过渡辊二氧化硫回收装置<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>刘卫东、马玉聪等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2018 2 1432360.9<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2018年9月3日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2019年4月2日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>12</span>
				</td>
				<td style="text-align:center;">
					<span>一种浮法玻璃投料口除尘设备<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>刘卫东、马玉聪等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2018 2 1432971.3<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2018年9月3日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2019年4月2日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>13</span>
				</td>
				<td style="text-align:center;">
					<span>一种玻璃在线镀膜设备<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>刘卫东<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>ZL 2018 2 1431804.7<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2018年9月3日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2019年4月2日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>14</span>
				</td>
				<td style="text-align:center;">
					<span>一种制备三氯化铁的新方法<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>胡常伟、马玉聪等<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>CN 108101120 A<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2018年1月19日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2018年6月1日</span><br>
</span>
				</td>
			</tr>
			<tr>
				<td style="text-align:center;">
					<span>15</span>
				</td>
				<td style="text-align:center;">
					<span>粉色本体着色玻璃组合物<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>马玉聪、李雪莲、秦丽艳<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>CN 110128009 A<br>
</span>
				</td>
				<td style="text-align:center;">
					<span>2019年6月25日<br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span> 中国耀华玻璃集团有限公司</span><span></span><br>
</span>
				</td>
				<td style="text-align:center;">
					<span><span>2019年8月16日</span><br>
</span>
				</td>
			</tr>
		</tbody>
	</table>
                                </template>
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "technologyAchievementList",
        data() {
            return {
                selectedItem: null,
                achievementItems: [
                    {
                        id: 1,
                        name: 'GB11614-2009-平板玻璃',
                        date: '2020-01-14',
                        src: [
                            require('@/../uploads/5e1d56c7986b0.jpg'),
                            require('@/../uploads/5e1d56d1e6b1d.jpg'),
                            require('@/../uploads/5e1d56e12eaa6.jpg'),
                            require('@/../uploads/5e1d56f1a22fa.jpg'),
                            require('@/../uploads/5e1d57155deff.jpg'),
                            require('@/../uploads/5e1d571b0b348.jpg'),
                            require('@/../uploads/5e1d571f4b2e1.jpg'),
                            require('@/../uploads/5e1d5723895d7.jpg'),
                            require('@/../uploads/5e1d5727aecb0.jpg'),
                            require('@/../uploads/5e1d572c2ba2d.jpg'),
                            require('@/../uploads/5e1d5730ba98d.jpg'),
                            require('@/../uploads/5e1d573534ec4.jpg'),
                        ],
                        description: ''
                    },
                    {
                        id: 2,
                        name: 'GB18915.1阳光控制镀膜玻璃',
                        date: '2020-01-14',
                        src: [
                            require('@/../uploads/5e1d537862cb4.jpg'),
                            require('@/../uploads/5e1d5216a22b4.jpg'),
                            require('@/../uploads/5e1d539d7dff6.jpg'),
                            require('@/../uploads/5e1d53ac96633.jpg'),
                            require('@/../uploads/5e1d53b28ca66.jpg'),
                            require('@/../uploads/5e1d53c182c95.jpg'),
                            require('@/../uploads/5e1d53ce90c58.jpg'),
                            require('@/../uploads/5e1d53d3972d3.jpg'),
                            require('@/../uploads/5e1d53d8adbc2.jpg'),
                        ],
                        description: ''
                    },
                    {
                        id: 3,
                        name: 'GB18915.2低辐射镀膜玻璃',
                        date: '2020-01-14',
                        src: [
                            require('@/../uploads/5e1d3b4241c24.jpg'),
                            require('@/../uploads/5e1d3c753c4d8.jpg'),
                            require('@/../uploads/5e1d3c7be9034.jpg'),
                            require('@/../uploads/5e1d3c9d199b9.jpg'),
                            require('@/../uploads/5e1d3cd54aa7b.jpg'),
                            require('@/../uploads/5e1d3cdbe4169.jpg'),
                            require('@/../uploads/5e1d3ce17a567.jpg'),

                        ],
                        description: ''
                    },
                    {
                        id: 4,
                        name: '耀华技术中心专利明细表',
                        date: '2020-01-14',
                        src: '',
                        description: ''
                    },
                    {
                        id: 5,
                        name: '技术中心科研成果',
                        date: '2019-11-05',
                        src: '',
                        description: '1、积极进行新产品和新设备研发成果专利工作\n' +
                            '\n' +
                            '截止到2019年10月的三年内，技术中心共获得专利14项，其中实用新型13项，发明专利1项，获得受理发明专利1项。专利涵盖浮法、镀膜等领域。\n' +
                            '\n' +
                            '2、河北省硼硅技术创新中心已完成申报\n' +
                            '\n' +
                            '耀华硼硅有2条生产线，主要是原片。为了实现硼硅产业化，耀华集团在今年6月份申请了河北省硼硅技术创新中心。准备以硼硅中心为重点，实现从原片向防火、器皿、家电等深加工转变。\n' +
                            '\n' +
                            '3、环保项目成为研发重点\n' +
                            '\n' +
                            '环保的要求和生产的需求促使环保建设不断升级，技术中心研发了脱硫脱硝废弃物再利用项目，减少了废弃物存放、运输及处理的困难。\n' +
                            '\n' +
                            '4、节能项目研发\n' +
                            '\n' +
                            '电为纯净能源，有利于减少废气排放。把电做能源也是技术中心重点项目。目前已自主设计并建造了以纯电做燃料的全电熔窑2个，以电为辅助的大型熔窑电助熔项目1个。同时技术中心正在研发以电作为主熔的大型浮法玻璃熔窑，这将是国内首创的电主熔大型浮法玻璃熔窑。\n' +
                            '\n' +
                            '5、赋予玻璃以新技术的镀膜工作正在积极展开\n' +
                            '\n' +
                            '近年来，技术中心在浮法玻璃基础上研发镀膜技术，先后研发并生产了福特蓝机车玻璃、粉玻低透家电玻璃、白玻高透产业玻璃等。申报并通过了河北省镀膜玻璃技术创新中心。'
                    },
                ],
                items: [
                    {image: '5e0eed078b5d1.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true // 控制是否显示关注部分，默认为显示
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
        // 省略其他组件选项
        watch: {
            '$route.params.id': function (newId) {
                // 获取路由参数变化后的新 id 值
                this.selectedItem = this.centerItems.find(item => item.id == newId); // 根据新 id 匹配对应的数据项
            }
        },
        created() {
            // 获取路由参数
            const id = this.$route.params.id; // 获取路由参数中的 id
            this.selectedItem = this.achievementItems.find(item => item.id == id); // 根据 id 匹配对应的数据项
        }
    }
</script>

<style scoped>

</style>
