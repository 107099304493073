<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/technology/center/1">国家企业技术中心</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/technology/center/2">博士后科研工作站</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/technology/center/3">工程技术创新中心</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/technology/achievement">科研成果</a></h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="content">
                    <div id="title" >
                        <h4>{{selectedItem.name}}</h4>
                    </div>
                    <div id="text">
                        <div style="text-align:center;">
                            <img :src="selectedItem.src" style="max-width: 100%; height: auto; object-fit: cover;">
                        </div>
                        <div style="text-align:center; margin-bottom: 20px;">
                            <span class="description">{{selectedItem.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "technologyCenterAndStation",
        data() {
            return {
                selectedItem: null,
                centerItems: [
                    {
                        id: 1,
                        name: '国家企业技术中心',
                        src: require('@/../uploads/5e0da07da9e12.png'),
                        description:'中国耀华玻璃集团有限公司技术中心（以下简称耀华技术中心）成立于1994年，1996年12月被国家经贸委、国家税务总局和海关总署认定为第四批国家企业技术中心（国经贸技[1996]913号）。经过二十多年的不断建设和发展，拥有博士后科研工作站、研究开发部、技术质量部、分析测试部、科技管理部、河北省镀膜玻璃技术创新中心等科研和管理机构（河北省硼硅玻璃技术创新中心也在申报中）。是一个高层次、高水平、开放式，集创新规划、技术设计、实现产业化于一体的创新科研开发基地。\n' +
                            '\n' +
                            '多年来，耀华技术中心始终紧紧围绕国家相关产业政策和企业当前发展形式，瞄准世界前沿科技，制定技术创新战略与规划，进行技术创新体系建设。不断加大科技经费投入，广泛开展技术创新活动，开发出关键核心技术与具有自主知识产权的新产品、新技术，产品和技术水平已经达到或接近国际先进水平。充分利用耀华产品质量在国内处于领先地位、享有较高的声誉及用户满意度高等优势，使企业先后荣获驰名商标、免检产品以及中国名牌产品等称号。并借助两化融合建设加强企业内部精细化管理，不断提升企业管理水平。\n' +
                            '\n' +
                            '耀华技术中心的建设，不仅培育和增强了企业核心竞争力，为耀华的持续发展提供了可靠保证，而且一些重大科研项目，如在线低辐射玻璃、自洁净玻璃以及高硼硅浮法玻璃等的成功开发，对我国传统玻璃行业产业转型升级、结构调整发挥了积极的推动作用。'
                    },
                    {
                        id: 2,
                        name: '博士后科研工作站',
                        src: require('@/../uploads/5e0d9fc7e84b2.png'),
                        description:'中国耀华玻璃集团有限公司博士后科研工作站于2000年11月被国家人事部批准建立，批准文号为人发[2000]113号，是秦皇岛市首家企业博士后科研工作站。自设立以来先后与北京化工大学、上海交通大学、四川大学和燕山大学等众多高等院校，联合培养了一批博士后科研人才。承担并完成了“煤加氢催化剂的评述及筛选”、“ 玻璃窑窑顶熔窑窑体热应力热力学分析”以及“光催化活化、自洁净玻璃的在线制造方法”等多项科研课题。其中“光催化活化、自洁净玻璃的在线制造方法”被列为国家“十五”863高新技术项目。'
                    },
                    {
                        id: 3,
                        name: '工程技术创新中心',
                        src: require('@/../uploads/5e0da0976df5a.png'),
                        description:'河北省镀膜玻璃技术创新中心，成立于2019年2月，批准文号为冀科平函〔2019〕4号。该中心由中国耀华玻璃集团有限公司与中建材蚌埠玻璃工业设计研究院有限公司双方联合共建，研究重点为膜层功能研发与设计、镀膜工艺技术及配套设备的研发、镀膜玻璃综合节能减排技术。中心主要代表性成果有浮法玻璃在线镀水晶蓝镀膜技术、在线LOW-E镀膜技术、在线易洁镀膜玻璃。\n' +
                            '\n' +
                            '中心下设主任和工程技术委员会。工程中心主任下设4个机构：功能膜层研究室、镀膜工艺与装备研究室、镀膜玻璃绿色生产研究室、分析测试室。工程技术委员会负责研发方向及研发指导。各室根据主任及委员会要求协同合作，分别研发镀膜工艺及设备。\n' +
                            '\n' +
                            '该中心通过联合研发低成本复合膜层镀膜玻璃，实现玻璃产品绿色化、节能化和多功能化。将玻璃应用范围从普通建筑领域，逐步拓展到光伏建筑一体化、新能源、电子玻璃等领域。从而实现传统玻璃产业转型升级，带动行业整体技术向国际化、高端化发展，进而引领现代消费潮流。'
                    },
                ],
                items: [
                    {image: '5e0eed078b5d1.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true // 控制是否显示关注部分，默认为显示
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
        // 省略其他组件选项
        watch: {
            '$route.params.id': function(newId) {
                // 获取路由参数变化后的新 id 值
                this.selectedItem = this.centerItems.find(item => item.id == newId); // 根据新 id 匹配对应的数据项
            }
        },
        created() {
            // 获取路由参数
            const id = this.$route.params.id; // 获取路由参数中的 id
            this.selectedItem = this.centerItems.find(item => item.id == id); // 根据 id 匹配对应的数据项
        }

    }
</script>

<style scoped>

</style>
