<template>
<div id="main">
    <div id="subnav">
        <div class="inner">
            <ul>
                <li class="nli">
                    <h4><a href="/#/technology/center/1">国家企业技术中心</a></h4>
                </li>
                <li class="nli">
                    <h4><a href="/#/technology/center/2">博士后科研工作站</a></h4>
                </li>
                <li class="nli">
                    <h4><a href="/#/technology/center/3">工程技术创新中心</a></h4>
                </li>
                <li class="nli">
                    <h4><a href="/#/technology/achievement">科研成果</a></h4>
                </li>
            </ul>
        </div>
    </div>
    <div class="custom-collapse">

        <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
            <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                <template #title>
                    <div class="title-container">
                        <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                        <span>{{ item.companyName }}</span>
                    </div>
                </template>
                <div>来源：发布日期：{{ item.publishDate }}</div>
                <div>{{ item.content }}</div>
                <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
            </el-collapse-item>
        </el-collapse>
        <el-pagination id="pagination"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="5"
                layout="prev, pager, next, jumper"
                :total="collapseItems.length"
        />
    </div>
</div>
</template>

<script>
    export default {
        name: "technologyAchievement",
        data() {
            return {
                activeNames: [],
                items: [
                    {image: '5e0eed078b5d1.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: 'GB11614-2009-平板玻璃',
                        publishDate: '2020-01-14',
                        moreLink: '/#/technology/achievement/1'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: 'GB18915.1阳光控制镀膜玻璃',
                        publishDate: '2020-01-14',
                        moreLink: '/#/technology/achievement/2'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: 'GB18915.2低辐射镀膜玻璃',
                        publishDate: '2020-01-14',
                        moreLink: '/#/technology/achievement/3'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '耀华技术中心专利明细表',
                        publishDate: '2020-01-14',
                        moreLink: '/#/technology/achievement/4'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '技术中心科研成果',
                        publishDate: '2019-11-05',
                        moreLink: '/#/technology/achievement/5'
                    }
                ],
                currentPage: 1 // 当前页数

            };
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 5;
                const endIndex = startIndex + 5;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style>
    #subnav,
    .custom-collapse {
        margin-bottom: 20px; /* 调整模块之间的垂直间距，根据需要进行调整 */
    }
    .custom-collapse {
        background-color: #f0f0f0; /* 这里更换为您想要的灰色背景色 */
    }

    #main {
        width: 60%; /* 或者您想要的任何宽度 */
        margin: 0 auto; /* 将元素水平居中 */
    }
    #main {
        background-color: white; /* 设置背景色为灰色 */
    }
    .title-container {
        display: flex;
        align-items: center; /* 垂直居中对齐 */
    }

    .title-container img {
        margin-right: 10px; /* 图片与文字之间的间距，根据需要调整 */
    }
    #collapse {
        background-color: rgba(255, 255, 255, 0);
    }
    .more {
        color: orange;
        transition: color 0.3s ease;
    }
    .more:hover {
        color: darkorange; /* Change to a slightly darker shade on hover */
    }
    #pagination {
        display: flex;
        justify-content: center;
    }
    .el-pagination .number.active {
        color: grey !important;
    }
</style>
