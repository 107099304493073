<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div class="content">
                <div id="left">
                    <h4>集团简介</h4>
                    <div class="text">
                        <p style="text-indent:2em;margin-top:10px;">
                        <span style="line-height:2;">
                            中国耀华玻璃集团有限公司（以下简称“耀华集团”）创建于1922年，是亚洲第一家采用机器连续生产平板玻璃的企业，被誉为“中国玻璃工业的摇篮”。<br>
                            耀华集团具有悠久的历史，雄厚的技术、品牌、人才和市场优势。多年来，以国家企业技术中心、博士后科研工作站等科研平台为基础，先后自主研发了在线低辐射镀膜玻璃、自洁净玻璃、硼硅玻璃、铝硅玻璃、熔石英玻璃等一批国内领先的新产品。<br>
                            耀华集团在秦皇岛地区拥有5条浮法玻璃生产线和2条具有自主知识产权的高硼硅特种浮法玻璃生产线。耀华玻璃驰名中外,主要产品有优质浮法玻璃、汽车玻璃、节能玻璃、粉红、福特蓝、F绿、灰（深、浅）等本体着色玻璃及在线镀膜玻璃、硼硅玻璃等。<br>
                            新世纪后，耀华进入深化改革、创新发展的又一重要时期。2020年以来，在秦皇岛市委、市政府和中国建材集团、凯盛科技集团的共同努力下，2020年6月30日，秦皇岛市国资委与凯盛科技集团签订了股权交接协议，标志着历时4年，耀华集团正式加入凯盛科技集团。<br>
                            耀华集团以凯盛科技集团“3+1”战略布局为引领，根据公司新的发展定位，正在积极推进退城进园工程，公司将建设两个产业园，高起点规划、高标准设计、高质量建设，打造全新的耀华玻璃新材料高新技术产业园；充分利用耀技厂区现有设施，重点开展技术含量高的新玻璃新材料高端产品研发与产业化，打造耀华科创产业园。新园区将重点发展优质浮法玻璃及其深加工产品、新能源玻璃、颜色玻璃、硼硅玻璃及配套深加工生产线，通过5～10年的发展，将百年耀华打造成为年营业收入突破50亿的具有创新特色的科技型产业集团。<br>
                        </span>
                        </p>
                    </div>
                </div>
                <div id="right">
                    <p style="text-align:center;">
                        <img src="@/../uploads/60da770ef2eec.jpg" title="60da770ef2eec.jpg" alt="60da770ef2eec.jpg" width="" height="" align="">
                    </p>
                    <p></p>
                    <table style="width:100%;" cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000">
                        <tbody>
                        <tr>
                            <td>
                                <img src="@/../uploads/60da76c29a2a5.jpg" title="60da76c29a2a5.jpg" alt="60da76c29a2a5.jpg" width="" height="" align=""><br>
                            </td>
                            <td>
                                <img src="@/../uploads/60da76cce8cc4.jpg" title="60da76cce8cc4.jpg" alt="60da76cce8cc4.jpg" width="" height="" align=""><br>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p></p>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Aboutyaohua",
    data() {
        return {
            interval: 4000,
            items: [
                {image: '5e156e89e6e27.jpg', title: 'Title 1', description: 'Description 1'}
            ],
            isFollowVisible: true // 控制是否显示关注部分，默认为显示
        }
    },
    mounted() {
        this.$emit('update-carousel', this.items);
    },

}
</script>

<style>
    body {
        font-size: 14px;
        color: #333;
        background: white;
        line-height: 1.5em;
        word-wrap:break-word;
        break-word:break-all;
    }
    a {
        color: #333;
        text-decoration: none;
        outline: none;
    }
    a:hover {
        text-decoration: underline;
    }
    .clear {
        clear: both;
    }
    /* list */
    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    /* headings */
    h1, h2, h3, h4, h5, h6 {
        line-height: 1.4em;
        color: #000;
    }
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.6em;
    }
    h3 {
        font-size: 1.4em;
    }
    h4 {
        font-size: 1.2em;
    }
    h5 {
        font-size: 1.1em;
    }
    h6 {
        font-size: 1em;
    }
    .l {
        float: left;
    }
    .r {
        float: right;
    }
    .inner {
        width: 1200px;
        margin: auto;
    }
    /* CSS Document */
    #banner {
        height: 320px;
        width: 100%;
        position: relative;
    }
    #banner img {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -960px;
    }
    /*main*/
    #main {
        /*	padding: 0 0 35px;
            background: url(../images/mainbg.jpg) no-repeat bottom center;
        */
    }
    #main .inner {
        background:#f5f5f5;
    }
    #main .lunbo {
        padding: 0 0 30px;
        border-bottom: 1px #ccc solid;
    }


    #main .content {
        /*	padding:30px 0;
            margin:50px 0;
            background:url(../images/liline.jpg) repeat-y center top;
        */}
    #main #content .content {
        /*	margin:20px 0;
        */	background:none;
        padding:0 0 20px;
    }
    #main #content .content h4 {
        text-align:center;
    }
    #main .content h4 {
        font-size:18px;
    }
    #main #content h5 {
        font-size:18px;
        color:#333;
        text-align:center;
        background:#ebebeb;
        /*	margin-top:20px;
        */	line-height:2em;
        /*	padding:35px 0 10px;
            border-bottom:1px #ccc solid;	*/
    }
    #main .text {
        /*	padding: 40px 20px 50px;
        */	/*text-indent: 2em;*/
        line-height: 2em;
    }
    /* subnav css */

    #main .content table{
        border-collapse:collapse;
    }
    #main .content p {
        padding:0;
    }
    #text {
        padding:0 40px;
        min-height:300px;
        line-height: 2em;
    }
    #title {
        padding:10px 20px ;
        text-align:center;
        /*	border-bottom:1px #ccc solid;
        */	margin-bottom:20px;
        background:#ebebeb;
    }
    #title h5 {
        font-size:16px;
    }
    #title span {
        color:#999;
        margin:0 5px;
    }
    #main #subnav {
        width:100%;
        height:60px;
        /*	float:left;
        */	background:#fff;
        /*	border-bottom:1px #ccc solid;
        */}
    #main #subnav .nli {
        position:relative;
        height:60px;
        float:left;
    }
    #main #subnav h4 {
        height:60px;
        line-height:60px;
        font-weight:normal;
        padding:0 35px 0 0;
        font-size:16px;
        float:left;
    }
    #main #subnav .on h4 a,#main #subnav h4 a:hover{
        color:#ff3234;
    }
    #main #subnav h4 a {
        text-decoration:none;
    }
    #main #subnav .sub {
        display:none;
        position:absolute;
        background:white;
        border:1px #ccc solid;
        width:100%;
        padding:5px 10px 10px;
        text-align:center;
        top:58px;
        left:0;
        margin-left:-30px;
        z-index:8;
    }
    #main #subnav .sub li {
        width:100%;
        padding:5px 0;
    }
    #main #right {
        width:570px;
        float:right;
        background:white;
        padding:0px 15px 10px;
    }
    #main #left {
        width: 570px;
        float: left;
        padding: 20px 15px 0;
    }
    #main #right img {
        max-width:100%;
    }
    #main .top {
        height:40px;
        line-height:40px;
        padding-bottom:2px;
        /*	border-bottom:1px #cscc solid;
        */	width:1200px;
        margin:0 auto;
    }


    #main .h p {
        color: #999;
    }
    #banner {
        overflow:hidden;
    }
    /*面包屑导航*/
    #main .top {
        position:relative;
    }
    #main .mbxdh {
        position:absolute;
        top:2px;
        left:0;
        color:#999;
        padding-left:25px;
        background:url(../../components/images/homeicon.png) no-repeat left center;
    }
    #main .mbxdh li {
        display:inline-block;
    }
    #main .mbxdh a {
        display:inline-block;
        padding:0 5px 0 2px;
        color:#666;
        text-decoration:none;
    }
    #main .top .mbxdh li {
        *display:inline;
    }
    #main .top .mbxdh a {
        *display:inline;
    }

</style>
