<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <h5>企业文化</h5>
                <div class="content">
                    <div id="text">
                        <p><br></p>
                        <p><strong><strong><span style="line-height:2;">价值</span></strong><span style="line-height:2;">核心：</span></strong><span style="line-height:2;">创新、绩效、和谐、责任</span><br>
                            <strong><span style="line-height:2;">行为准则：</span></strong><span style="line-height:2;">敬畏、感恩、谦恭、得体</span><br>
                            <strong><span style="line-height:2;">核心理念：</span></strong><span style="line-height:2;">善用资源、服务建设</span><br>
                            <strong><span style="line-height:2;">文化理念：</span></strong><span style="line-height:2;">企业是人、企业靠人、企业为人，让员工与企业共同成长</span><br>
                            <strong><span style="line-height:2;">四个精心：</span></strong><span style="line-height:2;">精心做人、静心做事、精心用权、精心交友</span><br>
                            <strong><span style="line-height:2;">五有干部：</span></strong><span style="line-height:2;">有学习能力、有市场意识、有专业水准、有敬业精神、有思想境界</span><br>
                            <strong><span style="line-height:2;">企业品格：</span></strong><span style="line-height:2;">环境保护、热心公益、员工发展、世界公民</span><br>
                            <strong><span style="line-height:2;">人文环境：</span></strong><span style="line-height:2;">待人宽厚、处事宽容、环境宽松、向心力、亲和力 、凝聚力</span><br>
                            <strong><span style="line-height:2;">企业氛围：</span></strong><span style="line-height:2;">党建文化、企业文化、廉洁文化、安全环保文化、“四化融合”</span><br>
                            <strong><span style="line-height:2;">耀华精神：</span></strong><span style="line-height:2;">艰苦奋斗、严守纪律、求实创新、勇争一流</span></p>
                        <p><br></p>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aboutCulture",
        data() {
            return {
                interval: 4000,
                items: [
                    {image: '5e156e89e6e27.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true // 控制是否显示关注部分，默认为显示
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style>

</style>
