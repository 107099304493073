import { render, staticRenderFns } from "./Learn20.vue?vue&type=template&id=2246a42e&scoped=true"
import script from "./Learn20.vue?vue&type=script&lang=js"
export * from "./Learn20.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2246a42e",
  null
  
)

export default component.exports