<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/management/hr">人力资源</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/purchase">采购招标</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/security">安全环保</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/system">管理体系</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/statute">政策法规</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/management/hr">企业管理</a></li> &gt;
                    <li><a href="/#/management/statute">政策法规</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "managementStatute",
        data() {
            return {
                activeNames: [],
                items: [
                    {image: '5e7435be5c0db.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '重大事故隐患管理规定',
                        publishDate: '2019-11-05',
                        content: '第一章 总则 第一条 为贯彻“安全第一，预防为主”的方针，加强对重大事故隐患的管理，预防重大事故的发生，...',
                        moreLink: '/#/management/statute'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '特种设备安全监察条例',
                        publishDate: '2019-11-05',
                        content: '第一章总则 第一条 为了加强特种设备的安全监察，防止和减少事故，保障人民 特种设备安全监察条...',
                        moreLink: '/#/management/statute'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '建设项目职业卫生“三同时”监督管理暂行办法',
                        publishDate: '2019-11-05',
                        content: '第一章 第一条 为了预防、控制和消除建设项目可能产生的职业病危害，加强和规范建设项目职业病防护设施建设的监督管理，根...',
                        moreLink: '/#/management/statute'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '劳动防护用品监督管理规定',
                        publishDate: '2019-11-05',
                        content: '目录 第一章 总 则 第二章 劳动防护用品的生产、检验、经营 第三章 劳动防护用品的配备与使...',
                        moreLink: '/#/management/statute'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中华人民共和国大气污染防治法(2018修正)',
                        publishDate: '2019-11-05',
                        content: '第一章 总  则 第一条 为保护和改善环境，防治大气污染，保障公众健康，推...',
                        moreLink: '/#/management/statute'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中华人民共和国安全生产法',
                        publishDate: '2019-11-05',
                        content: '最新版 2014年12月1日起施行 中华人民共和国安全生产法（2014年修订） （2002年6月29日...',
                        moreLink: '/#/management/statute'
                    },
                    {
                        name: '7',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中华人民共和国职业病防治法',
                        publishDate: '2019-11-05',
                        content: '2001年10月27日第九届全国人民代表大会常务委员会第二十四次会议通过 根据2011年12月31日第十一届全国人...',
                        moreLink: '/#/management/statute'
                    },
                    {
                        name: '8',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中华人民共和国环境保护法（2015.01.01）',
                        publishDate: '2019-11-05',
                        content: '2014-04-25 （1989年12月26日第七届全国人民代表大会常务委员会第十一次会议通过，2014年4月24...',
                        moreLink: '/#/management/statute'
                    },                    {
                        name: '9',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '建设项目安全设施“三同时”监督管理办法',
                        publishDate: '2019-11-05',
                        content: '第36号 [1] 《建设项目安全设施“三同时”监督管理暂行办法》已经2010年11月3日国家安全生产监督管理总局局长...',
                        moreLink: '/#/management/statute'
                    },                    {
                        name: '10',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '建材行业较大危险源因素辨识与防范指导手册',
                        publishDate: '2019-11-05',
                        content: '二零一六年二月 二零一六年二月 二零一六...',
                        moreLink: '/#/management/statute'
                    },



                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },

    }
</script>

<style scoped>

</style>
