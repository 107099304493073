<template>
  <div id="footer">
    <div class="fotnav">
      <div class="inner">
        <ul class="left l">
          <li><h4>关于耀华</h4>
            <ul class="subli">
              <li><a href="/Content/browse/cid/070101/">集团简介</a></li>
              <li><a href="/Content/browse/cid/070103/">组织机构</a></li>
              <li><a href="/Content/browse/cid/070104/">资质荣誉</a></li>
              <li><a href="/Content/browse/cid/070105/">企业文化</a></li>
              <li><a href="/Content/browse/cid/070106/">耀华历史</a></li>
              <li><a href="/Content/browse/cid/070107/">子公司</a></li>
            </ul>
          </li>
          <li><h4>新闻中心</h4>
            <ul class="subli">
              <li><a href="/Content/browse/cid/070201/">通知公告</a></li>
              <li><a href="/Content/browse/cid/070202/">企业新闻</a></li>
              <li><a href="/Content/browse/cid/070203/">微报道</a></li>
              <li><a href="/Content/browse/cid/070206/">图片新闻</a></li>
            </ul>
          </li>
          <li><h4>主导产品</h4>
            <ul class="subli">
              <li><a href="/Content/browse/cid/070309/">产品中心</a></li>
              <li><a href="/Content/browse/cid/070307/">国内销售</a></li>
              <li><a href="/Content/browse/cid/070308/">国际销售</a></li>
            </ul>
          </li>
          <li><h4>科技创新</h4>
            <ul class="subli">
              <li><a href="/Content/browse/cid/070401/">国家企业技术中心</a></li>
              <li><a href="/Content/browse/cid/070402/">博士后科研工作站</a></li>
              <li><a href="/Content/browse/cid/070403/">工程技术创新中心</a></li>
              <li><a href="/Content/browse/cid/070404/">科研成果</a></li>
            </ul>
          </li>
          <li><h4>企业管理</h4>
            <ul class="subli">
              <li><a href="/Content/browse/cid/070501/">人力资源</a></li>
              <li><a href="/Content/browse/cid/070502/">采购招标</a></li>
              <li><a href="/Content/browse/cid/070503/">安全环保</a></li>
              <li><a href="/Content/browse/cid/070504/">管理体系</a></li>
              <li><a href="/Content/browse/cid/070505/">政策法规</a></li>
            </ul>
          </li>
          <li><h4>党建工作</h4>
            <ul class="subli">
              <li><a href="/Content/browse/cid/070609/">学习二十大</a></li>
              <li><a href="/Content/browse/cid/070604/">导师带徒</a></li>
              <li><a href="/Content/browse/cid/070607/">专题专栏</a></li>
              <li><a href="/Content/browse/cid/070605/">青年工作</a></li>
              <li><a href="/Content/browse/cid/070608/">耀华通讯</a></li>
            </ul>
          </li>
          <li><h4>联系我们</h4>
            <ul class="subli">
              <li><a href="/Content/browse/cid/070701/">联系方式</a></li>
              <li><a href="/Content/browse/cid/070702/">在线留言</a></li>
              <li><a href="/Content/browse/cid/070703/">售后服务</a></li>
              <li><a href="/Content/browse/cid/070704/">常见问题</a></li>
            </ul>
          </li>
        </ul>
        <div class="right r">
          <div class="link"><h4>友情链接</h4>
            <el-select v-model="selectedLink" placeholder="请选择" @change="navigateToLink" size="small">
              <el-option
                  v-for="(item, index) in links"
                  :key="index"
                  :label="item.label"
                  :value="item.url"
              ></el-option>
            </el-select>
          </div>
          <h4 class="tel">客服电话</h4>
          <p><span>0086 (0335) 7068011</span>&nbsp;&nbsp;<span></span></p></div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="copyright">
      <div class="inner"><span>备案号：<a href="http://beian.miit.gov.cn/" target="_blank" style="color:#ddd;">冀ICP备2022012186号</a>&nbsp;&nbsp;版权所有© 中国耀华玻璃集团有限公司</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLink: '',
      links: [
        {label: '凯盛集团', url: 'http://www.ctiec.net/'},
        {label: '中国建材', url: 'http://www.cnbm.com.cn/'},
      ],
    };
  },
  methods: {
    navigateToLink() {
      if (this.selectedLink) {
        window.open(this.selectedLink, '_blank');
      }
    },
  },
};
</script>

<style scoped lang="less">
#footer {
  display: flex;
  flex-direction: column;

  .fotnav {
    flex: 1;
    padding: 35px 0;
    background: #333;

    .inner {
      display: flex;
      justify-content: space-between;

      .l {
        float: left;
        padding: 0;
        margin: 0;
      }

      .r {
        float: right;
      }
    }

    h4 {
      font-weight: bold;
      font-size: 14px;
      color: white;
      margin-bottom: 20px;
    }

    li {
      list-style: none;
      display: inline-block;
      width: 140px;
      text-align: left;
      vertical-align: top;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li a {
        text-decoration: none;
        color: #ccc;
        line-height: 24px;
        font-size: 12px;

        &:hover {
          color: #fff;
        }
      }
    }

    .right {
      width: 200px;

      .link {
        position: relative;
        padding: 10px 0;

        .linkt {
          width: 140px;
          height: 28px;
          line-height: 28px;
          border: 1px #999 solid;
          border-radius: 3px;
          color: #999;
          padding: 0 20px 0 14px;
          margin-bottom: 30px;
          background: #333 url(../images/linkicon.png) no-repeat right center;
          font-size: 12px;
          cursor: pointer;

          ul {
            position: absolute;
            top: 68px;
            left: 0;
            width: 198px;
            max-height: 140px;
            overflow: hidden;
            overflow-y: auto;
            border: 1px #999 solid;
            text-align: center;
            background: #333;
            z-index: 8;
            padding: 5px 0 10px;
            display: none;

            li {
              width: 198px;

              a {
                display: block;
                padding: 5px 20px 0;
                text-align: left;
                color: #ccc;
                font-size: 12px;
                text-decoration: none;

                &:hover {
                  color: white;
                }
              }
            }
          }
        }
      }

      .tel {
        height: 32px;
        line-height: 35px;
        padding-left: 25px;
        text-align: center;
        color: #ffa200;
        width: 200px;
        background: url(../images/telicon.png) no-repeat 55px center;
        //margin-bottom: 5px;
      }

      p {
        color: #fff;
        text-align: center;
        margin-bottom: 5px;
      }
    }
  }

  .copyright {
    padding: 15px 0;
    background: black;
    text-align: center;
    color: #ddd;
    vertical-align: middle;

    .inner {
      width: 1200px;
      margin: auto;
    }
  }

  .clear {
    clear: both;
  }
}
</style>
