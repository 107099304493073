<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/management/hr">人力资源</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/purchase">采购招标</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/security">安全环保</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/system">管理体系</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/statute">政策法规</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/management/hr">企业管理</a></li> &gt;
                    <li><a href="/#/management/security">安全环保</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "managementSecurity",
        data(){
            return {
                activeNames: [],
                items: [
                    {image: '5e7435be5c0db.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '2023年耀技公司自行监测',
                        publishDate: '2023-05-26',
                        content: '2023年耀技公司自行监测 文件链接',
                        moreLink: '/#/management/security'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '2022年耀技公司自行监测',
                        publishDate: '2023-05-25',
                        content: '2022年耀技公司自行监测 文件链接1 文件链接2 文件链接3 文件链接4...',
                        moreLink: '/#/management/security'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛耀华玻璃技术开发有限公司排污许可证',
                        publishDate: '2020-01-19',
                        content: '',
                        moreLink: '/#/management/security'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘耀节能玻璃有限公司排污许可证',
                        publishDate: '2020-01-19',
                        content: '',
                        moreLink: '/#/management/security'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘华特种玻璃有限公司排污许可证',
                        publishDate: '2020-01-19',
                        content: '',
                        moreLink: '/#/management/security'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘耀节能玻璃有限公司安标证书',
                        publishDate: '2020-01-19',
                        content: '',
                        moreLink: '/#/management/security'
                    },
                    {
                        name: '7',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘华特种玻璃有限公司安标证书',
                        publishDate: '2020-01-19',
                        content: '',
                        moreLink: '/#/management/security'
                    },
                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style scoped>

</style>
