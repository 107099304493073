<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="content">
                    <div id="title">
                        <h4>耀华玻璃厂历史沿革</h4>
                        <span class="source">来源：</span>
                        <span class="date">发布时间：2020-01-13</span>
                    </div>
                    <div id="text">
                        <p style="text-indent:2em;">
                            耀华是我国和亚洲第一家采用“机制平板玻璃”的工厂，已有近百年历史，是我国老的玻璃工业基地。
                        </p>
                        <p style="text-indent:2em;">
                            自1903年张骞等开始筹建以“吹筒法”工艺生产平板玻璃，并先后在湖北武昌、山东博山、江苏宿迁等地创建了平板玻璃厂，但因生产工艺落后，抵不过进口产品而先后停产倒闭。
                        </p>
                        <p style="text-indent:2em;">
                            耀华于1922年由周学熙实业集团通过引进“弗克法”先进技术而创办。1903年由比利时人哀米尔·弗克发明了“弗克法”（后称为“有石曹&nbsp;垂直引上法”），该项技术问世之后，使平板玻璃制作工艺由“手工生产”跨入“使用机器大批量生产”的时代。1914年世界上第一座采用“弗克法”制作平板玻璃的工厂在比利时建成投产，之后欧美一些国家也竞相采用，我国也引进了该项技术。1921年达成“中比合资办厂”的协议，公司定名为“耀华机器制造股份有限公司”，1922年3月27日（后被定为“厂庆日”）在天津召开了第一次董事会，宣告“耀华公司”正式成立。从耀华创办到秦皇岛解放前夕，耀华经历了“中比合办”、“中日合办”和“官商合办”三个时期，前后长达27年之久。
                        </p>
                        <p>
                            <br>
                        </p>
                        <table style="width:100%;" cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000">
                            <tbody>
                            <tr>
                                <td style="text-align:center;">
                                    <img src="@/../uploads/5dca1f5e74a60.jpg" title="5dca1f5e74a60.jpg" alt="5dca1f5e74a60.jpg"><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center;">
                                    <span>图为1924年耀华厂照片</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p style="text-indent:2em;">
                            <br>
                        </p>
                        <p style="text-indent:2em;">
                            新中国成立之后，特别是改革开放之后，我国国民经济快速发展，取得令人瞩目的辉煌成就。耀华的各项事业也蓬勃发展，蒸蒸日上，成绩喜人。在原有“有槽垂直引上工艺”基础上，试验成功了“无槽引上工艺”，1979年建成“压延玻璃生产线”；1980年从日本引进“对辊法”成型技术；1986年列为国家重点工程的450吨级厂内第一条浮法玻璃生产线建成投产；与此同时先后从国外引进了玻璃钢波纹（平）板生产线、玻璃钢型材生产线；不饱和聚酯树脂生产线；中空玻璃生产线；水平钢化玻璃生产线和真空溅射镀膜玻璃生产线；1996年建成了代表当时行业水平的“浮法示范线”等等。
                        </p>
                        <p>
                            <br>
                        </p>
                        <table cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000">
                            <tbody>
                            <tr>
                                <td style="text-align:center;width:33%;">
                                    <img src="@/../uploads/5eb4b2e080d26.jpg" title="5eb4b2e080d26.jpg" alt="5eb4b2e080d26.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    <img src="@/../uploads/5eb4b2e774113.jpg" title="5eb4b2e774113.jpg" alt="5eb4b2e774113.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    <img src="@/../uploads/5eb4b2ede9b44.jpg" title="5eb4b2ede9b44.jpg" alt="5eb4b2ede9b44.jpg" width="" height="" align=""><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center;width:33%;">
                                    图为1980年从日本引进“对辊法”成型技术<br>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    图为：1987年从美国引进“真空溅射镀膜玻璃技术及设备”<br>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    图为从德国引进“冷端技术与装备”<br>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;"><br></span>
                        </p>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;">自1985年以来曾获得多项行业及国家级荣誉称号，包括“全国建材行业先进（标兵）企业、全国企业管理优秀奖、全国设备管理优秀单位、国家技术开发优秀成果奖”七五“技术改造管理先进单位、全国思想政治工作优秀企业以及“五一劳动奖状”等，1988年被国家列入全国经济效益高的500家大型企业之列；</span>
                        </p>
                        <p style="text-indent:2em;">
                            <span style="line-height:1.5;"></span><br>
                            <span style="line-height:1.5;"></span>
                        </p>
                        <table cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000">
                            <tbody>
                            <tr>
                                <td style="text-align:center;width:50%;">
                                    <img src="@/../uploads/5eb4b2d621533.jpg" title="5eb4b2d621533.jpg" alt="5eb4b2d621533.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:50%;">
                                    <img src="@/../uploads/5eb4b2cc158a1.jpg" title="5eb4b2cc158a1.jpg" alt="5eb4b2cc158a1.jpg" width="" height="" align=""><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center;width:50%;">
                                    图为中央领导给耀华颁发企业管理优秀奖<br>
                                </td>
                                <td style="text-align:center;width:50%;">
                                    图为时任国家主席杨尚昆来耀华视察<br>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;"><br></span>
                        </p>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;">与此同时国家先后赋予耀华多项特殊政策。包括国家级计划单列企业集团、国家级企业技术中心、财务公司和直接对外进出口权，给予“债转股”特殊政策，“耀华玻璃”被批准在“上交所”上市融资等。耀华的发展与国家及各级政府给予的政策密不可分。</span>
                        </p>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;"><br></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "aboutHistorylist3"
    }
</script>

<style scoped>

</style>
