<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/management/hr">人力资源</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/purchase">采购招标</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/security">安全环保</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/system">管理体系</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/statute">政策法规</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="content">
                    <div id="rlzy">
                        <div class="bottom">
                            <h5>招聘动态</h5>
                            <div class="table">
                                <div class="tr">
                                    <div class="l zwmc">
                                        <h6>人力资源高级业务主管</h6>
                                        <span>招聘人数：</span>
                                        <span>学历：本科（一本及以上）</span>
                                        <span>经验：</span>
                                    </div>
                                    <div class="l date">2019-12-27</div>
                                    <div class="r more">
                                        <a href="/#/management/hr">查看 →</a>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                    <div id="page">
                        <ul>
                            <li>
                                <a href="javascript:void(0);">上一页</a>
                            </li>
                            <li class="on">
                                <a>1</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">下一页</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HumanResources",
        data(){
            return {
                items: [
                    {image: '5e7435be5c0db.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style scoped>
    /*人力资源*/
    #main #rlzy h5 {
        color:#ff3234;
        text-align:center;
        font-size:16px;
        padding-left:10px;
        /*	background:#f4f4f4;*/
        line-height:40px;

    }
    #main #rlzy .rlzytop .right {
        width:830px;
        padding:20px 70px 20px 0;
        line-height:2em;
        color:#666;
        /*text-indent:2em;	*/
    }
    #main #rlzy .rlzytop .right h5 {
        background:none;
        text-align:left;
        text-indent:0;
        padding-left:0;
        width:185px;
        border-bottom:2px #ff3234 solid;
        margin-bottom:10px;
    }
    #main #rlzy .rlzytop {
        border-bottom:1px #ccc solid;
        margin-bottom:30px;
    }
    #main #rlzy .table {
        margin-top:20px;
    }
    #main #rlzy .table .tr{
        width:510px;
        float:left;
        margin:0 25px;
        padding:10px 15px;
        border-bottom:1px #ccc dashed;
        position:relative;
    }
    #main #rlzy .table .zwmc {
        width:100%;
    }
    #main #rlzy .table .zwmc h6 {
        font-weight:normal;
        font-size:14px;
        padding-bottom:5px;
    }
    #main #rlzy .table .zwmc span{
        color:#999;
        margin-right:10px;
    }
    #main #rlzy .table .date {
        color:#999;
    }

    #main #rlzy .table .more {
        position:absolute;
        top:35px;
        right:0;
    }
    #main #rlzy .table .more a {
        color:#ff6700;
    }


</style>
