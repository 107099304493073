<template>
  <el-row class="main">
    <el-col :span="24">
      <!-- 面包屑 -->
      <div class="top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item @click.native="$router.go(0)">主导产品</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="$router.go(0)">产品中心</el-breadcrumb-item>
          <el-breadcrumb-item>{{ currentTab.name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-col>

    <el-col :span="24">
      <div class="inner">
        <!-- Tab 切换 -->
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
          <el-tab-pane v-for="tab in tabs" :label="tab.name" :name="tab.id" :key="tab.id">
            <!-- 内容 -->
            <el-row justify="center">
              <el-col v-for="product in paginatedProducts" :key="product.id" :span="6">
                <router-link :to="{ path: '/ProductCenter/ProductInfo', query: { id: product.id } }" class="item">
                  <div class="img" :style="{ backgroundImage: 'url(' + require('@/../uploads/' + product.image) + ')' }"></div>
                  <h5>{{ product.title }}</h5>
                </router-link>
              </el-col>
            </el-row>
            <!-- 分页查询 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tab.currentPage"
                :page-sizes="[4, 8, 16]"
                :page-size="tab.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tab.total"
            ></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {tabs} from './tabs'

export default {
  name: 'ProductCenter',
  data() {
    return {
      // 轮播图
      items: [
        {image: '5e1571ffb71c7.jpg'},
        {image: '63903952aa8f4.png'},
      ],
      activeTab: '07030901',
      tabs: tabs,
    };
  },
  created() {
    this.updateCarousel(this.items);
    // 控制标签页更新
    const query = this.$route.query;
    if (query.currentTab) {
      this.activeTab = query.currentTab;
    }
  },
  computed: {
    currentTab() {
      return this.tabs.find(tab => tab.id === this.activeTab);
    },
    paginatedProducts() {
      const start = (this.currentTab.currentPage - 1) * this.currentTab.pageSize;
      const end = start + this.currentTab.pageSize;
      return this.currentTab.products.slice(start, end);
    },
  },
  methods: {
    updateCarousel(items) {
      this.$emit('update-carousel', items);
    },
    handleTabClick(tab) {
      console.log(tab);
    },
    handleSizeChange(val) {
      this.currentTab.pageSize = val;
      // 发送分页请求
    },
    handleCurrentChange(val) {
      this.currentTab.currentPage = val;
      // 发送分页请求
    },
  },
};
</script>

<style lang="less">
.main {
  width: 1200px;
  margin: 0 auto;
  padding: 0;

  .top {
    height: 60px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    padding-left: 10px;

    a {
      text-decoration: none;

      &:hover {
        color: #e60012;
      }
    }

    span {
      cursor: pointer;
      font-weight: bold;
      color:#333;
    }

    .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner {
          &:hover {
            color: #e60012;
          }
        }
      }
    }
  }

  .inner {
    padding: 10px 0;
    margin: auto;
    text-align: center;

    .el-tabs {
      padding-left: 10px;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: inherit;

      .img {
        width: 280px;
        height: 212px;
        background-size: cover;
        background-position: center;
        margin-bottom: 10px;
      }

      h5 {
        margin: 0 0 10px 0;
        padding: 5px;
        font-size: 14px;
        font-weight: normal;
        transition: color 0.3s;

        &:hover {
          color: #e60012;
        }
      }
    }

    .el-tabs__item.is-active,
    .el-tabs__item:hover {
      color: #e60012;
    }

    .el-tabs__active-bar {
      background-color: #e60012;
      height: 2px;
    }

    .el-pagination {
      margin: 10px 40px 0 0;
      text-align: right;
      // 中间页数
      .el-pager {
        li:hover, li.active {
          color: #e60012;
        }
      }

      // 箭头
      .btn-next.hover, .btn-prev.hover {
        color: #e60012;
      }

      // 前往 页样式
      .el-pagination__jump.active {
        border-color: #e60012;
      }

      .el-select-dropdown__item li {
        color: #e60012;
      }
    }
  }
}
</style>
