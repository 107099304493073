<template>
  <div>
    <!-- 头部 -->
    <header>
      <HeaderNavigation/>
    </header>

    <!-- 轮播图 -->
    <el-carousel :interval="interval" :arrow="items.length > 1 ? 'hover' : 'never'"
                 :indicator-position="items.length > 1 ? 'inside' : 'none'" trigger="click">
      <el-carousel-item v-for="(item, index) in items" :key="index">
        <div class="image-container">
          <img :src="require(`@/../uploads/${item.image}`)" alt="">
        </div>
        <div class="carousel-item-text">
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>

    <router-view @update-carousel="updateCarousel"></router-view>

    <!-- 底部 -->
    <footer>
      <FooterNavigation/>
    </footer>

  </div>
</template>

<script>
import HeaderNavigation from "@/components/top/HeaderNavigation.vue";
import FooterNavigation from "@/components/bottom/FooterNavigation.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Layout",
  data() {
    return {
      interval: 5000, // 轮播间隔时间
      items: [] // 初始化轮播图片数据
    }
  },
  methods: {
    // 更新轮播图片数据的方法
    updateCarousel(items) {
      this.items = items;
    }
  },
  components: {
    HeaderNavigation,
    FooterNavigation,
  },
}
</script>

<style lang="less">
.image-container {
  text-align: center;
  overflow: hidden;
  background: #ccc;

  img {
    width: 1920px;
    height: 480px;
    object-fit: cover;
  }
}

.el-carousel__container {
  height: 480px;
}
</style>
