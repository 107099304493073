<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/party/learn20">学习二十大</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/mentor">导师带徒</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/special">专题专栏</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/youthWork">青年工作</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/communication">耀华通讯</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/party/learn20">党建工作</a></li> &gt;
                    <li><a href="/#/party/special">专题专栏</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "partySpecial",
        data(){
            return{
                activeNames: [],
                items: [
                    {image: '5e0d90764dd98.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '70周年系列活动—歌咏比赛',
                        publishDate: '2019-11-12',
                        content: '为热烈庆祝中华人民共和国成立70周年，歌颂祖国伟大成就，激发全体员工爱国情感，激励全体干部职工攻坚克难、奋力前行。9月28日上午...',
                        moreLink: '/#/party/special'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '70周年系列活动—观影活动',
                        publishDate: '2019-10-18',
                        content: '为庆祝新中国成立70周年，激发广大职工爱国热情，耀华集团公司9月31日-10月18日 组织700余人职工，分十一批次...',
                        moreLink: '/#/party/special'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '70周年系列活动—市诵读',
                        publishDate: '2019-11-13',
                        content: '9月17日晚，秦皇岛市总工会组织的“不忘初心、牢记使命”学习习近平新时代中国特色社会主义思想职工诵读展演在秦皇岛人民广场精彩上演...',
                        moreLink: '/#/party/special'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '70周年系列活动—趣味运动会',
                        publishDate: '2019-11-13',
                        content: '9月6日弘耀公司举办的一场别开生面的趣味运动会，共有40余名职工参加此次趣味运动会。 为提升员工身体素质，增进职工友...',
                        moreLink: '/#/party/special'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '一岗双责制度',
                        publishDate: '2019-11-12',
                        content: '“一岗”就是一个领导干部的职务所对应的岗位；“双责”就是一个领导干部既要对所在岗位应当承担的具体业务工作负责，又要对所在岗位应当...',
                        moreLink: '/#/party/special'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '四风问题',
                        publishDate: '2019-11-12',
                        content: '四风问题表现，一是形式主义，群众反映最突出的是追求形式、不重实效，图虚名、务虚功、工作不抓落实。二是官僚主义，群众最不满意的是办...',
                        moreLink: '/#/party/special'
                    },
                    {
                        name: '7',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '七一',
                        publishDate: '2019-07-01',
                        content: '6月28日下午，耀华集团党委隆重召开庆祝建党98周年暨“不忘初心、牢记使命”主题教育动员大会。耀华集团党委、行政领导、各基层单位...',
                        moreLink: '/#/party/special'
                    },

                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style scoped>

</style>
