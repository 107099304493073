export const tabs = [
    {
        id: '07030901',
        name: '平板玻璃',
        products: [
            {id: '86', image: '5e0d654edb00b.jpg', title: '浮法透明玻璃', description: '浮法透明玻璃'},
            {id: '366', image: '639029163c1bf.jpg', title: '黑色玻璃', description: '黑色玻璃'},
            {id: '180', image: '5e05aedd6215b.jpg', title: '金茶玻', description: '金茶玻'},
            {id: '71', image: '5e05a6f5d76bf.jpg', title: '茶玻璃', description: '茶玻璃'},
            {id: '70', image: '5e05b6037be5a.jpg', title: '深灰玻璃', description: '深灰玻璃'},
            {id: '69', image: '5e05b593ed039.jpg', title: '浅灰玻璃', description: '浅灰玻璃'},
            {id: '68', image: '5e05ade2b9382.jpg', title: '粉玻璃', description: '粉玻璃'},
            {id: '67', image: '5e05ae4b6a05b.jpg', title: '福特蓝玻璃', description: '福特蓝玻璃'},
        ],
        currentPage: 1,
        pageSize: 8,
        total: 8,
    },
    {
        id: '07030907',
        name: '镀膜玻璃',
        products: [
            {id: '179', image: '5e057cbdc8e26.jpg', title: '金茶色镀膜玻璃', description: '金茶色镀膜玻璃'},
            {id: '178', image: '5e057431b406c.jpg', title: '白色镀膜玻璃', description: '白色镀膜玻璃'},
            {id: '83', image: '5dc4bc941303e.jpg', title: '在线彩膜玻璃', description: '在线彩膜玻璃'},
            {id: '81', image: '5e057c4b3a45f.jpg', title: '茶色镀膜玻璃', description: '茶色镀膜玻璃'},
            {id: '79', image: '5e057eb916c0e.jpg', title: '福特蓝镀膜玻璃', description: '福特蓝镀膜玻璃'},
            {id: '77', image: '5e057d1bc6ce0.jpg', title: '粉红镀膜玻璃', description: '粉红镀膜玻璃'},
            {id: '76', image: '5e05a5055f84b.jpg', title: '深灰镀膜玻璃', description: '深灰镀膜玻璃'},
            {id: '75', image: '5e0580203d520.jpg', title: '浅灰镀膜玻璃', description: '浅灰镀膜玻璃'},
        ],
        currentPage: 1,
        pageSize: 8,
        total: 8,
    },
    {
        id: '07030908',
        name: '特种玻璃',
        products: [
            {id: '385', image: '639048cb025af.jpg', title: '硼硅4.0防火玻璃（BG40）', description: '硼硅4.0防火玻璃（BG40）'},
            {id: '384', image: '639045c6d7f16.jpg', title: '硼硅3.3浮法玻璃（BG33）', description: '硼硅3.3浮法玻璃（BG33）'},
            {id: '383', image: '6390450abc947.jpg', title: '高效薄膜太阳能电池高温玻璃（中铝玻璃）', description: '高效薄膜太阳能电池高温玻璃（中铝玻璃）'},
            {id: '382', image: '639042fb4056e.jpg', title: '光热玻璃', description: '光热玻璃'},
            {id: '381', image: '63903c8def2ee.png', title: '超白玻璃', description: '超白玻璃'},
        ],
        currentPage: 1,
        pageSize: 8,
        total: 5,
    },
    {
        id: '07030909',
        name: '深加工玻璃',
        products: [
            {id: '395', image: '639050bba208a.jpg', title: 'CIGS薄膜太阳能电池背电极', description: 'CIGS薄膜太阳能电池背电极'},
            {id: '394', image: '6390507ba0d5f.jpg', title: '调光玻璃', description: '调光玻璃'},
            {id: '393', image: '63904fc98a69c.jpg', title: '家电玻璃', description: '家电玻璃'},
            {id: '392', image: '63904f79b1c22.jpg', title: '彩釉玻璃', description: '彩釉玻璃'},
            {id: '391', image: '63904f1bd2e44.jpg', title: '弯钢玻璃', description: '弯钢玻璃'},
            {id: '390', image: '63904e9f2ecb9.jpg', title: '钢化玻璃钢化玻璃', description: '钢化玻璃'},
            {id: '389', image: '63904e3173511.png', title: '夹层玻璃', description: '夹层玻璃'},
            {id: '388', image: '63904de2a9999.jpg', title: '中空玻璃', description: '中空玻璃'},
            {id: '387', image: '63904d3159f96.jpg', title: '阳光控制镀膜玻璃', description: '阳光控制镀膜玻璃'},
            {id: '386', image: '63904b7aaeae0.jpg', title: '低辐射镀膜玻璃', description: '低辐射镀膜玻璃'},
        ],
        currentPage: 1,
        pageSize: 8,
        total: 10,
    },
    {
        id: '07030906',
        name: '汽车玻璃',
        products: [
            {id: '215', image: '5e54e6eab572e.jpg', title: '汽车玻璃', description: '汽车玻璃'},
        ],
        currentPage: 1,
        pageSize: 8,
        total: 1,
    },
    {
        id: '07030903',
        name: '光伏玻璃',
        products: [
            {id: '217', image: '5e66e3c6b698b.jpg', title: '晶硅电池2mm背板玻璃', description: '晶硅电池2mm背板玻璃'},
            {id: '216', image: '5e66e38f51b9c.jpg', title: '碲化镉电池2.2mm背板玻璃', description: '碲化镉电池2.2mm背板玻璃'},
            {id: '214', image: '5e54e4ae91f04.jpg', title: '光伏玻璃', description: '光伏玻璃'},
        ],
        currentPage: 1,
        pageSize: 8,
        total: 3,
    },
    {
        id: '07030905',
        name: '电子玻璃',
        products: [
            {id: '84', image: '5e54e27eba0ce.png', title: '电子玻璃', description: '电子玻璃'},
        ],
        currentPage: 1,
        pageSize: 8,
        total: 1,
    },
]
