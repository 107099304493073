<template>
  <el-row class="main">
    <el-col :span="24">
      <!-- 面包屑 -->
      <div class="top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/ProductCenter' }">主导产品</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/ProductCenter' }">产品中心</el-breadcrumb-item>
          <el-breadcrumb-item><router-link :to="{ path: '/ProductCenter', query: { currentTab: product.parentId } }">{{ product.parentName }}</router-link></el-breadcrumb-item>
          <el-breadcrumb-item>{{ product.name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-col>

    <el-col :span="24">
      <div class="inner">
        <!-- 标题 -->
        <div id="title">
          <h4>{{ product.name }}</h4>
          <span class="source">来源：{{ product.source }}</span>
          <span class="date">发布时间：{{ product.date }}</span>
        </div>
        <!-- 产品信息 -->
        <div class="product-info">
          <!-- 介绍 -->
          <div id="text">
            <div v-for="(item, index) in product.content.description" :key="'description-' + index" style="padding-top: 5px">
              <p style="text-align: left; font-weight: bold;">{{ item.title }}</p>
              <p style="text-align: left; font-weight: bold;">{{ item.subtitle }}</p>
              <p id="content" style="text-align: left;" v-html="item.content.replace(/\n/g, '<br/>')"></p>
            </div>
            <!-- 表格 -->
            <div id="table" v-html="tableContent(product.id)"></div>
            <!-- 电话 -->
            <p style="text-align: left">{{ product.content.phone }}</p>
            <!-- 产品 -->
            <div v-for="(item, index) in product.content.image" :key="'image-' + index">
              <img :src="require(`@/../uploads/${item}`)" alt="Product Image">
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {products} from './products'
import tables from './table/table.js';

export default {
  name: 'ProductInfo',
  data() {
    return {
      // 轮播图
      items: [
        {image: '5e1571ffb71c7.jpg'},
        {image: '63903952aa8f4.png'},
      ],
      product: {
        id: '',
        name: '',
        title: '',
        source: '',
        date: '',
        content: {
          description: [],
          phone: '',
          image: []
        },
      },
      tableContent(productId) {
        return tables[`table${productId}`];
      },
      productData: products,
    };
  },
  created() {
    this.updateCarousel(this.items);
    this.getProductInfo();
  },
  methods: {
    updateCarousel(items) {
      this.$emit('update-carousel', items);
    },
    getProductInfo() {
      const productId = this.$route.query.id;
      this.product = this.getProductById(productId) || this.product;
    },
    getProductById(id) {
      return this.productData.find(product => product.id === id);
    },
  }
};
</script>

<style>

.main {
  width: 1200px;
  margin: 0 auto;
  padding: 0;

  .top {
    height: 60px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;

      &:hover {
        color: #e60012;
      }
    }

    span {
      cursor: pointer;
      font-weight: bold;
      color:#333;
    }
  }

  .inner {
    padding: 0;
    text-align: center;
    margin: auto;
    background: #f5f5f5;

    #title {
      text-align: center;
      margin-bottom: 20px;
      background: #ebebeb;

      h4 {
        font-size: 18px;
        margin: 0;
      }

      span {
        margin: 0 5px;
        font-size: 14px;
        color: #999;
      }
    }

    .product-info {
      padding: 0 40px;
      min-height: 300px;

      #text {
        padding: 0;

        p {
          padding: 2px 0;
        }

        #content {
          padding: 0;
        }

        #table {
          padding: 5px 0;
        }

        img {
          width: 100%;
          height: 100%;
          padding: 10px 0;
          text-align: center;
        }
      }
    }
  }
}
</style>
