<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/news/notice">通知公告</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/news/companyNews">企业新闻</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/news/vReport">微报道</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/news/imgNews">图片新闻</a></h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="top">
                    <div class="mbxdh">
                        <ul>
                            <li>
                                <a href="/">首页</a>
                            </li> &gt;
                            <li>
                                <a href="/#/news/notice">新闻中心</a>
                            </li> &gt;
                            <li>
                                <a href="/#/news/notice">通知公告</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="dang">
                  <div class="box">
                    <a href="/#/news/notice">
                      <div class="left">2023-11-05</div>
                      <div class="right">
                        <h4>中国耀华玻璃集团有限公司违规行为举报电话：18233580085</h4>
                        <p>
                          <br>
                        </p>
                        <p>中国耀华玻璃集团有限公司违规行为举报电话：18233580085</p>
                      </div>
                      <div class="clear"></div>
                    </a>
                  </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2023-09-20</div>
                            <div class="right">
                                <h4>中国耀华玻璃集团有限公司社会化招聘 拟录用人员公示</h4>
                                <p>
                                    <br>
                                </p>
                                <p>中国耀华玻璃集团有限公司社会化招聘


                                    拟录用人员公示


                                    根据《中国耀华玻璃集团有限公司部分管理岗位招聘公...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2023-05-15</div>
                            <div class="right">
                                <h4>中国耀华玻璃集团有限公司洛阳龙昊600t/d浮法玻璃生产线冷修及节能智能化提升项目环境影</h4>
                                <p>中国耀华玻璃集团有限公司洛阳龙昊600t/d浮法玻璃生产线冷修及节能智能化提升项目环境影响评价第二次公众参与信息公示

                                    ...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2023-03-10</div>
                            <div class="right">
                                <h4>中国耀华玻璃集团有限公司洛阳龙昊600t/d浮法玻璃生产线冷修技改项目 环境影响评价公众</h4>
                                <p>中国耀华玻璃集团有限公司洛阳龙昊600t/d浮法玻璃生产线冷修技改项目现正式开展环境影响评价工作，根据《环境影响评价公众参与办法...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2022-06-01</div>
                            <div class="right">
                                <h4>公 告</h4>
                                <p>中国耀华玻璃集团有限公司正在对其所持有的秦皇岛耀华玻璃机械制造有限责任公司10%股权进行转让，该公司目前生产经营业务与耀华无关。...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2022-06-01</div>
                            <div class="right">
                                <h4>公 告</h4>
                                <p>  秦皇岛耀华装备集团股份有限公司、秦皇岛耀华运输有限责任公司非中国耀华玻璃集团有限公司的下属公司，上述两家公司不得使用“耀华”...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2022-06-01</div>
                            <div class="right">
                                <h4>公 告</h4>
                                <p>  中国耀华玻璃集团有限公司持有的“耀华”商标，2002年被国家工商总局认定为“中国驰名商标”。依照《中华人民共和国商标法》等法...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2022-01-10</div>
                            <div class="right">
                                <h4>关于清理拖欠民营企业账款线索联系人的公告</h4>
                                <p>为贯彻落实党中央、国务院决策部署，推动清理拖欠民营企业账款工作取得更大成效，现将我公司拖欠线索信访联系人信息公布如下：


                                    ...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2021-06-28</div>
                            <div class="right">
                                <h4>秦皇岛耀华玻璃技术开发有限公司 社会责任报告</h4>
                                <p></p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司2020年自行监测方案2020</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司2020年自行监测方案2020...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司企业基本信息</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司企业基本信息...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司废水检测报告（2020年第二季度）</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司废水检测报告（2020年第二季度）...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司噪声检测报告（2020年第二季度）</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司噪声检测报告（2020年第二季度）...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司废气检测报告（2020年第二季度）</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司废气检测报告（2020年第二季度）...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司废气检测报告（2020年第一季度）</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司废气检测报告（2020年第一季度）...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司废水噪声检测报告（2020年第一季度）</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司废水噪声检测报告（2020年第一季度）...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛北方玻璃有限公司固定污染源烟气自动监测设备比对监测报告</h4>
                                <p>附件下载：秦皇岛北方玻璃有限公司固定污染源烟气自动监测设备比对监测报告...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛耀华玻璃技术开发有限公司（5.25-6.28）废气自行监测报告</h4>
                                <p>附件下载：秦皇岛耀华玻璃技术开发有限公司（5.25-6.28）废气自行监测报告...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛耀华玻璃技术开发有限公司废气比对监测报告20200810</h4>
                                <p>附件下载：秦皇岛耀华玻璃技术开发有限公司废气比对监测报告20200810...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛耀华玻璃技术开发有限公司在线设备验收比对监测报告20200810</h4>
                                <p>附件下载：秦皇岛耀华玻璃技术开发有限公司在线设备验收比对监测报告20200810...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                    <div class="box">
                        <a href="/#/news/notice">
                            <div class="left">2020-08-14</div>
                            <div class="right">
                                <h4>秦皇岛耀华玻璃技术开发有限公司在线设备比对监测报告20200305</h4>
                                <p>附件下载：秦皇岛耀华玻璃技术开发有限公司在线设备比对监测报告20200305...</p>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                </div>
                <div id="page">
                    <ul>
                        <li>
                            <a href="javascript:void(0);">上一页</a>
                        </li>
                        <li class="on">
                            <a>1</a>
                        </li>
                        <li>
                            <a href="/Content/browse/cid/070201/p/2">2</a>
                        </li>
                        <li>
                            <a class="next" href="/Content/browse/cid/070201/p/2">下一页</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "newsNotice",
        data() {
            return{
                items: [
                    {image: '5e0d91686ddb4.jpg', title: 'Title 1', description: 'Description 1'}
                ],
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style scoped>
    #dang .box {
        border-bottom:1px #ccc dashed;
        padding:0 0 15px;
        margin-bottom:15px;
    }
    #dang .box .left {
        float:left;
        padding:45px 0 10px;
        background:url(../../components/images/date.png) no-repeat center 20px;
        color:#666;
        width:125px;
        border-right:1px #ccc solid;
        text-align:center;
    }
    #dang .box .right {
        float:right;
        width:1040px;
        padding-right:10px;
    }
    #dang .box .right h4 {
        font-weight:normal;
        padding-bottom:10px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    #dang .box .right p {
        color:#999;
    }
    #dang .box a:hover p{
        color:#333;
    }
</style>
