<template>
  <div id="main">

    <div id="subnav">
      <ul>
        <li class="nli">
          <h4><a href="/#/contact/ContactInformation">联系方式</a></h4>
        </li>
        <li class="nli">
          <h4><a href="/#/contact/OnlineMessage">在线留言</a></h4>
        </li>
        <li class="nli">
          <h4><a href="/#/contact/AfterSalesService">售后服务</a></h4>
        </li>
        <li>
          <h4><a href="/#/contact/CommonQuestion">常见问题</a></h4>
        </li>
      </ul>
    </div>

    <div id="contact">
      <div class="left l">
        <h4>中国耀华玻璃集团有限公司</h4>
        <div class="add">
          <strong>集团地址：</strong>河北省秦皇岛市海港区西港北路61号
        </div>
        <div class="tel">
          <strong>咨询电话：</strong>0086（0335）7068011 7068188
        </div>
        <div class="email">
          <strong>邮箱：</strong>internationaltrade@glassyaohua.com
        </div>
      </div>
      <div class="right l">
        <div class="wx">
          <span class="p">微信公众号</span>
          <span class="img">
                <img src="@/../uploads/5da8198ce6877.png">
              </span>
        </div>
        <div class="wb">
          <span class="p">官方微博</span>
          <span class="img">
                <img src="@/../uploads/5e65be660c335.png">
              </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'ContactInformation',
  data() {
    return {
      items: [
        {image: 'servicebanner.jpg'}
      ],
    }
  },
  mounted() {
    this.$emit('update-carousel', this.items);
  },
};
</script>

<style lang="less">
#main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  background: url(@/components/images/mainbg.png) no-repeat bottom center;
}

#subnav {
  width: 1200px;
  text-align: center;
}

#contact {
  position: relative;
  display: flex;
  width: 1200px;
  border-radius: 3px;
  padding: 20px;
  margin: auto;
  z-index: 2;
  background: white;
  justify-content: space-between;

  .left {
    flex: 1;
    width: 498px;
    border-right: 1px #e5e5e5 solid;
    padding: 30px 20px 50px 80px;

    div {
      padding: 5px 0 5px 30px;
      background-repeat: no-repeat;
      background-position: left center;
    }

    .add {
      background-image: url(@/components/images/contacticon.png);
    }

    .tel {
      background-image: url(@/components/images/contacticon-02.png);
    }

    .email {
      background-image: url(@/components/images/contacticon-03.png);
    }

    h4 {
      padding: 0 0 10px 0;
      font-size: 18px;
    }
  }

  .right {
    flex: 1;
    max-width: 50%;
    padding: 30px 20px 0 150px;

    span {
      display: block;
      text-align: center;
    }

    .wx {
      display: inline-block;
      margin-right: 45px;

      .p {
        padding-left: 45px;
        line-height: 36px;
        margin-bottom: 20px;
        background: url(@/components/images/wx.png) no-repeat left center;
      }
    }

    .wb {
      display: inline-block;

      .p {
        padding-left: 45px;
        line-height: 36px;
        margin-bottom: 20px;
        background: url(@/components/images/wb.png) no-repeat left center;
      }
    }
  }
}
</style>
