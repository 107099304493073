<template>
  <div id="main">
    <div class="subnav">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductCenter' }">主导产品</el-breadcrumb-item>
        <el-breadcrumb-item @click.native="$router.go(0)">国际销售</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="inner">
      <div class="map sjmap">
        <div class="cn"><img src="@/components/images/sjmap.png"></div>

        <div class="dq yzdq" @click="toggleList('yzdq')">
          <h4>亚洲</h4>
          <span class="img"><img src="@/components/images/yzdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['yzdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('yzdq')">关闭</span>
          <h5>亚洲</h5>
          <div class="li">
            <h6>特种玻璃（超白玻璃、光热玻璃、硼硅玻璃）</h6>
            <p>电话：0086-132-7337-0205</p>
            <p>邮箱：Daisydai@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-132-7337-0205</p>
          </div>
          <div class="li">
            <h6>浮法玻璃（优质浮法白玻、色玻）</h6>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
        </div>

        <div class="dq ozdq" @click="toggleList('ozdq')">
          <h4>欧洲</h4>
          <span class="img"><img src="@/components/images/ozdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['ozdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('ozdq')">关闭</span>
          <h5>欧洲</h5>
          <div class="li">
            <h6>特种玻璃（超白玻璃、光热玻璃、硼硅玻璃）</h6>
            <p>电话：0086-132-7337-0205</p>
            <p>邮箱：Daisydai@glassyaohua.com</p>\
            <p>联系人:&nbsp;&nbsp;联系电话：0086-132-7337-0205</p>
          </div>
          <div class="li">
            <h6>浮法玻璃（优质浮法白玻、色玻）</h6>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
        </div>

        <div class="dq fzdq" @click="toggleList('fzdq')">
          <h4>非洲</h4>
          <span class="img"><img src="@/components/images/fzdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['fzdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('fzdq')">关闭</span>
          <h5>非洲</h5>
          <div class="li">
            <h6>特种玻璃（超白玻璃、光热玻璃、硼硅玻璃）</h6>
            <p>电话：0086-132-7337-0205</p>
            <p>邮箱：Daisydai@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-132-7337-0205</p>
          </div>
          <div class="li">
            <h6>浮法玻璃（优质浮法白玻、色玻）</h6>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
        </div>

        <div class="dq bmzdq" @click="toggleList('bmzdq')">
          <h4>北美洲</h4>
          <span class="img"><img src="@/components/images/bmzdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['bmzdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('bmzdq')">关闭</span>
          <h5>北美洲</h5>
          <div class="li">
            <h6>特种玻璃（超白玻璃、光热玻璃、硼硅玻璃）</h6>
            <p>电话：0086-132-7337-0205</p>
            <p>邮箱：Daisydai@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-132-7337-0205</p>
          </div>
          <div class="li">
            <h6>浮法玻璃（优质浮法白玻、色玻）</h6>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
        </div>

        <div class="dq nmzdq" @click="toggleList('nmzdq')">
          <h4>南美洲</h4>
          <span class="img"><img src="@/components/images/nmzdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['nmzdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('nmzdq')">关闭</span>
          <h5>南美洲</h5>
          <div class="li">
            <h6>特种玻璃（超白玻璃、光热玻璃、硼硅玻璃）</h6>
            <p>电话：0086-132-7337-0205</p>
            <p>邮箱：Daisydai@glassyaohua.com</p><br>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-132-7337-0205</p>
          </div>
          <div class="li">
            <h6>浮法玻璃（优质浮法白玻、色玻）</h6>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
        </div>

        <div class="dq dyzdq" @click="toggleList('dyzdq')">
          <h4>大洋洲</h4>
          <span class="img"><img src="@/components/images/dyzdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['dyzdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('dyzdq')">关闭</span>
          <h5>大洋洲</h5>
          <div class="li">
            <h6>特种玻璃（超白玻璃、光热玻璃、硼硅玻璃）</h6>
            <p>电话：0086-132-7337-0205</p>
            <p>邮箱：Daisydai@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-132-7337-0205</p>
          </div>
          <div class="li">
            <h6>浮法玻璃（优质浮法白玻、色玻）</h6>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DomesticSales",
  data() {
    return {
      // 轮播图
      items: [
        {image: '5e1571ffb71c7.jpg'},
        {image: '63903952aa8f4.png'},
      ],
      showList: {
        yzdq: false,
        ozdq: false,
        fzdq: false,
        bmzdq: false,
        nmzdq: false,
        dyzdq: false,
      }
    };
  },
  created() {
    this.updateCarousel(this.items);
  },
  mounted() {
  },
  methods: {
    updateCarousel(items) {
      this.$emit('update-carousel', items);
    },
    toggleList(region) {
      // 切换显示状态
      this.showList[region] = !this.showList[region];
      // 如果有其他地区的列表显示，则隐藏其他地区的列表
      Object.keys(this.showList).forEach(key => {
        if (key !== region) {
          this.showList[key] = false;
        }
      });
    },
    hideList(region) {
      this.showList[region] = false;
    },
  }
};
</script>

<style scoped lang="less">
#main {
  padding: 0 0 50px;
  width: 100%;
  background: #f5f5f5 url(@/components/images/mainbg.png) no-repeat bottom center;

  .subnav {
    display: flex;
    width: 1920px;
    height: 60px;
    margin: 0 auto;
    padding-left: 10px;
    align-items: center;
    background-color: #f0f0f0;

    a {
      text-decoration: none;

      &:hover {
        color: #e60012;
      }
    }

    span {
      cursor: pointer;
      font-weight: bold;
      color: #333;
    }

    .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner {
          &:hover {
            color: #e60012;
          }
        }
      }
    }
  }

  .inner {
    position: relative;

    .sjmap {
      position: relative;
      width: 1200px;
      height:545px;
      padding-top:80px;

      .cn {
        position: absolute;
        top:80px;
        left: 0;
        z-index: 2;

        img {
          display: block;
          background-color: transparent;
        }
      }

      .dq {
        position: absolute;
        cursor: pointer;
        z-index: 3;

        img {
          opacity: 0;
          transition: opacity 0.5s;
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }

      .yzdq {
        width: 530px;
        height: 368px;
        top: 98px;
        right: 22px;

        h4 {
          top: 150px;
          left: 180px;
        }
      }

      .ozdq {
        width: 206px;
        height: 212px;
        top: 103px;
        right: 505px;

        h4 {
          top: 150px;
          left: 120px;
        }
      }

      .fzdq {
        width: 219px;
        height: 234px;
        top: 310px;
        right: 470px;

        h4 {
          top: 80px;
          left: 120px;
        }
      }

      .bmzdq {
        width: 507px;
        height: 330px;
        top: 80px;
        left: 20px;

        h4 {
          top: 180px;
          left: 180px;
        }
      }

      .nmzdq {
        width: 152px;
        height: 231px;
        bottom: 0px;
        left: 302px;

        h4 {
          top: 70px;
          left: 60px;
        }
      }

      .dyzdq {
        width: 216px;
        height: 126px;
        bottom: 34px;
        right: 58px;

        h4 {
          top: 35px;
          left: 45px;
        }
      }

      h4 {
        width: 90px;
        position: absolute;
        top: 0;
        z-index: 5;
      }

      .list {
        position: absolute;
        top: 125px;
        left: 0;
        width: 1180px;
        height: 550px;
        padding: 10px;
        border: 1px #ccc solid;
        background: white;
        z-index: 6;
        display: none;
        overflow: hidden;
        overflow-y: auto;

        h5 {
          font-size: 16px;
          color: #138bdc;
          text-align: center;
          padding-bottom: 10px;
          border-bottom: 1px #ccc solid;
        }

        .close {
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          padding: 2px 10px;
          background: #f4f4f4;
          color: #666;
          border-radius: 3px;

          &:hover {
            background: #ddd;
          }
        }

        .li {
          padding: 10px;
          width: 550px;
          float: left;
          margin: 0 5px;
          border-bottom: 1px #ccc dashed;

          p {
            color: #666;
          }

          h6 {
            font-size: 16px;
            padding-bottom: 5px;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>
