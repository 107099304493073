const tables = {
    table86 : `
        <table className="MsoNormalTable ke-zeroborder" border="0" cellSpacing="0" style="width:384pt;border:none;">
            <tbody>
            <tr>
                <td width="768" valign="center" colSpan="8" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="text-align:center;margin-left:0pt;vertical-align:middle;">
                        <b><span style="font-family:宋体;font-size:16pt;"><span>透明浮法玻璃性能参数</span></span></b>
                    </p>
                    <p className="MsoNormal" align="center" style="text-align:center;margin-left:0pt;vertical-align:middle;">
                        <b><span style="font-family:宋体;font-size:16pt;"> <span>Clear float glass performance parameters</span></span></b><b><span
                        style="font-family:宋体;font-size:16pt;"></span></b>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="96" valign="center" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>产品名称</span></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>Name of glass</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="192" valign="center" colSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="text-align:center;margin-left:0pt;vertical-align:middle;">
                        <span
                            style="font-family:宋体;font-size:10pt;"><span>可见光</span> <span>Visible light</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="192" valign="center" colSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span
                            style="font-family:宋体;font-size:10pt;"><span>太阳光</span><span>Solar energy（300nm-2500nm）</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">遮蔽系数</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Shading Coefficient</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>太阳光近红外直接透射比</span></span>
                    </p>
                    <p className="MsoNormal" align="center" style="text-align:center;margin-left:0pt;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>T</span></span><sub><span className="15"
                                                                                                       style="font-family:宋体;font-size:10.0000pt;vertical-align:sub;"><span>IR</span></span></sub><span
                        className="16"
                        style="font-family:宋体;font-size:10.0000pt;">% solar infrared direct transmittance</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>紫外透射比</span></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>T</span></span><sub><span className="15"
                                                                                                       style="font-family:宋体;font-size:10.0000pt;vertical-align:sub;"><span>UV</span></span></sub><span
                        className="16" style="font-family:宋体;font-size:10.0000pt;">% UV transmittance </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="text-align:center;margin-left:0pt;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>透射比</span></span>
                    </p>
                    <p className="MsoNormal" align="center" style="text-align:center;margin-left:0pt;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>T</span></span><sub><span className="15"
                                                                                                       style="font-family:宋体;font-size:10.0000pt;vertical-align:sub;"><span>可</span></span></sub><span
                        className="16" style="font-family:宋体;font-size:10.0000pt;">%</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>玻面反射比</span></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>R</span></span><sub><span className="15"
                                                                                                       style="font-family:宋体;font-size:10.0000pt;vertical-align:sub;"><span>玻可</span></span></sub><span
                        className="16" style="font-family:宋体;font-size:10.0000pt;">%</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>直接透射比</span><span>Te%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>玻璃面直接反射比</span></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>Re%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.8mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.6
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">8.9</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        78.5
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        2mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.2
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">8.6</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.4
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        77.6
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        2.2mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.4
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">8.6</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.7
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        78.5
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        3mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.2
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">8.3</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        83.1
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        73.8
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        3.2mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.5
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.2
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.5
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">8.4</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        83.8
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        74.1
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        4mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.1
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.2
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        85.2
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">8.1</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        81.8
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        71.4
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        5mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        89.4
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        82.7
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">7.2</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        77.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        68.6
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        6mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        81.0
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">7.3</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        74.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        66.5
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        7.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        78.8
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">6.9</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        1.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        72.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        62.0
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        10mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        87.6
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        7.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        76.4
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">6.9</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        0.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        68.5
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        58.7
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        12mm
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.7
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        7.8
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        73.4
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">6.7</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        0.9
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        64.0
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        55.3
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table366: `
        <table className="MsoTableGrid" border="1" cellSpacing="0" style="border:none;">
            <tbody>
            <tr>
                <td width="118" valign="top" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;">产品名称</span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="237" valign="top" colSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;">可见光</span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="237" valign="top" colSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;">太阳光</span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;">遮蔽系数</span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span
                            style="font-family:宋体;font-size:10.5000pt;"><span>太阳能总透射比</span><span>%</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span
                            style="font-family:宋体;font-size:10.5000pt;"><span>透射比</span><span>%</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span
                            style="font-family:宋体;font-size:10.5000pt;"><span>反射比</span><span>%</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>直接透射比</span><span>%</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>直接反射比</span><span>%</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>5mm</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>12.3</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>4.6</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>11.6</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>4.49</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>28</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
                <td width="118" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:宋体;font-size:10.5000pt;"><span>24.4</span></span><span
                        style="font-family:Calibri;font-size:10.5000pt;"></span>
                    </p>
                </td>
            </tr>
            </tbody>
        </table>    
    `,
    table180: `
        <table className="MsoNormalTable ke-zeroborder" border="0" cellSpacing="0" style="width:337pt;border:none;">
            <tbody>
            <tr>
                <td width="96" valign="center" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">产品名称</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Name of glass</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="192" valign="center" colSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">可见光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Visible light</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="192" valign="center" colSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">太阳光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Solar energy</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">遮蔽系数</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Shading Coefficient</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="98" valign="center" rowSpan="2" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span
                            style="font-family:宋体;font-size:10pt;"><span>太阳能总透射比</span><span>%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Sum of transmittance &nbsp;of Solar energy</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>透射比</span><span>%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Transmittance</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>反射比</span><span>%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Reflectance</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>直接透射比</span><span>%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Direct &nbsp;transmittance</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>直接反射比</span><span>%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">Direct reflectance</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        4mm
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">52.9 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">6.0 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">53.4 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">6.0 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.7 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="98" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">63.9 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        5mm
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">45.9 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">5.7 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">46.8 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">5.7 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.7 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="98" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">59.0 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        6mm
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">41.3 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">5.4 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">43.0 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">5.4 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.6 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="98" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">56.3 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8mm
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">31.0 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">4.9 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">33.4 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">4.9 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="96" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.6 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="98" valign="center" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">49.3 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table71: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span style="line-height:2;">产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span style="line-height:2;">可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span style="line-height:2;">太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span style="line-height:2;">遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span style="line-height:2;">太阳能总透射比%</span>
                    </div>
                    <p style="text-align:center;">
                        <span style="line-height:2;">Sum of transmittance </span><br>
                        <span style="line-height:2;"> of Solar energy</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span style="line-height:2;">透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span style="line-height:2;">反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span style="line-height:2;">直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Direct&nbsp; transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span style="line-height:2;">直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span style="line-height:2;">4mm茶玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">4mm Bronze glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">58.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">8.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">58.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">8.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">0.75</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">67.00</span>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span style="line-height:2;">5mm茶玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">5mm Bronze glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">52.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">8.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">51.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">7.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">0.69</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">62.00</span>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span style="line-height:2;">6mm茶玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span style="line-height:2;">6mm Bronze glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">46.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">7.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">45.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">7.00</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">0.64</span>
                </td>
                <td style="text-align:center;">
                    <span style="line-height:2;">57.00</span>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table70: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span></span><span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span></span><span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span></span><span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span></span><span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span></span><span>太阳能总透射比%</span>
                    </div>
                    <p style="text-align:center;">
                        Sum of transmittance <br>
                        of Solar energy
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct&nbsp; transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>4mm深灰玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>4mm Dark grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    35.00<br>
                </td>
                <td style="text-align:center;">
                    7.00<br>
                </td>
                <td style="text-align:center;">
                    44.00
                </td>
                <td style="text-align:center;">
                    7.00
                </td>
                <td style="text-align:center;">
                    0.64<br>
                </td>
                <td style="text-align:center;">
                    56.00<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>5mm深灰玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>5mm Dark grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    26.00<br>
                </td>
                <td style="text-align:center;">
                    7.00<br>
                </td>
                <td style="text-align:center;">
                    35.00
                </td>
                <td style="text-align:center;">
                    7.00
                </td>
                <td style="text-align:center;">
                    0.56<br>
                </td>
                <td style="text-align:center;">
                    50.00<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm深灰玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Dark grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    19.00<br>
                </td>
                <td style="text-align:center;">
                    6.00
                </td>
                <td style="text-align:center;">
                    28.00
                </td>
                <td style="text-align:center;">
                    6.90
                </td>
                <td style="text-align:center;">
                    0.51<br>
                </td>
                <td style="text-align:center;">
                    45.00<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>8mm深灰玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>8mm Dark grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    14.00<br>
                </td>
                <td style="text-align:center;">
                    5.76<br>
                </td>
                <td style="text-align:center;">
                    20.22
                </td>
                <td style="text-align:center;">
                    6.84
                </td>
                <td style="text-align:center;">
                    0.44<br>
                </td>
                <td style="text-align:center;">
                    39.09<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>10mm深灰玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>10mm Dark grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    8.00<br>
                </td>
                <td style="text-align:center;">
                    5.65
                </td>
                <td style="text-align:center;">
                    14.27
                </td>
                <td style="text-align:center;">
                    6.64
                </td>
                <td style="text-align:center;">
                    0.39<br>
                </td>
                <td style="text-align:center;">
                    34.64<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>12mm深灰玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>12mm Dark grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    4.65<br>
                </td>
                <td style="text-align:center;">
                    5.62
                </td>
                <td style="text-align:center;">
                    11.06
                </td>
                <td style="text-align:center;">
                    6.55
                </td>
                <td style="text-align:center;">
                    0.37<br>
                </td>
                <td style="text-align:center;">
                    33.30<br>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table69: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span></span><span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <div style="text-align:center;">
						<span>Sum of transmittance<br>of Solar energy</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct&nbsp; transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span><span></span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>4mm浅灰玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>4mm Euro grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    54.00
                </td>
                <td style="text-align:center;">
                    8.00
                </td>
                <td style="text-align:center;">
                    53.00
                </td>
                <td style="text-align:center;">
                    7.00
                </td>
                <td style="text-align:center;">
                    0.71<br>
                </td>
                <td style="text-align:center;">
                    63.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>5mm浅灰玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>5mm Euro grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    48.00
                </td>
                <td style="text-align:center;">
                    7.00
                </td>
                <td style="text-align:center;">
                    48.00
                </td>
                <td style="text-align:center;">
                    7.00
                </td>
                <td style="text-align:center;">
                    0.67<br>
                </td>
                <td style="text-align:center;">
                    60.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm浅灰玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Euro grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    41.00
                </td>
                <td style="text-align:center;">
                    6.88
                </td>
                <td style="text-align:center;">
                    43.00
                </td>
                <td style="text-align:center;">
                    7.00
                </td>
                <td style="text-align:center;">
                    0.63<br>
                </td>
                <td style="text-align:center;">
                    56.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>8mm浅灰玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>8mm Euro grey glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    35.00
                </td>
                <td style="text-align:center;">
                    6.60
                </td>
                <td style="text-align:center;">
                    31.00
                </td>
                <td style="text-align:center;">
                    6.10
                </td>
                <td style="text-align:center;">
                    0.57<br>
                </td>
                <td style="text-align:center;">
                    49.20
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>10mm浅灰玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>10mm Euro grey glass</span><span></span>
                    </div>
                </td>
                <td style="text-align:center;">
                    27.00
                </td>
                <td style="text-align:center;">
                    5.90
                </td>
                <td style="text-align:center;">
                    24.40
                </td>
                <td style="text-align:center;">
                    5.50
                </td>
                <td style="text-align:center;">
                    0.50<br>
                </td>
                <td style="text-align:center;">
                    40.80
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table68: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sha</span><span>d</span><span>ing Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <p style="text-align:center;">
                        Sum of transmittance <br>
                        of Solar energy
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct&nbsp; transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>4mm粉玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>4mm Pink glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    77.67<br>
                </td>
                <td style="text-align:center;">
                    9.00<br>
                </td>
                <td style="text-align:center;">
                    77.63
                </td>
                <td style="text-align:center;">
                    11.65
                </td>
                <td style="text-align:center;">
                    0.90<br>
                </td>
                <td style="text-align:center;">
                    80.40<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm粉玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Pink glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    69.88<br>
                </td>
                <td style="text-align:center;">
                    8.35
                </td>
                <td style="text-align:center;">
                    70.62
                </td>
                <td style="text-align:center;">
                    10.40
                </td>
                <td style="text-align:center;">
                    0.85<br>
                </td>
                <td style="text-align:center;">
                    75.52<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>8mm粉玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>8mm Pink glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    63.93<br>
                </td>
                <td style="text-align:center;">
                    7.87
                </td>
                <td style="text-align:center;">
                    65.20
                </td>
                <td style="text-align:center;">
                    9.81
                </td>
                <td style="text-align:center;">
                    0.81<br>
                </td>
                <td style="text-align:center;">
                    71.65<br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>10mm粉玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>10mm Pink glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    59.14<br>
                </td>
                <td style="text-align:center;">
                    7.59
                </td>
                <td style="text-align:center;">
                    60.82
                </td>
                <td style="text-align:center;">
                    9.36
                </td>
                <td style="text-align:center;">
                    0.77<br>
                </td>
                <td style="text-align:center;">
                    68.52 <br>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table67: `
        <table style="width:100%;" cellPadding="0" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span></span><span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span><span></span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <p style="text-align:center;">
                        Sum of transmittance<br>
                        of Solar energy
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <span> 
                        <div style="text-align:center;">
                            <span>Transmittance</span> 
                        </div>
                    </span>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <span> 
                        <div style="text-align:center;">
                            <span>Reflectance</span> 
                        </div>
                    </span>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct&nbsp; transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                    <div style="text-align:center;">
                        <span></span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>4mm福特蓝玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>4mm Ford blue glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    <span>67.00</span><span></span><br>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>9.00</span>
                    </div>
                    <div style="text-align:center;">
                        <br>
                    </div>
                </td>
                <td style="text-align:center;">
                    68.00<br>
                </td>
                <td style="text-align:center;">
                    8.00
                </td>
                <td style="text-align:center;">
                    0.83
                </td>
                <td style="text-align:center;">
                    74.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>5mm福特蓝玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>5mm Ford blue glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    <span>62.00</span><span></span><br>
                </td>
                <td style="text-align:center;">
                    9.00<br>
                </td>
                <td style="text-align:center;">
                    63.00
                </td>
                <td style="text-align:center;">
                    8.00
                </td>
                <td style="text-align:center;">
                    0.80
                </td>
                <td>
                    <p style="text-align:center;">
                        7<span>0.00</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm福特蓝玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Ford blue glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    <span>57.00</span><span></span><br>
                </td>
                <td style="text-align:center;">
                    8.00
                </td>
                <td style="text-align:center;">
                    59.00
                </td>
                <td style="text-align:center;">
                    8.00
                </td>
                <td style="text-align:center;">
                    0.76
                </td>
                <td style="text-align:center;">
                    67.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>8mm福特蓝玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>8mm Ford blue glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    <span>48.00</span><br>
                </td>
                <td style="text-align:center;">
                    6.74
                </td>
                <td style="text-align:center;">
                    45.47
                </td>
                <td style="text-align:center;">
                    7.66
                </td>
                <td style="text-align:center;">
                    0.65
                </td>
                <td style="text-align:center;">
                    57.57
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>10mm福特蓝玻璃 </span>
                    </div>
                    <div style="text-align:center;">
                        <span>10mm Ford blue glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    41.00<br>
                </td>
                <td style="text-align:center;">
                    6.97
                </td>
                <td style="text-align:center;">
                    35.46
                </td>
                <td style="text-align:center;">
                    7.82
                </td>
                <td style="text-align:center;">
                    0.56
                </td>
                <td style="text-align:center;">
                    50.10
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table83: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:left;">
                        <span>厚度</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>可见光透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance of Visible light</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>太阳光直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct transmittance Sunlight</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient (SC)</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sum of transmittance </span>
                    </div>
                    <div style="text-align:center;">
                        <span>of Solar energy</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span><span>水晶蓝膜</span></span>
                    </div>
                    <div style="text-align:center;">
                        <span>Crystal blue reflective glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    6mm
                </td>
                <td style="text-align:center;">
                    76.00
                </td>
                <td style="text-align:center;">
                    69.00
                </td>
                <td style="text-align:center;">
                    0.84
                </td>
                <td style="text-align:center;">
                    75.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        珍珠蓝
                    </div>
                    <div style="text-align:center;">
                        Pearl blue glass
                    </div>
                </td>
                <td style="text-align:center;">
                    6mm
                </td>
                <td style="text-align:center;">
                    67.80
                </td>
                <td style="text-align:center;">
                    61.15
                </td>
                <td style="text-align:center;">
                    0.75
                </td>
                <td style="text-align:center;">
                    67.98
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table81: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sum of transmittance </span>
                    </div>
                    <div style="text-align:center;">
                        <span>of Solar energy</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>5mm 茶色镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>5mm Bronze reflective glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    27.00<br>
                </td>
                <td style="text-align:center;">
                    50.00
                </td>
                <td style="text-align:center;">
                    33.00
                </td>
                <td style="text-align:center;">
                    35.00
                </td>
                <td style="text-align:center;">
                    0.47
                </td>
                <td style="text-align:center;">
                    41.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm 茶色镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Bronze reflective glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    25.00
                </td>
                <td style="text-align:center;">
                    52.00
                </td>
                <td style="text-align:center;">
                    31.00
                </td>
                <td style="text-align:center;">
                    36.00
                </td>
                <td style="text-align:center;">
                    0.44
                </td>
                <td style="text-align:center;">
                    39.00
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table79: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sum of transmittance </span>
                    </div>
                    <div style="text-align:center;">
                        <span>of Solar energy</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>5mm福特蓝镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>5mm Dark blue reflective glass</span><span></span>
                    </div>
                </td>
                <td style="text-align:center;">
                    28.00
                </td>
                <td style="text-align:center;">
                    55.00<br>
                </td>
                <td style="text-align:center;">
                    <span>40.00</span>
                </td>
                <td style="text-align:center;">
                    <span>39.00</span>
                </td>
                <td style="text-align:center;">
                    <span>0.51</span>
                </td>
                <td style="text-align:center;">
                    <span>45.00</span><br>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm福特蓝镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Dark blue reflective glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    25.00
                </td>
                <td style="text-align:center;">
                    57.00
                </td>
                <td style="text-align:center;">
                    36.00
                </td>
                <td style="text-align:center;">
                    40.00
                </td>
                <td style="text-align:center;">
                    0.47
                </td>
                <td style="text-align:center;">
                    42.00
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table77: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sum of transmittance </span>
                    </div>
                    <div style="text-align:center;">
                        <span>of Solar energy</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>4</span><span>mm粉红镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>4mm Pink grey reflective glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    30.66
                </td>
                <td style="text-align:center;">
                    36.06
                </td>
                <td style="text-align:center;">
                    47.82
                </td>
                <td style="text-align:center;">
                    29.17
                </td>
                <td style="text-align:center;">
                    0.60
                </td>
                <td style="text-align:center;">
                    53.76
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm粉红镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Pink grey reflective glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    29.92
                </td>
                <td style="text-align:center;">
                    28.64
                </td>
                <td style="text-align:center;">
                    46.40
                </td>
                <td style="text-align:center;">
                    22.14
                </td>
                <td style="text-align:center;">
                    0.61
                </td>
                <td style="text-align:center;">
                    54.52
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table76: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sum of transmittance </span>
                    </div>
                    <div style="text-align:center;">
                        <span>of Solar energy</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>5mm深灰镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>5mm Dark grey reflective glass</span>
                    </div>
                </td>
                <td style="text-align:center;">
                    15.00<br>
                </td>
                <td style="text-align:center;">
                    43.00
                </td>
                <td style="text-align:center;">
                    27.00
                </td>
                <td style="text-align:center;">
                    31.00
                </td>
                <td style="text-align:center;">
                    0.43
                </td>
                <td style="text-align:center;">
                    38.00
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm深灰镀膜玻璃</span>
                    </div>
                    <span> 
					<div style="text-align:center;">
						<span>6mm Dark grey reflective glass</span><span></span> 
					</div>
					</span>
                </td>
                <td style="text-align:center;">
                    10.00
                </td>
                <td style="text-align:center;">
                    53.00
                </td>
                <td style="text-align:center;">
                    26.00
                </td>
                <td style="text-align:center;">
                    37.00
                </td>
                <td style="text-align:center;">
                    0.40
                </td>
                <td style="text-align:center;">
                    36.00
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table75: `
        <table style="width:100%;" cellPadding="2" cellSpacing="0" border="1" bordercolor="#333333">
            <tbody>
            <tr>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>产品名称</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Name of glass</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>可见光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Visible light</span>
                    </div>
                </td>
                <td colSpan="2">
                    <div style="text-align:center;">
                        <span>太阳光</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sunlight</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>遮蔽系数</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Shading Coefficient</span>
                    </div>
                </td>
                <td rowSpan="2">
                    <div style="text-align:center;">
                        <span>太阳能总透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Sum of transmittance </span>
                    </div>
                    <div style="text-align:center;">
                        <span>of Solar energy</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Reflectance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接透射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct transmittance</span>
                    </div>
                </td>
                <td>
                    <div style="text-align:center;">
                        <span>直接反射比%</span>
                    </div>
                    <div style="text-align:center;">
                        <span>Direct reflectance</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align:center;">
                        <span>6mm浅灰镀膜玻璃</span>
                    </div>
                    <div style="text-align:center;">
                        <span>6mm Euro grey reflective glass</span><span></span>
                    </div>
                </td>
                <td style="text-align:center;">
                    26.00
                </td>
                <td style="text-align:center;">
                    41.70
                </td>
                <td style="text-align:center;">
                    31.60
                </td>
                <td style="text-align:center;">
                    29.90
                </td>
                <td style="text-align:center;">
                    0.47
                </td>
                <td style="text-align:center;">
                    41.50
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table383: `
        <table className="MsoTableGrid" border="1" cellSpacing="0" style="border:none;">
            <tbody>
            <tr>
                <td width="360" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <b><span
                            style="font-family:方正细等线简体;font-size:14pt;"><span>项目</span><span>Item</span></span></b><b><span
                        style="font-family:&quot;font-size:14pt;"></span></b>
                    </p>
                </td>
                <td width="207" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <b><span
                            style="font-family:方正细等线简体;font-size:14pt;"><span>单位</span><span>Unit</span></span></b><b><span
                        style="font-family:&quot;font-size:14pt;"></span></b>
                    </p>
                </td>
                <td width="284" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <b><span style="font-family:方正细等线简体;font-size:14pt;"><span>指标</span><span>Index</span></span></b><b><span
                        style="font-family:&quot;font-size:14pt;"></span></b>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="360" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span
                            style="font-family:方正细等线简体;font-size:14pt;"><span>应变点</span> <span>Strain point</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="207" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:Arial;color:#333333;font-size:14pt;background:#FFFFFF;"><span>°C</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="284" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>535-550</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="360" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>退火点</span> <span>Annealing point</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="207" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:Arial;color:#333333;font-size:14pt;background:#FFFFFF;"><span>°C</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="284" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>580-590</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="360" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>密度</span> <span>Density</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="207" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>g/cm</span></span><sup><span
                        style="font-family:方正细等线简体;font-size:22pt;vertical-align:super;"><span>2</span></span></sup><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="284" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>2.5</span></span><span
                        style="font-family:Arial;color:#333333;font-size:14pt;background:#FFFFFF;"><span>±</span></span><span
                        style="font-family:方正细等线简体;font-size:14pt;"><span>0.005</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="360" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>厚度</span><span>Thickness</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="207" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>mm</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
                <td width="284" valign="top" style="border:1.0000pt solid windowtext;">
                    <p className="MsoNormal" align="center" style="margin-left:0.0000pt;text-align:center;">
                        <span style="font-family:方正细等线简体;font-size:14pt;"><span>1.1-3.2</span></span><span
                        style="font-family:&quot;font-size:14pt;"></span>
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table382: `
        <table className="MsoNormalTable" border="1" cellSpacing="0" style="width:364pt;border:none;">
            <tbody>
            <tr>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">厚度</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:Calibri;font-size:10pt;">L*</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:Calibri;font-size:10pt;">a*</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:Calibri;font-size:10pt;">b*</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">可见光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">太阳光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">紫外光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>透光率</span><span>%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span className="15" style="font-family:宋体;font-size:10.0000pt;">透光率</span><span
                        style="font-family:Calibri;font-size:10pt;">%</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span className="15" style="font-family:宋体;font-size:10.0000pt;">透光率</span><span
                        style="font-family:Calibri;font-size:10pt;">%</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        2mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">96.62</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">-0.02 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">0.18</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">91.70</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">91.40</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.60
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        3mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">96.63</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">-0.06</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">0.19 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">91.61</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">91.02</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        87.70
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        4mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">96.64</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">-0.10</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.21</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">91.60</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">90.90</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.90
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table381: `
        <table className="MsoNormalTable ke-zeroborder" border="0" cellSpacing="0" style="width:364pt;border:none;">
            <tbody>
            <tr>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">厚度</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:Calibri;font-size:10pt;">L*</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:Calibri;font-size:10pt;">a*</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:Calibri;font-size:10pt;">b*</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">可见光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">太阳光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">紫外光</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;"><span>透光率</span><span>%</span></span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span className="15" style="font-family:宋体;font-size:10.0000pt;">透光率</span><span
                        style="font-family:Calibri;font-size:10pt;">%</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span className="15" style="font-family:宋体;font-size:10.0000pt;">透光率</span><span
                        style="font-family:Calibri;font-size:10pt;">%</span><span
                        style="font-family:Calibri;font-size:10pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        2mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">96.62</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">-0.07 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">0.20 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        91.5
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        91.01
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        86.7
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        3mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">96.58</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">-0.10 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">0.18 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        91.32
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.65
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        78.16
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        4mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">96.41</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">-0.26 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.11 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        91.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.3
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        76.61
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        5mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">96.5</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">-0.20 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">0.16 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        91.16
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.1
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        73.5
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        6mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">96.48</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">-0.30 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.26 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        91.04
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        89.4
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        71.42
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        8mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">96.45</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">-0.19 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.24 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.95
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.87
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        67.82
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        10mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">96.47</span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">-0.25 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10pt;">0.24 </span><span
                        style="font-family:宋体;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        90.68
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        88.19
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        65.03
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        12mm
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">96.41</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">-0.21 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">0.20 </span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td width="104" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:等线;font-size:10pt;">90.38</span><span
                        style="font-family:等线;font-size:10pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        87.33
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        60.56
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    table394: `
        <table className="MsoNormalTable ke-zeroborder" border="0" cellSpacing="0" style="width:368.5pt;border:none;">
            <tbody>
            <tr>
                <td width="368" valign="center" colSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span
                            style="font-family:宋体;font-size:10.5pt;"><span>项目</span> <span>Items</span></span><span
                        style="font-family:宋体;font-size:10.5pt;"></span>
                    </p>
                </td>
                <td width="184" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span
                            style="font-family:宋体;font-size:10.5pt;"><span>模式</span><span>Models</span></span><span
                        style="font-family:宋体;font-size:10.5pt;"></span>
                    </p>
                </td>
                <td width="184" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10.5pt;"><span>参数</span><span>Parameters</span></span><span
                        style="font-family:宋体;font-size:10.5pt;"></span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" rowSpan="5" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        光学参数
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        Optical parameters
                    </p>
                </td>
                <td valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        可见光透过率
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        Visible transmittance
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>通电</span> <span>Power on</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＞</span><span>65%</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>断电</span> <span>Power off</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＞</span><span>42%</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>雾度</span> <span>Fog degree</span>
                    </p>
                </td>
                <td width="184" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span
                            style="font-family:宋体;font-size:10.5pt;"><span>通电</span> <span>Power on</span></span><span
                        style="font-family:宋体;font-size:10.5pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＜</span><span>4%</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td width="184" valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span style="font-family:宋体;font-size:10.5pt;"><span>断电</span> <span>Power off</span></span><span
                        style="font-family:宋体;font-size:10.5pt;"></span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＞</span><span>90%</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>可视角度</span> <span>Viewing angle</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>通电</span> <span>Power on</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＞</span><span>160°</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" rowSpan="4" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        电学参数
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        Optical parameters
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        &nbsp;
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>工作电压</span> <span>Load voltage</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>通电</span> <span>Power on</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        48V AC
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" rowSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        响应时间
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        Response time
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>断电</span><span>-通电</span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        Power off-Power on
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＜</span><span>200ms</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>通电</span><span>-断电</span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        Power on-Power off
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＜</span><span>10ms</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>功耗</span> <span>Power consumption</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>通电</span> <span>Power on</span>
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        ＜<span>5W/M</span>²
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" rowSpan="3" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        环境参数
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        Environment parameters
                    </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>工作温度</span> <span>Working temperature</span>
                    </p>
                </td>
                <td valign="center" colSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" style="vertical-align:middle;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>-10℃ ~ 65℃</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>使用寿命</span> <span>Service life</span>
                    </p>
                </td>
                <td valign="center" colSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>通电</span> <span>Power on ＞10年 (years)</span>
                    </p>
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        &nbsp;
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="center" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>开关次数</span> <span>Switching frequency</span>
                    </p>
                </td>
                <td valign="center" colSpan="2" style="border:1.0000pt solid #000000;">
                    <p className="MsoNormal" align="center"
                       style="margin-left:0.0000pt;text-align:center;vertical-align:middle;">
                        <span>＞</span><span>8000万次 (10 million times)</span>
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    `,

};

export default tables;
