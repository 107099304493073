<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/party/learn20">学习二十大</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/mentor">导师带徒</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/special">专题专栏</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/youthWork">青年工作</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/communication">耀华通讯</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/party/learn20">党建工作</a></li> &gt;
                    <li><a href="/#/party/mentor">导师带徒</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "partyMentor",
        data(){
            return{
                activeNames: [],
                items: [
                    {image: '5e0d90764dd98.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '关于转发河北省人力资源和社会保障厅、河北省财政厅《关于全面推进企业新型学徒制的实施意见》',
                        publishDate: '2020-01-19',
                        content: '',
                        moreLink: '/#/party/mentor'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '师带徒情况汇报',
                        publishDate: '2020-01-19',
                        content: '今年4月16日，集团召开党委会，将职工培训只能划分到党群工作部。为了开展好这项工作，党群部于4月20日召开职工培训会议，各子公司...',
                        moreLink: '/#/party/mentor'
                    },
                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style scoped>

</style>
