<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div class="top">
                <div class="mbxdh">
                    <ul>
                        <li><a href="">首页</a></li> &gt;
                        <li><a href="/#/about">关于耀华</a></li> &gt;
                        <li><a href="/#/about/honor">耀华历史</a></li>
                    </ul>
                </div>
            </div>
            <div class="content">
                <div id="imgnews">
                    <div class="box" v-for="(item, index) in imgNewsItems" :key="index">
                        <a :href="'/#/about/history/' + (index + 1)">
                            <div class="left l">
                                <div class="jqthumb" style="width: 182px; height: 120px; opacity: 1;">
                                    <img :src="item.imgSrc" style="width: 100%; height: 100%; object-fit: cover;">
                                </div>
                                <img :src="item.imgSrc" style="display: none;">
                            </div>
                            <div class="right r">
                                <div class="title">
                                    <h5>{{ item.title }}</h5>
                                    <span>来源：</span><span class="date">{{ item.publishDate }}</span>
                                </div>
                                <p>{{ item.content }}</p>
                                <span class="more"></span>
                            </div>
                            <div class="clear"></div>
                        </a>
                    </div>
                </div>
                <div id="page">
                    <ul>
                        <li><a href="javascript:void(0);">上一页</a></li>
                        <li class="on"><a>1</a></li>
                        <li><a href="javascript:void(0);">下一页</a></li>
                    </ul>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "aboutHistory",
        data() {
            return {
                interval: 4000,
                items: [
                    {image: '5e156e89e6e27.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true, // 控制是否显示关注部分，默认为显示
                imgNewsItems: [
                    {
                        title: '我国平板玻璃工业发展历程',
                        publishDate: '2020-01-13',
                        content: '图为作者上世纪末出国考察照片 图为1933年耀华玻璃厂全景 ...',
                        imgSrc: require('@/../uploads/5e1bbea75ad2e.jpg')
                    },
                    {
                        title: '我国“浮法工艺技术”发展阶段',
                        publishDate: '2020-01-13',
                        content: '我国“浮法工艺技术”的发展经历了以下五个阶段：一是自主研发...',
                        imgSrc: require('@/../uploads/5e1bbf028bf7a.jpg')
                    },
                    {
                        title: '耀华玻璃厂历史沿革',
                        publishDate: '2020-01-13',
                        content: '耀华是我国和亚洲第一家采用“机制平板玻璃”的工厂，已有近百年历史，是我国老的玻璃工业基地。自1903年张骞等开始筹...',
                        imgSrc: require('@/../uploads/5e1bbf2feb09d.jpg')
                    },
                    {
                        title: '对行业（企业）发展的几点感悟',
                        publishDate: '2019-11-12',
                        content: '1、从高速增长阶段转为高质量发展阶段改革开放40年国民经济快速发展，史无前例的大规模经济建设为我国玻璃工业创造了...',
                        imgSrc: require('@/../uploads/5e1bbf4f0bdb9.jpg')
                    },
                ]
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style>
    html, body, address, blockquote, div, dl, form, h1, h2, h3, h4, h5, h6, ol, p, pre, table, ul, dd, dt, li, tbody, td, tfoot, th, thead, tr, button, del, ins, map, object, a, abbr, acronym, b, bdo, big, br, cite, code, dfn, em, i, img, kbd, q, samp, small, span, strong, sub, sup, tt, var, legend, fieldset {
        margin: 0;
        padding: 0;
    }
    img, fieldset {
        border: 0;
    }
    /* set image max width to 100% */
    img {
        border: 0;
    }
    /* set html5 elements to block */
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
    }
    #page {
        text-align:center;
        margin:20px 0 10px;
    }
    #page li {
        display:inline-block;
        margin:0 2px;
        background:#eaeaea;
        line-height:30px;
    }
    #page li {
        *display:inline;
    }
    #page li a {
        display:block;
        padding:0 10px;
        color:#666;
    }
    #page li a:hover,#page li.on a  {
        background-color:black;
        text-decoration:none;
        color:white;
    }

    body {
        font-size: 14px;
        color: #333;
        background: white;
        line-height: 1.5em;
        word-wrap:break-word;
        break-word:break-all;
    }
    a {
        color: #333;
        text-decoration: none;
        outline: none;
    }
    a:hover {
        text-decoration: underline;
    }
    .clear {
        clear: both;
    }
    /* list */
    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    /* headings */
    h1, h2, h3, h4, h5, h6 {
        line-height: 1.4em;
        color: #000;
    }
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.6em;
    }
    h3 {
        font-size: 1.4em;
    }
    h4 {
        font-size: 1.2em;
    }
    h5 {
        font-size: 1.1em;
    }
    h6 {
        font-size: 1em;
    }
    .l {
        float: left;
    }
    .r {
        float: right;
    }
    .inner {
        width: 1200px;
        margin: auto;
    }
    #main .content table{
        border-collapse:collapse;
    }
    #main .content p {
        padding:0;
    }
    #text {
        padding:0 40px;
        min-height:300px;
        line-height: 2em;
    }
    #title {
        padding:10px 20px ;
        text-align:center;
        /*	border-bottom:1px #ccc solid;
        */	margin-bottom:20px;
        background:#ebebeb;
    }
    #title h5 {
        font-size:16px;
    }
    #title span {
        color:#999;
        margin:0 5px;
    }
    #main #subnav {
        width:100%;
        height:60px;
        /*	float:left;
        */	background:#fff;
        /*	border-bottom:1px #ccc solid;
        */}
    #main #subnav .nli {
        position:relative;
        height:60px;
        float:left;
    }
    #main #subnav h4 {
        height:60px;
        line-height:60px;
        font-weight:normal;
        padding:0 35px 0 0;
        font-size:16px;
        float:left;
    }
    #main #subnav .on h4 a,#main #subnav h4 a:hover{
        color:#ff3234;
    }
    #main #subnav h4 a {
        text-decoration:none;
    }
    #main #subnav .sub {
        display:none;
        position:absolute;
        background:white;
        border:1px #ccc solid;
        width:100%;
        padding:5px 10px 10px;
        text-align:center;
        top:58px;
        left:0;
        margin-left:-30px;
        z-index:8;
    }
    #main #subnav .sub li {
        width:100%;
        padding:5px 0;
    }
    #main #right {
        width:570px;
        float:right;
        background:white;
        padding:0px 15px 10px;
    }
    #main #left {
        width: 570px;
        float: left;
        padding: 20px 15px 0;
    }
    #main #right img {
        max-width:100%;
    }
    #main .top {
        height:40px;
        line-height:40px;
        padding-bottom:2px;
        /*	border-bottom:1px #cscc solid;
        */	width:1200px;
        margin:0 auto;
    }
    #subnav .sideMenu h4 {
        line-height:24px;
        padding:12px 10px;
        border-top: 1px solid #ccc;
        background: white;
        cursor: pointer;
        font-size:16px;
        font-weight:normal;
    }

    #subnav .sideMenu a:hover {
        text-decoration:none;
        color:#d63629;
    }

    #subnav .sideMenu h4.on a {
        color:#d63629;
    }
    #subnav .sideMenu ul {
        color: #999;
        border-top:1px #ccc solid;
        padding:6px 0 10px;
        display: none; /* 默认都隐藏 */
    }


    #main .h p {
        color: #999;
    }
    #banner {
        overflow:hidden;
    }
    /*面包屑导航*/
    #main .top {
        position:relative;
    }
    #main .mbxdh {
        position:absolute;
        top:2px;
        left:0;
        color:#999;
        padding-left:25px;
        background:url(../../components/images/homeicon.png) no-repeat left center;
    }
    #main .mbxdh li {
        display:inline-block;
    }
    #main .mbxdh a {
        display:inline-block;
        padding:0 5px 0 2px;
        color:#666;
        text-decoration:none;
    }
    #main .top .mbxdh li {
        *display:inline;
    }
    #main .top .mbxdh a {
        *display:inline;
    }
    /* CSS Document */
    #banner {
        height: 320px;
        width: 100%;
        position: relative;
    }
    #banner img {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -960px;
    }
    #main {
        padding: 0 0 35px;
        background:#f5f5f5;
    }
    #imgnews .box {
        border-bottom:1px #ccc solid;
        /*	margin-bottom:25px;
        */	*padding-bottom:0;
        cursor:pointer;
    }
    #imgnews .box a {
        display:block;
        padding:10px 15px;
        cursor:pointer;
    }
    #imgnews .box a:hover {
        text-decoration:none;
        background:white;
    }
    #imgnews .box .left {
        width:182px;
        height:120px;
    }
    #imgnews .box .right {
        width:970px;
        height:120px;
        color:#999;
        position:relative;
    }
    #news .box {
        color:#999;
        position:relative;
        padding:10px 20px 10px 45px;
        border-bottom:1px #ccc solid;
        cursor:pointer;
        background:url(../../components/images/qicon2.jpg) no-repeat 10px 15px;
    }
    #imgnews .box .right h5,#news .box h5 {
        font-size:16px;
        font-weight:normal;
        color:#333;
        padding:5px 0 5px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    #news .show {
        position:relative;
        background:white url(../../components/images/qicon.jpg) no-repeat 10px 15px;
    }
    #news .box .date {
        position:absolute;
        top:15px;
        right:0;
    }
    #news .show .date {
        position:inherit;
    }
    #news .box .ly,#news .box p,#news .box .more {
        display:none;
    }
    #news .show .more{
        color:#ff8601;
    }
    #news .show .more:hover{
        transition: 0.3s ease;
        -o-transition: 0.3s ease;
        -webkit-transition: 0.3s ease;
        margin-left:5px;
    }
    #imgnews .box .right .title{
        padding-bottom:10px;
    }
    /*#news .box .title {
        padding-bottom:10px;
    }*/
    #imgnews .box .right .title span,#news .box .title span {
        margin-right:10px;
    }
    #imgnews .box .right .more/*,#news .box .more*/ {
        display:block;
        width:30px;
        height:14px;
        background:url(../../components/images/more.png) no-repeat center center;
        position:absolute;
        /*	bottom:10px;
        */	left:0;
        transition: 0.3s ease;
        -o-transition: 0.3s ease;
        -webkit-transition: 0.3s ease;
    }
    #news .box a {
        color:#999;
    }
    /*#news .box a:hover {
        color:#333;
    }
    #news .box .more {
        bottom:12px;
        left:20px;
    }*/
    #imgnews .box a:hover .right .more{
        left:20px;
    }
    #news .box a:hover .more {
        left:40px;
    }
    #news a {
        text-decoration:none;
    }

</style>
