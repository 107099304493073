export const imgNewsItems = [
    {
        title: '冲刺年终 ㉘ | 优质浮法玻璃成员企业节能降耗初见成效',
        publishDate: '2022-12-27',
        content: '冲刺年终 ㉘ | 优质浮法玻璃成员企业节能降耗初见成效 节能降耗是企业可持续发展的生命线。耀华集团浮法...',
        imgSrc:  require('@/../uploads/646c1c819a9cd.png')
    },
    {
        title: '冲刺年终㉗│龙昊公司熔浮甲班 用实干铸就“工匠精神”',
        publishDate: '2022-12-22',
        content: '冲刺年终㉗│龙昊公司熔浮甲班 用实干铸就“工匠精神” 在龙昊公司，有这样一群人，他们秉持对工作的忠诚和责任，锐意进取...',
        imgSrc: require('@/../uploads/646c1bfbc85b1.png')
    },
    {
        title: '冲刺年终 ㉖ | 节能公司巾帼女将绽芳华',
        publishDate: '2022-12-21',
        content: '冲刺年终 ㉖ | 节能公司巾帼女将绽芳华 变电所是耀华节能公司供电系统的心脏，配电工的工作职责就是要确保安全送电，巡...',
        imgSrc: require('@/../uploads/646c1b91d534d.png')
    },
    {
        title: '冲刺年终㉕ | 中联玻璃 青春向前冲',
        publishDate: '2022-12-20',
        content: '冲刺年终㉕ | 中联玻璃 青春向前冲 白日莫闲过，青春不再来。当前正处于冬季叠加疫情影响，又是冲刺全年目标任务的特殊...',
        imgSrc: require('@/../uploads/646c1aeeb3f94.png')
    },
    {
        title: '冲刺年终㉔ | 凤阳凯盛聚团队之势，满弓劲发！',
        publishDate: '2022-12-19',
        content: '冲刺年终㉔ | 凤阳凯盛聚团队之势，满弓劲发！ 企业生产并不是一个人的努力就可以完成的，它需要团队的智慧与拼搏。在凤...',
        imgSrc: require('@/../uploads/646c1a27d57c0.png')
    },
    {
        title: '冲刺年终 ㉓ | 记录那些闪烁在生产一线的“微光”',
        publishDate: '2022-12-15',
        content: '冲刺年终 ㉓ | 记录那些闪烁在生产一线的“微光” 弘华公司坚持以党的二十大精神为引领，在抓好疫情防控的同时，聚焦聚...',
        imgSrc: require('@/../uploads/646c197d8071d.png')
    },
    {
        title: '冲刺年终 ㉒ | 内江玻璃引进新设备，增添新动能',
        publishDate: '2022-12-14',
        content: '冲刺年终 ㉒ | 内江玻璃引进新设备，增添新动能  工欲善其事，必先利其器。为提高生产效率，满足市场多元化需求，助力...',
        imgSrc: require('@/../uploads/646c18d796305.png')
    },
    {
        title: '冲刺年终 ㉑ | 蚌埠凯盛玻璃“金点子”激发创新活力',
        publishDate: '2022-12-13',
        content: '冲刺年终 ㉑ | 蚌埠凯盛玻璃“金点子”激发创新活力 蚌埠凯盛玻璃有限公司在积极求新求变，产出新产品、开拓新市场的同...',
        imgSrc: require('@/../uploads/646c1895eb3bf.png')
    },
    {
        title: '冲刺年终 ⑳ | 兴科公司人人争做“小诸葛”',
        publishDate: '2022-12-12',
        content: '冲刺年终 ⑳ | 兴科公司人人争做“小诸葛” 进入四季度，蚌埠兴科玻璃有限公司将技术改造作为提质增效的突破口，紧紧围...',
        imgSrc: require('@/../uploads/646c1724bc58e.png')
    },
    {
        title: '冲刺年终 ⑲ | 晶华玻璃用创新为增效“添翼加速”',
        publishDate: '2022-12-09',
        content: '冲刺年终 ⑲ | 晶华玻璃用创新为增效“添翼加速”  凯盛晶华玻璃有限公司坚持全员创新创效，把创新活动贯穿于企业生产...',
        imgSrc: require('@/../uploads/646c168f5c027.png')
    }
]
