<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/party/learn20">学习二十大</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/mentor">导师带徒</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/special">专题专栏</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/youthWork">青年工作</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/communication">耀华通讯</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/party/learn20">党建工作</a></li> &gt;
                    <li><a href="/#/party/learn20">学习二十大</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "partyLearn20",
        data(){
            return{
                activeNames: [],
                items: [
                    {image: '5e0d90764dd98.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '耀华集团特种玻璃运营部成员企业学习党的二十大精神到班组、进车间',
                        publishDate: '2022-11-07',
                        content: '耀华集团特种玻璃运营部成员企业学习党的二十大精神到班组、进车间 近日，耀华集团特种玻璃运营部成员企业通过班前领读、事...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '蚌埠兴科学习宣传贯彻党的二十大精神系列活动精彩纷呈',
                        publishDate: '2022-11-04',
                        content: '蚌埠兴科学习宣传贯彻党的二十大精神系列活动精彩纷呈 为深入学习宣传贯彻党的二十大精神，进一步凝聚新时代文明实践力量传...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '内江公司组织开展党的二十大精神宣讲',
                        publishDate: '2022-11-03',
                        content: '内江公司组织开展党的二十大精神宣讲 内江公司高度重视党的二十大精神的学习贯彻落实，党的二十大召开以来，抓实抓好氛围营...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '佳星玻璃学习宣传贯彻二十大精神奋力开创高质量发展新局面',
                        publishDate: '2022-11-01',
                        content: '佳星玻璃学习宣传贯彻二十大精神奋力开创高质量发展新局面 中国共产党第二十次全国代表大会，开启了全面建设社会主义现代化...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '晶华公司迅速掀起学习贯彻党的二十大精神热潮',
                        publishDate: '2022-10-28',
                        content: '晶华公司迅速掀起学习贯彻党的二十大精神热潮 连日来，凯盛晶华玻璃有限公司掀起学习贯彻党的二十大报告的热潮。公司上下一...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '龙昊玻璃迅速掀起学习贯彻党的二十大精神热潮',
                        publishDate: '2022-10-27',
                        content: '龙昊玻璃迅速掀起学习贯彻党的二十大精神热潮 10月16日，中国共产党第二十次全国代表大会在北京人民大会堂隆重开幕，龙...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '7',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中联玻璃形式多样认真学习宣传党的二十大精神',
                        publishDate: '2022-10-26',
                        content: '中联玻璃形式多样认真学习宣传党的二十大精神 连日来，中联玻璃以学习党的二十大精神为首要任务，采取学习会、研讨会等多种...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '8',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '耀华秦皇岛公司认真学习宣传党的二十大精神',
                        publishDate: '2022-10-25',
                        content: '耀华秦皇岛公司认真学习宣传党的二十大精神 10月16日，中国共产党第二十次全国代表大会在北京人...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '9',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '耀华集团干部职工认真学习党的二十大精神，谈成就、说发展、话未来！',
                        publishDate: '2022-10-24',
                        content: '耀华集团干部职工认真学习党的二十大精神，谈成就、说发展、话未来！ 2022年10月16日，中国共产党第二十次全国代表...',
                        moreLink: '/#/party/learn20'
                    },
                    {
                        name: '10',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '党的二十大报告在耀华集团干部职工中引发热烈反响',
                        publishDate: '2022-10-23',
                        content: '党的二十大报告在耀华集团干部职工中引发热烈反响 10月16日，中国共产党第二十次全国代表大会在北京人民大会堂隆重开幕...',
                        moreLink: '/#/party/learn20'
                    },

                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },

    }
</script>

<style scoped>

</style>
