<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/party/learn20">学习二十大</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/mentor">导师带徒</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/special">专题专栏</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/youthWork">青年工作</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/party/communication">耀华通讯</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/party/learn20">党建工作</a></li> &gt;
                    <li><a href="/#/party/youthWork">青年工作</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "YouthWork",
        data(){
            return{
                activeNames: [],
                items: [
                    {image: '5e0d90764dd98.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '青春蝶变—耀华成员企业的青年成长记',
                        publishDate: '2022-08-31',
                        content: '青春蝶变—耀华成员企业的青年成长记 入职壹周年 青春正当时 盛夏已过，秋意渐浓。转眼间，又迎来新员工入职季，去年...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '我们俩与千里之外孩子们五天难忘的故事',
                        publishDate: '2022-08-09',
                        content: '我们俩与千里之外孩子们五天难忘的故事 2022年8月1日—5日，中国建材集团团委在五个定点帮扶区县举办中国建材“善建...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '全民健身日 一起来运动',
                        publishDate: '2022-08-08',
                        content: '全民健身日 一起来运动 没有健康的体魄生活就会失去活力 保温杯里泡枸杞不如燃烧卡路里 今天是...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '耀华集团团委组织开展习近平总书记《论党的青年工作》专题学习',
                        publishDate: '2022-07-15',
                        content: '耀华集团团委组织开展习近平总书记《论党的青年工作》专题学习 近日，耀华集团团委组织开展习近平总书记《论党的青年工作》...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '耀华集团团委开展参观玻璃博物馆、团史馆主题团日活动',
                        publishDate: '2022-06-30',
                        content: '耀华集团团委开展参观玻璃博物馆、团史馆主题团日活动 2022年是中国共产主义青年团建团100周年，为传承红色理想、汲...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '奋斗是青春最亮丽的底色',
                        publishDate: '2022-05-05',
                        content: '奋斗是青春最亮丽的底色 100年前，在中国共产党的领导下一股青春的力量开始焕发生机一个凝聚青年的组织开...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '7',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '耀华集团青年集体和个人受到凯盛科技集团团委表彰',
                        publishDate: '2022-04-28',
                        content: '耀华集团青年集体和个人受到凯盛科技集团团委表彰 耀华玻璃 2022-04-28 17:10发表于河北 ...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '8',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '以雷锋奉献精神 亮耀华青年底色',
                        publishDate: '2022-03-07',
                        content: '以雷锋奉献精神 亮耀华青年底色 "向雷锋同志学习"是毛泽东主席1963年3月的题词，号召全国人民学习雷锋的共产主义精...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '9',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '做花灯 摇元宵 耀华团委组织花样闹元宵',
                        publishDate: '2022-02-16',
                        content: '做花灯 摇元宵 耀华团委组织花样闹元宵 为了营造元宵节欢乐祥和的节日氛围，2月15日是农历正月十五元宵节，耀华团委举...',
                        moreLink: '/#/party/youthWork'
                    },
                    {
                        name: '10',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '踏着歌声我们步入春天 —耀华集团举办迎春节卡拉OK大赛',
                        publishDate: '2019-11-06',
                        content: '春天迈着轻盈的脚步，悄悄地、悄悄地向我们走来了。辛苦工作一年的耀华职工，已经感受到了春的消息。在岁末年初的时候，集团团委、工会组...',
                        moreLink: '/#/party/youthWork'
                    },


                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style scoped>

</style>
