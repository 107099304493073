
export const newsItems = [
    {
        name: '1',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '叙情怀，话蓝图，耀华退休老领导 相约回“家”再团聚！',
        publishDate: '2023-09-19',
        content: '叙情怀，话蓝图，耀华退休老领导 相约回“家”再团聚！ 叙情怀，话蓝图，耀华退休老领导相约回“家”再...',
        moreLink: '/#/'
    },
    {
        name: '2',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '职工之家•缘定耀华丨河北省新时代“职工之家”建设成果展示活动走进百年耀华',
        publishDate: '2023-09-08',
        content: '职工之家•缘定耀华丨河北省新时代“职工之家”建设成果展示活动走进百年耀华 “1954年毛主席亲自视察...',
        moreLink: '/#/'
    },
    {
        name: '3',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '中国建材“善用资源日”开放活动 —走进耀华高新技术产业园',
        publishDate: '2023-08-25',
        content: '中国建材“善用资源日”开放活动 —走进耀华高新技术产业园 今年是中国建材集团第四届“善用资源日”，本...',
        moreLink: '/#/'
    },
    {
        name: '4',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '河北省委书记倪岳峰在秦期间 到耀华调研指导工作',
        publishDate: '2023-07-23',
        content: '河北省委书记倪岳峰在秦期间 到耀华调研指导工作 倪岳峰在秦皇岛市调研时强调，积极构建产学研协作新模式...',
        moreLink: '/#/'
    },
    {
        name: '5',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '市长丁伟到耀华高新技术产业园 新建生产线调研',
        publishDate: '2023-07-11',
        content: '市长丁伟到耀华高新技术产业园 新建生产线调研 7月10日，秦皇岛市委副书记、市长丁伟到耀华高新技术产...',
        moreLink: '/#/'
    },
    {
        name: '6',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '耀华集团隆重召开庆祝中国共产党成立 102周年暨“两优一先”表彰大会',
        publishDate: '2023-06-27',
        content: '耀华集团隆重召开庆祝中国共产党成立 102周年暨“两优一先”表彰大会 6月27日，耀华集团隆重召开庆...',
        moreLink: '/#/'
    },
    {
        name: '7',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '最美科研先锋 | 耀华玻璃董淑娟：开拓创新深耕颜色玻璃数十载 助力增强企业竞争力',
        publishDate: '2023-05-23',
        content: '最美科研先锋 | 耀华玻璃董淑娟：开拓创新深耕颜色玻璃数十载 助力增强企业竞争力 耀华玻璃2023-02-22 18...',
        moreLink: '/#/'
    },
    {
        name: '8',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '深加工板块上下齐心，铆足干劲稳增长',
        publishDate: '2023-05-23',
        content: '深加工板块上下齐心，铆足干劲稳增长 耀华玻璃2023-02-06 20:03 新春伊始，耀华集团深加工板...',
        moreLink: '/#/'
    },
    {
        name: '9',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '向更高目标冲刺，以实干开创凯盛未来！凯盛科技集团召开2023年度工作会议',
        publishDate: '2023-05-23',
        content: '向更高目标冲刺，以实干开创凯盛未来！凯盛科技集团召开2023年度工作会议 耀华玻璃2023-02-24 19:02 ...',
        moreLink: '/#/'
    },
    {
        name: '10',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '特种玻璃板块满弦开局奏响春之曲',
        publishDate: '2023-05-23',
        content: '特种玻璃板块满弦开局奏响春之曲 耀华玻璃2023-02-03 18:24 开局就是决战，起跑就要加速。...',
        moreLink: '/#/'
    },
    {
        name: '11',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '耀华节能公司 — 实干笃定前行',
        publishDate: '2023-05-23',
        content: '耀华节能公司 — 实干笃定前行 耀华玻璃2023-02-23 18:13 耀华节能公司以稳定生产为基础，...',
        moreLink: '/#/'
    },
    {
        name: '12',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '耀华秦皇岛公司优化产品结构，启航管理提升新征程',
        publishDate: '2023-05-23',
        content: '耀华秦皇岛公司优化产品结构，启航管理提升新征程 耀华玻璃2023-01-04 20:13 2022年耀华...',
        moreLink: '/#/'
    },
    {
        name: '13',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '耀华集团召开2022年度工作业绩汇报会',
        publishDate: '2023-05-23',
        content: '耀华集团召开2022年度工作业绩汇报会 耀华玻璃2023-01-14 23:20 1月14日，耀华集团召...',
        moreLink: '/#/'
    },
    {
        name: '14',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '耀华集团先进集体和个人受到凯盛科技集团表彰',
        publishDate: '2023-05-23',
        content: '耀华集团先进集体和个人受到凯盛科技集团表彰 耀华玻璃2023-01-12 23:32发表于河北 2023...',
        moreLink: '/#/'
    },
    {
        name: '14',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '耀华集团党委召开2022年度成员企业党组织书记抓党建述职评议考核会',
        publishDate: '2023-05-23',
        content: '耀华集团党委召开2022年度成员企业党组织书记抓党建述职评议考核会 耀华玻璃2023-01-19 17:03 ...',
        moreLink: '/#/'
    },
    {
        name: '15',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '新春走基层 | 耀华集团领导慰问春节期间一线在岗职工',
        publishDate: '2023-05-23',
        content: '新春走基层 | 耀华集团领导慰问春节期间一线在岗职工 耀华玻璃2023-01-27 07:00 ...',
        moreLink: '/#/'
    },
    {
        name: '16',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '开启百年耀华新征程 | 耀华集团召开2023年工作会议暨职代会',
        publishDate: '2023-05-23',
        content: '开启百年耀华新征程 | 耀华集团召开2023年工作会议暨职代会 耀华玻璃2023-01-18 22:24 ...',
        moreLink: '/#/'
    },
    {
        name: '17',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '你们有梦想 我们有舞台 耀华好声音青年歌手大赛唱响活力青春！',
        publishDate: '2023-05-15',
        content: '你们有梦想 我们有舞台 耀华好声音青年歌手大赛唱响活力青春！ 唱出活力青春，释放激情无限！5月15日...',
        moreLink: '/#/'
    },
    {
        name: '18',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '耀华集团亮相第32届中国国际玻璃展',
        publishDate: '2023-05-06',
        content: '耀华集团亮相第32届中国国际玻璃展 5月6日，第32届中国国际玻璃工业技术展览会在上海新国际博览中心...',
        moreLink: '/#/'
    },
    {
        name: '19',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        newsName: '走进成员企业⑩| 濮阳光材紧紧围绕“创新”精耕细作',
        publishDate: '2022-10-13',
        content: '走进成员企业⑩| 濮阳光材紧紧围绕“创新”精耕细作 中建材（濮阳）光电材料有限公司位于河南省濮阳县产业集聚区，占地3...',
        moreLink: '/#/'
    },
];
