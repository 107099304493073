export const products = [
    // 平板玻璃
    {
        id: '86',
        name: '浮法透明玻璃',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "可提供1.8~19mm 各种规格的高质量透明玻璃，该产品适用于眼睛、镜框、汽车玻璃、建筑及各种加工产品使用。" },
                { title: "超薄制镜玻璃", subtitle: "UItra-thin glass for mirror", content: "适用于高级制镜、画框等\n厚度：1.6mm，1.8mm，2.0mm，2.1mm，2.5mm，3.0mm。\n规格：1219×914—3660×2440，特殊规格可按用户要求。" },
                { title: "汽车风挡玻璃", subtitle: "Automobile windshield glass", content: "提供高质量、性能稳定的汽车风挡玻璃。\n厚度分为：2mm、2.1mm、2.3mm、2.5mm、3mm、3.2mm、3.5mm及5mm、6mm 玻璃。" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05733ceb938.jpg',
                '5e05734390700.jpg',
                '5e05734943479.jpg',
            ],
        },
    },
    {
        id: '366',
        name: '黑色玻璃',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "产品规格\n最小尺寸：1220×1830\n最大尺寸：2440×3660\n可根据客户要求提供定制尺寸\n\n厚度范围\n1.6mm—15mm" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '63902879d682d.jpg',
                '63905321cab2c.jpg',
            ],
        },
    },
    {
        id: '180',
        name: '金茶玻',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2019-12-27',
        content: {
            description: [
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05aee849f38.jpg',
            ],
        },
    },
    {
        id: '71',
        name: '金茶玻',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2019-12-27',
        content: {
            description: [
                { title: "茶玻璃性能参数", subtitle: "Bronze glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05a6fbc538f.jpg',
            ],
        },
    },
    {
        id: '70',
        name: '深灰玻璃',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "深灰玻璃性能参数", subtitle: "Dark grey glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05b6146add4.jpg',
            ],
        },
    },
    {
        id: '69',
        name: '浅灰玻璃',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "浅灰玻璃性能参数", subtitle: "Euro grey glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05b5a86435b.jpg',
            ],
        },
    },
    {
        id: '68',
        name: '粉玻璃',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2019-11-06',
        content: {
            description: [
                { title: "粉玻璃性能参数", subtitle: "Pink glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05adea3aef1.jpg',
            ],
        },
    },
    {
        id: '67',
        name: '粉玻璃',
        parentId: '07030901',
        parentName: '平板玻璃',
        source: '耀华玻璃',
        date: '2019-11-06',
        content: {
            description: [
                { title: "福特蓝玻璃性能参数", subtitle: "Ford blue glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05ae611955b.jpg',
            ],
        },
    },
    // 镀膜玻璃
    {
        id: '179',
        name: '金茶色镀膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-12-27',
        content: {
            description: [],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e057cc4c7f32.jpg',
                '5e057ccacd1b1.jpg',
            ],
        },
    },
    {
        id: '178',
        name: '白色镀膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-12-27',
        content: {
            description: [
                { title: "福特蓝玻璃性能参数", subtitle: "Ford blue glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e0573c94499a.jpg',
                '5e0573d06dd54.jpg',
                '5e0573d633f30.jpg',
                '5e0573db7f72a.jpg',
            ],
        },
    },
    {
        id: '83',
        name: '在线彩膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-12-27',
        content: {
            description: [
                { title: "在线彩膜玻璃性能参数", subtitle: "On color coated glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5dc4bcade1d94.jpg',
            ],
        },
    },
    {
        id: '81',
        name: '茶色镀膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "茶色镀膜玻璃性能参数", subtitle: "Bronze reflective glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e057c614b437.jpg',
            ],
        },
    },
    {
        id: '79',
        name: '福特蓝镀膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "福特蓝镀膜玻璃性能参数", subtitle: "Ford blue reflective glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e057ec560e6c.jpg',
                '5e057eccc4e47.jpg',
            ],
        },
    },
    {
        id: '77',
        name: '粉红镀膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "粉红镀膜玻璃性能参数", subtitle: "Pink reflective glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e057d37df1ab.jpg',
            ],
        },
    },
    {
        id: '76',
        name: '深灰镀膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "深灰镀膜玻璃性能参数", subtitle: "Dark grey reflective glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05a51a94a0b.jpg',
            ],
        },
    },
    {
        id: '75',
        name: '浅灰镀膜玻璃',
        parentId: '07030907',
        parentName: '镀膜玻璃',
        source: '耀华玻璃',
        date: '2019-11-07',
        content: {
            description: [
                { title: "浅灰镀膜玻璃性能参数", subtitle: "Euro grey reflective glass performance parameters", content: "" },
            ],
            phone: "联系电话：0086-335-7501725 7501772",
            image: [
                '5e05804f23573.jpg',
                '5e058054e4dfe.jpg',
                '5e05803e189c4.jpg',
                '5e0580440354a.jpg',
            ],
        },
    },
    // 特种玻璃
    {
        id: '385',
        name: '硼硅4.0防火玻璃（BG40）',
        parentId: '07030908',
        parentName: '特种玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "产品性能：极佳的视觉效果、超强的防火性、优异的耐热冲击性能、无需均质处理，无自爆" },
                { title: "", subtitle: "", content: "使用领域：防火玻璃、防火隔断、中空玻璃、幕墙玻璃、有防火要求的天棚、地板" },
                { title: "", subtitle: "", content: "技术参数：出产尺寸（mm）：1600×1200、1600×2400。其他尺寸可根据要求生产" },
                { title: "", subtitle: "", content: "厚度（mm）：6-12\n密度（g/cm³）（at25℃）：2.28±0.02\n膨胀系数（α）（20-300℃）：4.0±0.1*10-6\n软化点（℃）：845±5\n防火等级：C级 I 类 （≥120min）\n执行标准：GB15763.1-2001\n后续加工：切割成型，磨边，钢化。" },
                { title: "", subtitle: "", content: "使用中注意事项：\n1、硼硅单片防火玻璃不能使用在有明确要求具有隔热或放热辐射功能的场合、\n2、硼硅单片防火玻璃是经过特殊处理的安全玻璃，加工成型后，不能进行切割等深加工。\n3、搬运和安装时应注意避免硼硅单片防火玻璃表面划伤。" },
            ],
            phone: "",
            image: [
                '639047961ba41.jpg',
                '639047bd87d03.jpg',
                '639047c84ebc5.jpg',
                '639047d38603f.jpg',
                '639047b420dfe.jpg',
                '639047a94578d.jpg',
                '639047ddc6841.jpg',
                '639047e6cd166.jpg',
                '639047ef6457d.jpg',
            ],
        },
    },
    {
        id: '384',
        name: '硼硅3.3浮法玻璃（BG33）',
        parentId: '07030908',
        parentName: '特种玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "应用领域：\n·家用电器（烤箱内部的玻璃面板，微波炉托盘，火炉面板等）\n·环境工程 化学工程（抵抗性衬层，化学反应釜，安全视镜）\n·照明（聚光灯和大功率泛光照明灯具的保护玻璃）\n·太阳能发电（太阳能电池基板）\n·精密器械（光学滤光片）\n·半导体技术（晶片，显示玻璃）\n·医学技术 生物工程\n·安全防护（防弹玻璃）" },
                { title: "", subtitle: "", content: "产品特性：\n·良好的耐高温性能，化学性能稳定\n·极佳的透过率\n·有多种厚度可供选择\n·可用于多种光学用途" },
                { title: "", subtitle: "", content: "技术参数：\n·密度 (g/cm3 )（(at 25℃ )）：2.23±0.02\n·膨胀系数 (20-300℃ )：3.3±0.1×10-6·K-1\n·软化点 (℃ )：820±10\n·同片温差性能（增强型）(K)：100 ＞300（增强型）\n·抗热冲击性：≤ 240℃\n·最高工作温度 (℃ )：≤ 450\n·折射率 nd : 1.47384\n·透过率：92%（厚度≤ 4mm）91% ( 厚度≥ 5mm)" },
                { title: "", subtitle: "", content: "产品规格：\n常规尺寸: 1150×850mm 1700×1150mm\n1200×800mm 1600×1200mm\n其他尺寸可根据要求生产" },
                { title: "", subtitle: "", content: "厚度范围：\n2-25mm" },
                { title: "", subtitle: "", content: "后续加工：\n切割，磨边，钻孔，镀膜，半钢化，丝印等" },
            ],
            phone: "联系电话：0086-335-7092443 7092444",
            image: [
                '63904615dfd71.jpg',
            ],
        },
    },
    {
        id: '383',
        name: '高效薄膜太阳能电池高温玻璃（中铝玻璃）',
        parentId: '07030908',
        parentName: '特种玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "应用领域：\n薄膜太阳能电池、防眩玻璃、双玻组件、汽车配套中控屏等。" },
                { title: "", subtitle: "", content: "产品特性：\n应变点高，高温加热过程中变形较小，热缩均匀，硬度、强度及耐磨度优于普通玻璃。" },
                { title: "", subtitle: "", content: "产品规格：\n可定制尺寸" },
                { title: "", subtitle: "", content: "厚度范围：\n1.1-3.2mm" },
            ],
            phone: "联系电话：0086-552-4081378",
            image: [
                '6390459116512.jpg',
            ],
        },
    },
    {
        id: '382',
        name: '光热玻璃',
        parentId: '07030908',
        parentName: '特种玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "光热玻璃是采用浮法工艺生产，具有优越的物理性能、机械强度高以及明显优于一般超白玻璃的光学性能。光热玻璃是太阳能光热发电的核心基础部件聚光镜的基础材料，能显著提高光热转换效率，满足太阳能光热发电产业的发展要求。" },
                { title: "", subtitle: "", content: "应用领域：光热发电、导光板" },
                { title: "", subtitle: "", content: "产品特性：\nFe2O3 含量小于 0.009%，具有极高的透光率。\n光热玻璃中杂质含量极低、自爆率低、耐候性高。\n机械加工适应性高、表面平滑度高。" },
                { title: "", subtitle: "", content: "产品规格：\n最小尺寸: 1830×1220mm\n最大尺寸: 6000×3660mm\n可根据客户要求提供定制尺寸" },
                { title: "", subtitle: "", content: "厚度范围：\n2-4mm" },
            ],
            phone: "联系电话：0086-196-3931-8928 196-3931-8930",
            image: [
                '639043810953c.jpg',
                '6390438b58ae9.jpg',
                '6390439501b6e.jpg',
                '6390439dbf36c.jpg',
                '639043a5c282e.jpg',
            ],
        },
    },
    {
        id: '381',
        name: '超白玻璃',
        parentId: '07030908',
        parentName: '特种玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "应用领域：高端建筑、高档园艺建、高档玻璃家具、工艺品、灯具、展柜、高档鱼缸、水族馆、科技产品、电子产品、高档轿车玻璃、太阳能电池等。" },
                { title: "", subtitle: "", content: "产品特性：\n可见光透光率可达 91% 以上；\n极高的安全性，钢化玻璃的自爆率接近零；\n纯净的视觉效果非常适用于幕墙及深加工产品基板的应用；\n极佳的显色性能，赋予玻璃幕墙晶莹剔透、高贵典雅的特质；" },
                { title: "", subtitle: "", content: "产品规格：\n最小尺寸: 1830×1220mm\n最大尺寸: 6000×3660mm\n可根据客户要求提供定制尺寸" },
                { title: "", subtitle: "", content: "厚度范围:\n2-12mm" },
            ],
            phone: "联系电话：0086-196-3931-8928 196-3931-8930",
            image: [
                '63903d053e9c6.png',
            ],
        },
    },
    // 深加工玻璃
    {
        id: '395',
        name: 'CIGS薄膜太阳能电池背电极',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "通过在 2.1mm 中铝白玻上溅射工艺形成，由钼电极导电层和玻璃基板组成，适用于铜铟镓硒薄膜太阳能电池组件的导电电极玻璃。\nCIGS 薄膜太阳能电池具有成本低、温度系数弱、弱光效应好、外形美观、重量轻等优点，尤其适合于 BIPV，可根据建筑需求制成黄色，蓝色，灰色等多色彩太阳能电池，发展潜力巨大，被业界广泛评为“太阳能能源的未来”。 CIGS 薄膜太阳能电池背电极是 CIGS 薄膜太阳能电池板的重要组成部分，既是新材料产业的具体应用，也是新能源光伏发电产业链的重要环节。" },
                { title: "", subtitle: "", content: "产品特性：\n·背电极导电性高，背电级方阻≤ 0.78Ω/ □；\n·膜层稳定性高；\n·产品通过磁控溅射工艺形成，绿色无污染。" },
                { title: "", subtitle: "", content: "产品规格：\n常规尺寸: 1583×660mm" },
                { title: "", subtitle: "", content: "厚度范围：\n2.1mm" },
            ],
            phone: "联系电话：0086-552-2861008",
            image: [
                '639050e23faf5.jpg',
                '639050ea5f74f.jpg',
            ],
        },
    },
    {
        id: '394',
        name: '调光玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "调光玻璃是一款将液晶膜复合到两层玻璃中间，经高温高压胶合后一体成型的夹层结构的新型特种光电玻璃产品，使用者通过控制电流的通断与否控制玻璃的透明与不透明状态。" },
                { title: "", subtitle: "", content: "应用领域：\n广泛应用于需隐密性的卫浴空间、商业空间、兼具投影荧幕、科技住宅、展览空间等。" },
                { title: "", subtitle: "", content: "产品规格：\n最小尺寸: 300×300mm\n最大尺寸: 3500×2000mm" },
                { title: "", subtitle: "", content: "厚度范围：\n10-18mm" },
                { title: "", subtitle: "", content: "性能参数：" },
            ],
            phone: "联系电话：0086-199-3866-3771 191-4032-7812",
            image: [
                '6390506db6c03.jpg',
            ],
        },
    },
    {
        id: '393',
        name: '家电玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "家电玻璃外观通透，具备抗风压性、热稳定性、抗冲击性，良好的保温隔热效果，受外力冲击不易破损，安全节能。\n应用领域：\n广泛应用于各类家用电器（冰箱、微波炉、烤箱等）的内置隔板、外层面板等方面。" },
                { title: "", subtitle: "", content: "产品规格\n最小尺寸: 300×300mm\n最大尺寸: 1600×1200mm" },
                { title: "", subtitle: "", content: "厚度范围\n1.6-6mm" },
            ],
            phone: "联系电话：0086-454-6075518  0086-370-2597025",
            image: [
                '63904ff84f771.jpg',
            ],
        },
    },
    {
        id: '392',
        name: '彩釉玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "彩釉玻璃是在浮法玻璃表面通过丝网印刷、辊刷、数码打印等印刷方法涂制一层无机釉料，经过烘干，高温处理，使釉料烧结在玻璃表面，使玻璃达到预期的装饰效果。彩釉玻璃颜色丰富、色泽亮丽，可根据客户需求另行设计花纹。 具有绝佳的遮蔽性和优良的阳光控制作用。" },
                { title: "", subtitle: "", content: "应用领域\n广泛用于外墙装饰" },
                { title: "", subtitle: "", content: "产品特性\n·机械强度高、耐光、耐热、耐寒、防止紫外线等性能；\n·装饰：色彩、图案丰富；\n·可根据客户要求定制达到预期的装饰效果；\n·节能：对阳光有一定的吸收和反射作用，具有遮阳效果。" },
                { title: "", subtitle: "", content: "产品规格：\n最小尺寸: 300×300mm\n最大尺寸: 6000×2500mm" },
                { title: "", subtitle: "", content: "厚度范围：\n3-25mm" },
            ],
            phone: "联系电话：0086-552-2861008",
            image: [
                '63904fa3c95ee.jpg',
            ],
        },
    },
    {
        id: '391',
        name: '弯钢玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "弯钢化玻璃把玻璃加热到软化点后成型，然后将其快速均匀冷却制成。兴科玻璃使用辊道成型水平对流加热钢化炉，可以生产各种大小圆弧弯钢化玻璃产品，尤其适合加工镀膜类产品，产品成型精度高，外观质量优秀。可以制成弯夹层，弯中空等各种复合产品。" },
                { title: "", subtitle: "", content: "产品规格\n最小尺寸: 600×400mm\n最大尺寸: 2500×2400mm" },
                { title: "", subtitle: "", content: "厚度范围\n5-19mm" },
            ],
            phone: "联系电话：0086-552-2861008",
            image: [
                '63904f442385e.jpg',
                '63904f4c0d98f.jpg',
                '63904f53a8ae7.jpg',
            ],
        },
    },
    {
        id: '390',
        name: '钢化玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "钢化玻璃是浮法玻璃原片通过加热并急剧冷却处理，使玻璃表面呈现均匀的应压力，玻璃内部呈现均匀的张应力，从而使玻璃的柔性更好，强度增加，安全性大幅增强。钢化玻璃表面应力为: 69-168Mpa。" },
                { title: "", subtitle: "", content: "应用领域：\n可广泛用于建筑、汽车、飞机、船舶、仪器、仪表及家具等领域。" },
                { title: "", subtitle: "", content: "产品特性：\n·安全性：破裂后呈碎小钝角颗粒，对人体不会造成重大伤害。\n·高强度：一般是普通玻璃强度的 4 倍及以上。\n·挠度：是普通玻璃的 3 ～ 4 倍。如：6×1200×350（mm）的玻璃，其挠度可达 100mm。6mm 的钢化玻璃其抗弯强度可达 1700kg/cm2。\n·热稳定性：良好的热稳定性。钢化玻璃能经受的温差是退火玻璃的 2.5 ～3 倍，最高使用温度则接近 300℃。" },
                { title: "", subtitle: "", content: "产品规格：\n最小尺寸: 150×250mm\n最大尺寸: 2440×6000mm" },
                { title: "", subtitle: "", content: "厚度范围\n3.2-19mm" },
            ],
            phone: "联系电话：0086-199-3866-3771 191-4032-7812",
            image: [
                '63904edb3a392.jpg',
                '63904ee44a29d.jpg',
                '63904eeb7772d.jpg',
            ],
        },
    },
    {
        id: '389',
        name: '夹层玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "夹层玻璃是一种在两片或多片玻璃之间夹以 PVB 薄膜，经过高温高压制成的高级安全玻璃。玻璃即使碎裂，碎片也会被粘在薄膜上，对人体和财产具有较大的保护作用。具有透明、机械强度高、耐光、耐寒、耐热、隔音和防紫外线特性。" },
                { title: "", subtitle: "", content: "应用领域：\n广泛应用于采光顶、建筑幕墙、水族馆、高压设备的观测窗、银行的防弹防爆窗等。" },
                { title: "", subtitle: "", content: "产品特性：\n·安全性：PVB 膜具有很高的粘结性，玻璃在受到外力冲击时，碎片会和胶膜紧紧粘结。\n·防盗性：PVB夹层玻璃非常坚韧，即使将玻璃敲裂，仍保持整体性，使人无法进入室内。\n·防爆性：采用多夹层可制造多种级别的防弹、防爆玻璃。\n·隔音性: PVB 薄膜具有对声波的吸收功能，能有效地抑制噪音的传播。\n· 防紫外线性: PVB薄膜能吸收掉99％以上的紫外线。\n·装饰性: PVB 具有优良的透过性，可选用不同颜色的 PVB膜，使夹层玻璃更加美观。" },
                { title: "", subtitle: "", content: "产品规格：\n平夹胶最大厚度：32mm\n最小尺寸：100×100mm\n最大尺寸：2200×5900mm\n弯夹胶最大厚度：26mm\n最小尺寸：500×500mm\n胶最大尺寸：2400×3660mm\n最小弯曲半径：R=450 mm" },
                { title: "", subtitle: "", content: "厚度范围\n3.2-19mm" },
            ],
            phone: "联系电话：0086-199-3866-3771 191-4032-7812",
            image: [
                '63904e767bca3.png',
            ],
        },
    },
    {
        id: '388',
        name: '中空玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "中空玻璃是一种良好的隔热、隔音、美观适用、可降低建筑物自重的新型建筑材料，是以两片或多片玻璃使用高强度、高气密性复合粘结剂，将玻璃片与内含干燥剂的铝合金框架粘结，制成的高效能隔音隔热玻璃。其主要材料是玻璃、铝间隔条、插角接头、丁基胶、硅酮密封胶、干燥剂。" },
                { title: "", subtitle: "", content: "应用领域\n可用于高档建筑物的门窗、天棚、需要隔音的学校、医院、体育场馆、电视及广播录音室等场所，也可应用于制冷电器、飞机、船舶、机车等领域。" },
                { title: "", subtitle: "", content: "产品特性\n·隔热、保温：中空玻璃密封状态下具有很低的导热和传热系数，使整个玻璃制品具有良好的保温性能，创造一个舒适的居住环境。\n·隔音：中空玻璃具有良好的隔音效果，达 30 分贝以上，填充特定气体可进一步改善隔音性能。\n·防结露：由于中空玻璃内部存在着可以吸附水分子的干燥剂，气体是干燥的，在温度降低时，中空玻璃的内部也不会产生凝露的现象，同时，在中空玻璃的外表面结露点也会升高，露点低于-40℃，防止室内外温差导致结露。\n·抗风压强度提高。幕墙主要承受风荷载，抗风压成为幕墙的主要指标。中空玻璃的抗风压强度是单片玻璃的1.5倍。\n·玻璃不易自爆。中空玻璃生产方法为粘结法，为冷加工，玻璃原片内应力不改变，四周以弹性材料密封，玻璃不易自爆。\n·装饰：中空玻璃可以复合镀膜、夹层、彩釉等其他品种玻璃，具有良好的装饰效果。" },
                { title: "", subtitle: "", content: "产品规格\n最小尺寸: 300×300mm\n最大尺寸: 3500×2500mm" },
                { title: "", subtitle: "", content: "厚度范围\n10-60mm" },
            ],
            phone: "联系电话：0086-199-3866-3771 191-4032-7812",
            image: [
                '63904dc403cdc.jpg',
                '63904dd512631.jpg',
            ],
        },
    },
    {
        id: '387',
        name: '阳光控制镀膜玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "阳光控制镀膜玻璃又叫热反射玻璃，它是在玻璃表面镀上金属膜及金属氧化物或氮化物膜，使玻璃的遮阳系数Sc从 0.98（6mm 透明玻璃）降低到 0.2~0.6。" },
                { title: "", subtitle: "", content: "颜色\n蓝色、灰色、水晶灰、蓝灰等" },
                { title: "", subtitle: "", content: "产品特性\n·减弱紫外线透过;\n·多种反射色调、理想的可见光透射率和反射率;\n·降低玻璃的遮阳系数 Sc，限制太阳辐射的直接透过、 低太阳能获得率、理想的遮阳系数;\n·可单片使用。" },
                { title: "", subtitle: "", content: "产品规格\n最大尺寸: 6000×3300mm" },
                { title: "", subtitle: "", content: "厚度范围\n4-12mm" },
            ],
            phone: "联系电话：0086-199-3866-3771 191-4032-7812",
            image: [
                '63904d697c91e.jpg',
            ],
        },
    },
    {
        id: '386',
        name: '低辐射镀膜玻璃',
        parentId: '07030909',
        parentName: '深加工玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "", subtitle: "", content: "低辐射镀膜玻璃又叫 Low-E 玻璃，是在玻璃表面镀制一层或多层具有低辐射功能的金属或化合物的薄膜 , 玻璃的辐射率 E 由 0.84 降低到 0.15 以下形成的，使玻璃表面具有极高的远红外线反射率，从而达到保温的目的。与普通玻璃及传统的建筑用镀膜玻璃相比，具有更优异的隔热效果和良好的视觉效果。" },
                { title: "", subtitle: "", content: "颜色:\n蓝色、灰色、水晶灰、蓝灰、绿色、浅绿、黄色等。" },
                { title: "", subtitle: "", content: "应用领域:\n高档建筑的门窗或幕墙玻璃以及火车、汽车、轮船的风挡玻璃等。也可按不同用途进行加工，如制成磨光、夹层、镜面及中空玻璃，隔热效果显著。" },
                { title: "", subtitle: "", content: "产品特性:\n·较高的可见光透过率，自然采光，外观清澈。\n·良好的保温性能，有效降低室内热量散失， 营造温暖舒适的室内环境。\n·较低的传热系数，有效降低冬季采暖费用。\n·较高的遮阳系数，太阳红外热能直接透射比低，夏季隔热性好\n·不可单片使用，只能合成中空玻璃使用。" },
                { title: "", subtitle: "", content: "产品规格:\n最小尺寸：300×300mm\n最大尺寸：6000×3300mm\n客户也可定制其他厚度和尺寸。" },
                { title: "", subtitle: "", content: "厚度范围\n2-12mm" },
            ],
            phone: "联系电话：0086-199-3866-3771 191-4032-7812",
            image: [
                '63904d006c62a.jpg',
                '63904d0852112.jpg',
            ],
        },
    },
    // 汽车玻璃
    {
        id: '215',
        name: '汽车玻璃',
        parentId: '07030906',
        parentName: '汽车玻璃',
        source: '耀华玻璃',
        date: '2022-12-07',
        content: {
            description: [
                { title: "产品概述", subtitle: "", content: "产品以其良好的光学性能、较好的化学稳定性、较高的机械强度、良好的热稳定性，具备平整度优良、高透、可热弯、表面质量高等特性，产品可做切割、磨边、钻孔、钢化、夹胶、丝印、热弯、镀膜等高档深加工处理。" },
                { title: "生产规格", subtitle: "", content: "颜色:\n蓝色、灰色、水晶灰、蓝灰、绿色、浅绿、黄色等。最小：914mm*1220mm\n最大：3660mm*2440mm" },
                { title: "产品标准", subtitle: "", content: "合格板宽4880mm，能够生产二分、三分、四分板，可切割规格丰富，能够生产客户需要的各种非标准尺寸。\n采用高于国标GB11614-2009标准的企业内控标准，为了便于客户更好地选择，企业内控标准根据产品应用领域不同进行了细分等级。" },
                { title: "产品认证", subtitle: "", content: "公司通过GB/T19001-2016/ISO9001：2015质量管理体系认证。产品全部通过国家玻璃质量监督检验中心型式检验，通过通标标准技术服务（天津）有限公司SGS检测，河北省玻璃产品质量监督检验中心抽查检验全部合格。" },
                { title: "主要厚度", subtitle: "", content: "1.8mm-4mm" },
                { title: "主要用途", subtitle: "", content: "可用于加工汽车前档玻璃、后档玻璃、侧窗玻璃等" },
                { title: "主要控制参数", subtitle: "", content: "薄厚差≦0.1mm；\n弯曲度≦1.5‰；\n微缺陷控制在0.3mm；\n2mm斑马角≧50°；\n3mm透光率≧90%；\n满足汽车玻璃其他质量要求。" },
            ],
            phone: "联系电话：0086-199-3866-3771 191-4032-7812",
            image: [
                '5e54e6051eb71.png',
                '5e61e7ec3ef80.jpg',
                '5e61e7f94c7f1.jpg',
                '5e61e7d6d614e.jpg',
                '5e61e800620fd.png',
            ],
        },
    },
    // 光伏玻璃
    {
        id: '217',
        name: '晶硅电池2mm背板玻璃',
        parentId: '07030903',
        parentName: '光伏玻璃',
        source: '耀华玻璃',
        date: '2020-03-10',
        content: {
            description: [],
            phone: "",
            image: [
                '5e66e3d1cbf3b.jpg',
            ],
        },
    },
    {
        id: '216',
        name: '碲化镉电池2.2mm背板玻璃',
        parentId: '07030903',
        parentName: '光伏玻璃',
        source: '耀华玻璃',
        date: '2020-03-10',
        content: {
            description: [],
            phone: "",
            image: [
                '5e66e39b495c0.jpg',
            ],
        },
    },
    {
        id: '214',
        name: '光伏玻璃',
        parentId: '07030903',
        parentName: '光伏玻璃',
        source: '耀华玻璃',
        date: '2020-03-10',
        content: {
            description: [
                { title: "产品概述", subtitle: "", content: "产品以其良好的光学性能、较好的化学稳定性、较高的机械强度、良好的热稳定性，具备平整度优良、高透、可热弯、表面质量高等特性，产品可做切割、磨边、钻孔、钢化、夹胶、丝印、热弯、镀膜等高档深加工处理。" },
                { title: "生产规格", subtitle: "", content: "最小：914mm*1220mm\n最大：3660mm*2440mm" },
                { title: "产品标准", subtitle: "", content: "合格板宽4880mm，能够生产二分、三分、四分板，可切割规格丰富，能够生产客户需要的各种非标准尺寸。\n采用高于国标GB11614-2009标准的企业内控标准，为了便于客户更好地选择，企业内控标准根据产品应用领域不同进行了细分等级。" },
                { title: "产品认证", subtitle: "", content: "公司通过GB/T19001-2016/ISO9001：2015质量管理体系认证。产品全部通过国家玻璃质量监督检验中心型式检验，通过通标标准技术服务（天津）有限公司SGS检测，河北省玻璃产品质量监督检验中心抽查检验全部合格。" },
                { title: "主要厚度", subtitle: "", content: "1.8mm-2.5mm" },
                { title: "主要用途", subtitle: "", content: "可用于加工太阳能薄膜电池背板玻璃、光伏双玻组件背板玻璃等" },
                { title: "主要控制参数", subtitle: "", content: "薄厚差≦0.1mm；\n弯曲度≦1.5‰；\n微缺陷控制在0.8mm；\n2mm斑马角≧50°；\n尺寸偏差≦0.5mm。" },
            ],
            phone: "",
            image: [
                '5e61e9bc6cc16.jpg',
                '5e61e9cbe62eb.jpg',
            ],
        },
    },
    // 电子玻璃
    {
        id: '84',
        name: '电子玻璃',
        parentId: '07030905',
        parentName: '电子玻璃',
        source: '耀华玻璃',
        date: '2020-03-10',
        content: {
            description: [
                { title: "产品概述", subtitle: "", content: "产品以其良好的光学性能、较好的化学稳定性、较高的机械强度、良好的热稳定性，具备平整度优良、高透、可热弯、表面质量高等特性，产品可做切割、磨边、钻孔、钢化、夹胶、丝印、热弯、镀膜等高档深加工处理。产品以其良好的光学性能、较好的化学稳定性、较高的机械强度、良好的热稳定性，具备平整度优良、高透、可热弯、表面质量高等特性，产品可做切割、磨边、钻孔、钢化、夹胶、丝印、热弯、镀膜等高档深加工处理。" },
                { title: "生产规格", subtitle: "", content: "最小：914mm*1220mm\n最大：3660mm*2440mm" },
                { title: "产品标准", subtitle: "", content: "合格板宽4880mm，能够生产二分、三分、四分板，可切割规格丰富，能够生产客户需要的各种非标准尺寸。\n采用高于国标GB11614-2009标准的企业内控标准，为了便于客户更好地选择，企业内控标准根据产品应用领域不同进行了细分等级。" },
                { title: "产品认证", subtitle: "", content: "公司通过GB/T19001-2016/ISO9001：2015质量管理体系认证。产品全部通过国家玻璃质量监督检验中心型式检验，通过通标标准技术服务（天津）有限公司SGS检测，河北省玻璃产品质量监督检验中心抽查检验全部合格。" },
                { title: "主要厚度", subtitle: "", content: "1.8mm-4mm" },
                { title: "主要用途", subtitle: "", content: "可用于加工AG玻璃、复印机玻璃、扫描仪玻璃、视屏玻璃等" },
                { title: "主要控制参数", subtitle: "", content: "薄厚差≦0.1mm；\n弯曲度≦1‰；\n微缺陷控制在0.3mm；\n3mm斑马角≧60°；\n3mm透光率≧90%；\n可做蚀刻AG。" },
            ],
            phone: "",
            image: [
                '5e54e350740db.png',
            ],
        },
    },

]
