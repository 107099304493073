<template>
  <div id="main">

    <div id="subnav">
      <div class="inner">
        <ul>
          <li class="nli">
            <h4><a href="/#/contact/ContactInformation">联系方式</a></h4>
          </li>
          <li class="nli">
            <h4><a href="/#/contact/OnlineMessage">在线留言</a></h4>
          </li>
          <li class="nli">
            <h4><a href="/#/contact/AfterSalesService">售后服务</a></h4>
          </li>
          <li class="nli">
            <h4><a href="/#/contact/CommonQuestion">常见问题</a></h4>
          </li>
        </ul>
      </div>
    </div>

    <div class="custom-collapse">
      <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
        <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
          <template #title>
            <div class="title-container">
              <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
              <span>{{ item.questionName }}</span>
            </div>
          </template>
          <p id="content" style="text-align: left;" v-html="item.content.replace(/\n/g, '<br/>')"></p>
        </el-collapse-item>
      </el-collapse>
      <el-pagination id="pagination"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="20"
                     layout="prev, pager, next, jumper"
                     :total="questionsItems.length"
      />
    </div>
  </div>
</template>

<script>
import {questions} from './item/questions'

export default {
  name: 'CommonQuestion',
  data() {
    return {
      items: [
        {image: '5e0d91686ddb4.jpg'}
      ],
      activeNames: [],
      isFollowVisible: true,
      currentPage: 1, // 当前页数
      questionsItems: questions
    }
  },
  computed: {
    paginatedItems() {
      // 根据当前页数和每页显示数量对折叠项数组进行分页
      const startIndex = (this.currentPage - 1) * 20;
      const endIndex = startIndex + 20;
      return this.questionsItems.slice(startIndex, endIndex);
    }
  },
  mounted() {
    this.$emit('update-carousel', this.items);
  },
  methods: {
    handleChange(names) {
      // 处理展开状态的改变
      // 在这里你可以根据需要更新数据模型中的 expanded 字段
      // 例如，根据 names 数组中的值来判断哪些项是展开的
      this.activeNames = names;
      // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
      this.paginatedItems.forEach(item => {
        item.expanded = this.activeNames.includes(item.name);
      });
    },
    handleCurrentChange(val) {
      // 处理当前页数变化
      this.currentPage = val;
    },
  },
}
</script>

<style>
#main {
  width: 1920px;
  margin: 0 auto;
  background: url(@/components/images/mainbg.png) no-repeat bottom center;
}

.custom-collapse {
  width: 1200px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
}

.title-container {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

#collapse {
  background-color: rgba(255, 255, 255, 0);
}

#pagination {
  display: flex;
  justify-content: center;
}
</style>
