<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/management/hr">人力资源</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/purchase">采购招标</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/security">安全环保</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/system">管理体系</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/statute">政策法规</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/management/hr">企业管理</a></li> &gt;
                    <li><a href="/#/management/system">管理体系</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "managementSystem",
        data() {
            return {
                activeNames: [],
                items: [
                    {image: '5e7435be5c0db.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '管理方针',
                        publishDate: '2020-01-13',
                        content: '公司严格遵守与质量、环境职业健康安全和能源相关的法律法规，认真关注相关方的建议和要求，通过实施管理体系，不断实现公司整体的持续改...',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '体系建设',
                        publishDate: '2020-01-13',
                        content: '为提高本公司的管理水平，确保生产出优质/环保的浮法玻璃产品，营造绿色安全企业，树立良好的企业形象，提高产品在国内外市场的竞争力，...',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中国耀华玻璃集团有限公司质量管理体系认证',
                        publishDate: '2019-11-08',
                        content: '中国耀华玻璃集团有限公司于2018年10月31日通过了中国建材检验认证集团GB/T19001-2016/ISO9001:2015...',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中国耀华玻璃集团有限公司职业健康安全管理体系认证',
                        publishDate: '2019-11-08',
                        content: '中国耀华玻璃集团有限公司于2018年10月31日通过了中国建材检验认证集团GB/T28001-2011 idt IOHSAS18...',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中国耀华玻璃集团有限公司环境管理体系认证',
                        publishDate: '2019-11-08',
                        content: '中国耀华玻璃集团有限公司于2018年10月31日通过了中国建材检验认证集团GB/T24001-2016/ISO14001:201...',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛耀华玻璃技术开发有限公司质量管理体系认证',
                        publishDate: '2019-11-08',
                        content: '',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '7',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘耀节能玻璃有限公司质量管理体系认证',
                        publishDate: '2019-11-08',
                        content: '',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '8',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘耀节能玻璃有限公司职业健康安全管理体系认证',
                        publishDate: '2019-11-08',
                        content: '',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '9',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘耀节能玻璃有限公司环境管理体系认证',
                        publishDate: '2019-11-08',
                        content: '',
                        moreLink: '/#/management/system'
                    },
                    {
                        name: '10',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '秦皇岛弘华特种玻璃有限公司质量管理体系认证',
                        publishDate: '2019-11-08',
                        content: '',
                        moreLink: '/#/management/system'
                    },
                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },

    }
</script>

<style scoped>

</style>
