<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="content">
                    <div id="title">
                        <h4>我国平板玻璃工业发展历程</h4>
                        <span class="source">来源：</span>
                        <span class="date">发布时间：2020-01-13</span>
                    </div>
                    <div id="text">
                        <p style="text-align:center;"></p>
                        <table style="width:100%;" cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000">
                            <tbody>
                            <tr>
                                <td style="text-align:center;width:33%">
                                    <img src="@/../uploads/5eb4b871034c2.jpg" title="5eb4b871034c2.jpg" alt="5eb4b871034c2.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:33%">
                                    <img src="@/../uploads/5eb4b876d30ea.jpg" title="5eb4b876d30ea.jpg" alt="5eb4b876d30ea.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:33%">
                                    <img src="@/../uploads/5eb4b87c79988.jpg" title="5eb4b87c79988.jpg" alt="5eb4b87c79988.jpg" width="" height="" align=""><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center;width:33%">
                                    图为作者上世纪末出国考察照片
                                </td>
                                <td style="text-align:center;width:33%">
                                    图为1933年耀华玻璃厂全景
                                </td>
                                <td style="text-align:center;width:33%">
                                    图为1954年毛泽东主席视察耀华
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p></p>
                        <span style="line-height:1.5;"></span><br>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;"></span><span style="line-height:2;">我国是制造玻璃的文明古国。回首历史，我们可以将中国玻璃工业的发展追溯到十四世纪末期。但在1903年之前我国所用的平板玻璃基本是从国外进口的。自1903年张骞与许鼎霖等开始筹建以“吹筒法”工艺生产平板玻璃，在110余年发展过程中我国平板玻璃实现了四次大的跨越：一是上世纪初至解放前夕，实现了“从无到有”的跨越。典型标志是1903年张骞等人以“吹筒法”生产平板玻璃和1922年耀华引进“弗克法”机制窗玻璃；二是解放后的30年，主要体现在“全面布局和广泛研究上”。由解放初秦皇岛、大连、沈阳三家玻璃厂，又新增了上海、洛阳、株洲、兰州、昆明、蚌埠、杭州、厦门、宿迁等玻璃厂。典型标志是“洛阳浮法工艺技术”研制成功；三是改革开放后的30年，我国平板玻璃工业“发生了翻天覆地的巨大变化”。平板玻璃总量从1978年1784万重箱，到2011年7.85亿重箱。典型标志是“浮法技术水平的全面提升”；四是从2012年开始，进入新时代，我国平板玻璃工业也进入了一个新的发展时期，启动了“第二代浮法玻璃创新研发”。进一步提高质量、增加品种、节能减排、开发新产品，拓展新领域；实现从追赶国际先进，缩小与发达国家差距到进入国际先进水平和领先水平的发展阶段。典型标志是实施“二代浮法创新研发”。</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "aboutHistorylist1"
    }
</script>

<style scoped>

</style>
