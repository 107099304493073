<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <div class="content">
                    <div id="title">
                        <h4>我国“浮法工艺技术”发展阶段</h4>
                        <span class="source">来源：</span>
                        <span class="date">发布时间：2020-01-13</span>
                    </div>
                    <div id="text">
                        <p style="text-indent:2em;">
                            <span style="line-height:2;">我国“浮法工艺技术”的发展经历了以下五个阶段：</span>
                            <strong><span style="line-height:2;">一是自主研发，实现“从无到有”的跨越。</span></strong>
                            <span style="line-height:2;">从1963年至1981年，历经18个春秋，先后完成了“实验室研究”、“中间阶段”、“工业性试验”，1981年4月通过“国家级技术鉴定”并命名为“中国洛阳浮法”，成为与英国皮尔金顿，美国PPG齐名，世界公认的“三大浮法工艺之一”；</span>
                            <strong><span style="line-height:2;">二是改造“垂直引上工艺”，实现“由旧到新”的跨越。</span></strong>
                            <span style="line-height:2;">随着浮法工艺的发展与日趋完善，淘汰落后的“垂直引上工艺”势在必行，从1985年开始洛阳、秦皇岛耀华等共有20余条九机、六机“垂直引上”先后改为浮法玻璃生产线，提升了我国平板玻璃整体工艺技术水平，实现了“由旧到新”的跨越；</span>
                            <strong><span style="line-height:2;">三是通过攻关创新，实现“由低到高”的跨越。</span></strong>
                            <span style="line-height:2;">改革开放以来，我国浮法玻璃工艺技术水平不断提高。单窑熔化能力由300～400t/d，提高至1000～1200&nbsp;t/d，原板宽从3.5～4.0m提高至5.2m，采用浮法工艺技术不但能生产普通用汽车玻璃、制镜玻璃以及各种加工用玻璃，而且能生产0.5～1.1mm超薄玻璃、25mm超厚玻璃、超白玻璃、在线镀膜玻璃等。</span>
                        </p>
                        <p style="text-indent:2em;">
                            <span style="line-height:2;"><br></span>
                        </p>
                        <p style="text-indent:2em;">
                            <span style="line-height:1.5;"></span>
                        </p>
                        <table cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000">
                            <tbody>
                            <tr>
                                <td style="text-align:center;width:33%;">
                                    <img src="@/../uploads/5eb4b93e9f043.jpg" title="5eb4b93e9f043.jpg" alt="5eb4b93e9f043.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    <img src="@/../uploads/5eb4b9461c3b7.jpg" title="5eb4b9461c3b7.jpg" alt="5eb4b9461c3b7.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    <img src="@/../uploads/5eb4b94c819fc.jpg" title="5eb4b94c819fc.jpg" alt="5eb4b94c819fc.jpg" width="" height="" align=""><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center;width:33%;">
                                    <p>
                                        图为1995年“十七届国际玻璃大会”期间
                                    </p>
                                    <p>
                                        国家建材局林汉雄、王燕谋、张人为“三任局长”合影
                                    </p>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    图为2006年张人为会长带队考察“欧洲浮法玻璃生产线”<br>
                                </td>
                                <td style="text-align:center;width:33%;">
                                    图为2006年张人为会长带队访问英国皮尔金顿公司<br>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            <br>
                        </p>
                        <p style="text-indent:2em;">
                            <strong><span style="line-height:2;">四是参与国际合作，实现“由内到外”的跨越。</span></strong>
                            <span style="line-height:2;">上世纪八十年代末在印尼建成第一条300t/d浮法玻璃生产线，随后又向印尼、印度、伊朗和越南出口了4条浮法玻璃成套技术与装备，规模从500&nbsp;t/d到1000&nbsp;t/d，到目前为止在国外设计与总包的生产线有40余条，推动了我国浮法玻璃技术进一步走向世界；</span>
                            <strong><span style="line-height:2;">五是通过“二代浮法研发”向国际领先水平迈进。</span></strong>
                            <span style="line-height:2;">产品质量进一步提高、性能优化、功能提升、实现节能减排。浮法原片质量可满足高档汽车的要求，替代了进口，并先后开发了电子显示、超薄（最薄达0.15mm）、超白、太阳能领域（铜铟镓硒及锑化镉太阳能电池）等多项新产品，达到国际先进和世界领先水平。</span>
                        </p>
                        <p>
                            <br>
                        </p>
                        <table cellpadding="2" cellspacing="0" border="0" class="ke-zeroborder" bordercolor="#000000" style="text-align:center;">
                            <tbody>
                            <tr>
                                <td style="text-align:center;width:50%;">
                                    <img src="@/../uploads/5eb4b968ba9d8.jpg" title="5eb4b968ba9d8.jpg" alt="5eb4b968ba9d8.jpg" width="" height="" align=""><br>
                                </td>
                                <td style="text-align:center;width:50%;">
                                    <img src="@/../uploads/5eb4b96ff0244.jpg" title="5eb4b96ff0244.jpg" alt="5eb4b96ff0244.jpg" width="" height="" align=""><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center;">
                                    图为乔龙德会长主持审查“二代浮法”研发项目<br>
                                </td>
                                <td style="text-align:center;">
                                    图为乔龙德会长出席“二代”浮法阶段性表彰会<br>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br>
                        <br>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "aboutHistorylist2"
    }
</script>

<style scoped>

</style>
