<template>
  <div>
    <!-- 通知公告 -->
    <div class="notice">
      <div class="inner">
        <h4>通知公告:</h4>
        <div class="txtMarquee-left">
          <div class="hd">
            <a class="next"></a>
            <a class="prev"></a>
          </div>
          <div class="bd">
            <div class="tempWrap" style="overflow:hidden; position:relative;">
              <ul class="infoList">
                <li v-for="(notice, index) in notices" :key="index">
                  <a :href="notice.link" target="_blank" @click.prevent="openInCurrentTab(notice.link)">{{ notice.title }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新闻媒体 -->
    <div class="news">
      <div class="h">
        <h3>新闻媒体</h3>
      </div>
      <div class="content">
        <div class="inner">
          <!-- 图片新闻 -->
          <div class="left l">
            <div class="h4">
              <h4>图片新闻</h4>
              <a class="more" href="/Content/browse/cid/070206" @click="handleToIndex()">查看更多 →</a>
            </div>
            <div class="picScroll-left">
              <el-carousel :interval="4000" height="330px" indicator-position="outside" trigger="click">
                <el-carousel-item v-for="(item, index) in imageNews" :key="index">
                  <div class="pic">
                    <router-link :to="item.link" target="_blank" @click.native="handleToIndex()">
                      <div class="jqthumb"
                           :style="{ backgroundImage: 'url(' + require('@/../uploads/' + item.image) + ')' }"></div>
                    </router-link>
                  </div>
                  <div class="title">
                    <router-link :to="item.link" target="_blank" @click.native="handleToIndex()">{{ item.title }}</router-link>
                    <span class="date">{{ item.date }}</span>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--
              <div class="bd">
                <div class="tempWrap">
                  <ul class="picList">
                    <li v-for="(item, index) in imageNews" :key="index" v-show="index === currentIndex">
                      <div class="pic">
                        <router-link :to="item.link" target="_blank">
                          <div class="jqthumb">
                            <div class="picImg" :style="{ backgroundImage: 'url(' + require('@/../uploads/' + item.image) + ')' }"></div>
                          </div>
                        </router-link>
                      </div>
                      <div class="title">
                        <router-link :to="item.link" target="_blank">{{ item.title }}</router-link>
                        <span class="date">{{ item.date }}</span>
                      </div>
                    </li>
                  </ul>
                -->
            </div>
          </div>
          <!-- 文字新闻 -->
          <div class="right r">
            <div class="slideTxtBox">
              <div class="hd">
                <ul>
                  <li v-for="(tab, tabIndex) in ['企业新闻', '微报道']" :key="tabIndex"
                      @click="activeTabIndex = tabIndex" :class="{ 'on': tabIndex === activeTabIndex }">
                    <h4>{{ tab }}</h4>
                  </li>
                </ul>
              </div>
              <div class="bd">
                <div v-show="activeTabIndex === 0">
                  <a class="more" href="/Content/browse/cid/070202" @click="handleToIndex()">查看更多 →</a>
                  <ul>
                    <li v-for="(news, index) in textNews[0].filter((value, index1) => index1 <= 7)" :key="index">
                      <span class="date">{{ news.date }}</span>
                      <router-link :to="news.link" target="_blank" @click.native="handleToIndex()">{{ news.title }}</router-link>
                    </li>
                  </ul>
                </div>
                <div v-show="activeTabIndex === 1">
                  <a class="more" href="/Content/browse/cid/070203" @click="handleToIndex()">查看更多 →</a>
                  <ul>
                    <li v-for="(news, index) in textNews[1].filter((value, index1) => index1 <= 7)" :key="index">
                      <span class="date">{{ news.date }}</span>
                      <router-link :to="news.link" target="_blank" @click.native="handleToIndex()">{{ news.title }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品中心 -->
    <div class="products">
      <div class="h">
        <h3>产品中心</h3>
      </div>
      <div class="content">
        <div class="inner">
          <div class="cpbox" v-for="(product, index) in products" :key="index">
            <a :href="product.link" @click.prevent="openInCurrentTab(product.link)">
              <div class="img">
                <div class="jqthumb"
                     :style="{width: '180px', height: '160px', opacity: '1', backgroundImage: 'url(' + require('@/../uploads/' + product.image) + ')', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', backgroundSize: 'cover'}">
                  <div style="width: 100%; height: 100%;"></div>
                </div>
                <img :src="product.image" style="display: none;">
              </div>
              <h6>{{ product.title }}</h6>
              <p>{{ product.description }}</p>
              <span class="more">查看详情 →</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- 关于耀华 -->
    <div class="about">
      <div class="h">
        <h3>关于耀华</h3>
      </div>
      <div class="content">
        <div class="inner">
          <div class="left l">
            <div class="jianjie">
              <div class="nh">
                <h4>集团简介</h4>
                <a class="more" href="/Content/browse/cid/070101" @click="handleToIndex()">了解详情 →</a>
              </div>
              <div class="text">
                <p>中国耀华玻璃集团有限公司（以下简称“耀华集团”）创建于1922年，是亚洲第一家采用机器连续生产平板玻璃的企业，被誉为“中国玻璃工业的摇篮”。</p>
                <p>耀华集团具有悠久的历史，雄厚的技术、品牌、人才和市场优势。多年来，以国家企业技术中心、博士后科研工作站等科研平台为基础，先后自主研发了在线低辐射镀膜玻璃、自洁净玻璃、硼硅玻璃、铝硅玻璃、熔石英玻璃等一批国内领先的新产品。</p>
              </div>
            </div>
            <div class="wenhua">
              <div class="nh">
                <h4>企业文化</h4>
                <a class="more" href="/Content/browse/cid/070101" @click="handleToIndex()">了解详情 →</a>
              </div>
              <div class="text">
                <p>价值核心：创新&nbsp; 绩效&nbsp; 和谐&nbsp; 责任</p>
                <p>核心理念：善用资源&nbsp; &nbsp;服务建设&nbsp; &nbsp;</p>
                <p>行为准则：敬畏&nbsp; 感恩&nbsp; 谦恭&nbsp; 得体</p>
                <p>文化理念：企业是人&nbsp; 企业靠人&nbsp; 企业为人&nbsp; 让员工与企业共同成长</p>
              </div>
            </div>
          </div>
          <div class="right r">
            <div class="img">
              <img src="@/../uploads/5da815ea7f37b.png">
            </div>
            <div class="img">
              <img src="@/../uploads/5da815c29e195.png">
            </div>
            <div class="img">
              <img src="@/../uploads/5da815b1360c4.png">
            </div>
            <div class="img">
              <img src="@/../uploads/5da815970e688.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="inner">
        <!--党建工作-->
        <div class="left l">
          <div class="both">
            <h3>党建工作</h3>
          </div>
          <div class="slideTxtBoxdj">
            <div class="hd">
              <ul>
                <li v-for="(tab, tabIndex) in djTabs" :key="tabIndex" :class="{ 'on': tabIndex === djActiveTab }"
                    @mouseover="djActiveTab = tabIndex"><span>{{ tab }}</span></li>
              </ul>
            </div>
            <div class="bd">
              <div v-for="(content, index) in djContents" :key="index" v-show="index === djActiveTab">
                <a class="more" :href="content.moreLink" @click.prevent="openInCurrentTab(content.moreLink)" >更多 →</a>
                <ul>
                  <li v-for="(notice, noticeIndex) in content.notices" :key="noticeIndex">
                    <span class="date">{{ notice.date }}</span>
                    <a :href="notice.link" target="_blank" @click.prevent="openInCurrentTab(notice.link)">{{ notice.title }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--企业管理-->
        <div class="right r">
          <div class="both">
            <h3>企业管理</h3>
          </div>
          <div class="slideTxtBoxgl">
            <div class="hd">
              <ul>
                <li v-for="(tab, tabIndex) in glTabs" :key="tabIndex" :class="{ 'on': tabIndex === glActiveTab }"
                    @mouseover="glActiveTab = tabIndex"><span>{{ tab }}</span></li>
              </ul>
            </div>
            <div class="bd">
              <div v-for="(content, index) in glContents" :key="index" v-show="index === glActiveTab">
                <a class="more" :href="content.moreLink" @click.prevent="openInCurrentTab(content.moreLink)">更多 →</a>
                <ul>
                  <li v-for="(notice, noticeIndex) in content.notices" :key="noticeIndex">
                    <span class="date">{{ notice.date }}</span>
                    <a :href="notice.link" target="_blank" @click.prevent="openInCurrentTab(notice.link)">{{ notice.title }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 服务中心 -->
    <div class="service">
      <div class="inner">
        <ul>
          <li class="four"><a href="/Content/browse/cid/070701">联系方式</a></li>
          <li class="first"><a href="/Content/browse/cid/070702">在线留言</a></li>
          <li class="two"><a href="/Content/browse/cid/070703">售后服务</a></li>
          <li class="three"><a href="/Content/browse/cid/070704">常见问题</a></li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import {Carousel, CarouselItem} from 'element-ui';

export default {
  components: {
    'el-carousel': Carousel,
    'el-carousel-item': CarouselItem
  },
  name:'HomePage',
  data() {
    return {
      interval: 4000,
      // 轮播图
      items: [
        {image: '5e0d8fcbc28d8.jpg'},
        {image: '5dbf9f5fc858f.jpg'},
        {image: '5e41279b15fb7.jpg'},
        {image: '5e411b81c7509.jpg'},
      ],
      // 通知公告
      notices: [
        {title: '中国耀华玻璃集团有限公司违规行为举报电话：18233580085', link: '/#/'},
        {title: '公 告', link: '/Detail/index/cid/070201/id/303/detail.html'},
        {title: '中国耀华玻璃集团有限公司社会化招聘 拟录...', link: '/Detail/index/cid/070201/id/441/detail.html'},
        {title: '中国耀华玻璃集团有限公司洛阳龙昊600t...', link: '/Detail/index/cid/070201/id/398/detail.html'},
        {title: '中国耀华玻璃集团有限公司洛阳龙昊600t...', link: '/Detail/index/cid/070201/id/397/detail.html'},
        {title: '公 告', link: '/Detail/index/cid/070201/id/304/detail.html'},
        {title: '公 告', link: '/Detail/index/cid/070201/id/303/detail.html'},
        {title: '中国耀华玻璃集团有限公司社会化招聘 拟录...', link: '/Detail/index/cid/070201/id/441/detail.html'},
        {title: '中国耀华玻璃集团有限公司洛阳龙昊600t...', link: '/Detail/index/cid/070201/id/398/detail.html'},
      ],
      // 图片新闻
      imageNews: [
        {
          image: '646c1c819a9cd.png',
          title: '冲刺年终 ㉘ | 优质浮法玻璃成员企业节能降耗初见成效',
          date: '2022-12-27',
          link: '/Detail/index/cid/070206/id/415/detail.html'
        },
        {
          image: '646c1bfbc85b1.png',
          title: '冲刺年终 ㉗ | 龙昊公司熔浮甲班 用实干铸就“工匠精神”',
          date: '2022-12-22',
          link: '/Detail/index/cid/070206/id/414/detail.html'
        },
        {
          image: '646c1b91d534d.png',
          title: '冲刺年终 ㉖ | 节能公司巾帼女将绽芳华',
          date: '2022-12-21',
          link: '/Detail/index/cid/070206/id/413/detail.html'
        },
        {
          image: '646c1aeeb3f94.png',
          title: '冲刺年终 ㉕ | 中联玻璃 青春向前冲',
          date: '2022-12-20',
          link: '/Detail/index/cid/070206/id/412/detail.html'
        },
        {
          image: '646c1a27d57c0.png',
          title: '冲刺年终 ㉔ | 凤阳凯盛聚团队之势，满弓劲发！',
          date: '2022-12-19',
          link: '/Detail/index/cid/070206/id/411/detail.html'
        },
      ],
      // 文字新闻
      activeTabIndex: 0,
      textNews: [
        // 企业新闻
        [
          {
            date: '09-19',
            link: '/Detail/index/cid/070202/id/449/detail.html',
            title: '叙情怀，话蓝图，耀华退休老领导 相约回“家”再团聚！'
          },
          {
            date: '09-08',
            link: '/Detail/index/cid/070202/id/448/detail.html',
            title: '职工之家•缘定耀华丨河北省新时代“职工之家”建设成果展示活动'
          },
          {
            date: '08-25',
            link: '/Detail/index/cid/070202/id/447/detail.html',
            title: '中国建材“善用资源日”开放活动 —走进耀华高新技术产业园'
          },
          {
            date: '07-23',
            link: '/Detail/index/cid/070202/id/446/detail.html',
            title: '河北省委书记倪岳峰在秦期间 到耀华调研指导工作'
          },
          {
            date: '07-11',
            link: '/Detail/index/cid/070202/id/445/detail.html',
            title: '市长丁伟到耀华高新技术产业园 新建生产线调研'
          },
          {
            date: '06-27',
            link: '/Detail/index/cid/070202/id/444/detail.html',
            title: '耀华集团隆重召开庆祝中国共产党成立 102周年暨“两优一先”'
          },
          {
            date: '05-23',
            link: '/Detail/index/cid/070202/id/426/detail.html',
            title: '最美科研先锋 | 耀华玻璃董淑娟：开拓创新深耕颜色玻璃数十载'
          },
          {
            date: '05-23',
            link: '/Detail/index/cid/070202/id/426/detail.html',
            title: '最美科研先锋 | 耀华玻璃董淑娟：开拓创新深耕颜色玻璃数十载'
          },
          {
            // 多余元素展示不出来
            date: '05-23',
            link: '/Detail/index/cid/070202/id/426/detail.html',
            title: '最美科研先锋 | 耀华玻璃董淑娟：开拓创新深耕颜色玻璃数十载'
          },
        ],
        // 微报道
        [
          {
            date: '04-27',
            link: '/Detail/index/cid/070203/id/438/detail.html',
            title: '耀华集团成员企业开展“五一”节前安全检查'
          },
          {
            date: '04-26',
            link: '/Detail/index/cid/070203/id/437/detail.html',
            title: '斗志昂扬 热血澎湃 | 耀华集团春季运动会圆满结束'
          },
          {
            date: '04-19',
            link: '/Detail/index/cid/070203/id/436/detail.html',
            title: '降本增效在行动 ⑧｜规范作业的“指导书”--可视化操作规程'
          },
          {
            date: '04-18',
            link: '/Detail/index/cid/070203/id/435/detail.html',
            title: '降本增效在行动 ⑦｜预防问题的“警报器”--质量控制地图'
          },
          {
            date: '04-17',
            link: '/Detail/index/cid/070203/id/434/detail.html',
            title: '降本增效在行动 ⑥｜中联玻璃过程控制的“指南针”—工程师日志'
          },
          {
            date: '04-12',
            link: '/Detail/index/cid/070203/id/433/detail.html',
            title: '中国机械冶金建材工会领导到耀华调研'
          },
          {
            date: '04-06',
            link: '/Detail/index/cid/070203/id/432/detail.html',
            title: '中联玻璃荣获国家级“绿色工厂”'
          },
          {
            date: '03-22',
            link: '/Detail/index/cid/070203/id/431/detail.html',
            title: '奏响春之声，弘华特玻退城进园项目开工'
          },
          {
            // 多余元素展示不出来
            date: '03-22',
            link: '/Detail/index/cid/070203/id/431/detail.html',
            title: '奏响春之声，弘华特玻退城进园项目开工'
          },
        ]
      ],
      // 产品中心
      products: [
        {
          image: '5e158a08983f8.jpg',
          title: '平板玻璃',
          description: '该产品适用于眼镜、镜框、汽车玻璃、建筑及各种加工产品使用。...',
          link: '/Content/browse/cid/07030901'
        },
        {
          image: '5e1d165986c57.jpg',
          title: '镀膜玻璃',
          description: '集采光、节能、装饰和环保等多功能为一体，是现代建筑的集中体现...',
          link: '/Content/browse/cid/07030907'
        },
        {
          image: '63903803734fe.png',
          title: '特种玻璃',
          description: '主要应用在高档建筑、高档玻璃加工和太阳能光电幕墙领域以及高档...',
          link: '/Content/browse/cid/07030908'
        },
        {
          image: '63904b0c035e8.jpg',
          title: '深加工玻璃',
          description: '广泛应用于制造门窗、幕墙、室内装修、家具、家用电器、手机屏幕...',
          link: '/Content/browse/cid/07030909'
        },
        {
          image: '5e15889215fbe.jpg',
          title: '汽车玻璃',
          description: '广泛用于商业建筑和民用建筑等方面。...',
          link: '/Content/browse/cid/07030906'
        },
        {
          image: '5e54e4535596b.jpg',
          title: '光伏玻璃',
          description: '该产品广泛用于加工薄膜太阳能电池背板和双玻组件背板。...',
          link: '/Content/browse/cid/07030903'
        },
      ],
      djTabs: [ "学习二十大", "导师带徒", "专题专栏", "青年工作"],
      djActiveTab: 0,
      djContents: [
        {
          notices: [
            { date: "2022-11-07", title: "耀华集团特种玻璃运营部成员企业学习党的二十大精神到班组、进车", link: "/Detail/index/cid/070609/id/352/detail.html" },
            { date: "2022-11-04", title: "蚌埠兴科学习宣传贯彻党的二十大精神系列活动精彩纷呈", link: "/Detail/index/cid/070609/id/351/detail.html" },
            { date: "2022-11-03", title: "内江公司组织开展党的二十大精神宣讲", link: "/Detail/index/cid/070609/id/350/detail.html" },
            { date: "2022-11-01", title: "佳星玻璃学习宣传贯彻二十大精神奋力开创高质量发展新局面", link: "/Detail/index/cid/070609/id/349/detail.html" },
            { date: "2022-10-28", title: "晶华公司迅速掀起学习贯彻党的二十大精神热潮", link: "/Detail/index/cid/070609/id/348/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070609"
        },
        {
          notices: [
            { date: "2020-01-19", title: "关于转发河北省人力资源和社会保障厅、河北省财政厅《关于全面推", link: "/Detail/index/cid/070604/id/209/detail.html" },
            { date: "2020-01-19", title: "师带徒情况汇报", link: "/Detail/index/cid/070604/id/208/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070604"
        },
        {
          notices: [
            { date: "2019-11-12", title: "70周年系列活动—歌咏比赛", link: "/Detail/index/cid/070607/id/159/detail.html" },
            { date: "2019-10-18", title: "70周年系列活动—观影活动", link: "/Detail/index/cid/070607/id/164/detail.html" },
            { date: "2019-11-13", title: "70周年系列活动—市诵读", link: "/Detail/index/cid/070607/id/166/detail.html" },
            { date: "2019-11-13", title: "70周年系列活动—趣味运动会", link: "/Detail/index/cid/070607/id/165/detail.html" },
            { date: "2019-11-12", title: "一岗双责制度", link: "/Detail/index/cid/070607/id/157/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070607"
        },
        {
          notices: [
            { date: "2022-08-31", title: "青春蝶变—耀华成员企业的青年成长记", link: "/Detail/index/cid/070605/id/325/detail.html" },
            { date: "2022-08-09", title: "我们俩与千里之外孩子们五天难忘的故事", link: "/Detail/index/cid/070605/id/324/detail.html" },
            { date: "2022-08-08", title: "全民健身日 一起来运动", link: "/Detail/index/cid/070605/id/323/detail.html" },
            { date: "2022-07-15", title: "耀华集团团委组织开展习近平总书记《论党的青年工作》专题学习", link: "/Detail/index/cid/070605/id/322/detail.html" },
            { date: "2022-06-30", title: "耀华集团团委开展参观玻璃博物馆、团史馆主题团日活动", link: "/Detail/index/cid/070605/id/321/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070605"
        },
      ],
      glTabs: [ "采购招标", "安全环保", "管理体系", "政策法规"],
      glActiveTab: 0,
      glContents: [
        {
          notices: [
            { date: "2023-02-09", title: "中国耀华玻璃集团有限公司 废旧物资处理招标公告", link: "/Detail/index/cid/070502/id/396/detail.html" },
            { date: "2019-11-05", title: "采购招标——碎玻璃需求信息", link: "/Detail/index/cid/070502/id/55/detail.html" },
            { date: "2019-11-05", title: "采购招标——生石灰需求信息", link: "/Detail/index/cid/070502/id/54/detail.html" },
            { date: "2019-11-05", title: "采购招标——白云石需求信息", link: "/Detail/index/cid/070502/id/53/detail.html" },
            { date: "2019-11-05", title: "采购招标——石灰石需求信息", link: "/Detail/index/cid/070502/id/52/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070502"
        },
        {
          notices: [
            { date: "2023-05-26", title: "2023年耀技公司自行监测", link: "/Detail/index/cid/070503/id/439/detail.html" },
            { date: "2023-05-25", title: "2022年耀技公司自行监测", link: "/Detail/index/cid/070503/id/440/detail.html" },
            { date: "2020-01-19", title: "秦皇岛耀华玻璃技术开发有限公司排污许可证", link: "/Detail/index/cid/070503/id/206/detail.html" },
            { date: "2020-01-19", title: "秦皇岛弘耀节能玻璃有限公司排污许可证", link: "/Detail/index/cid/070503/id/205/detail.html" },
            { date: "2020-01-19", title: "秦皇岛弘华特种玻璃有限公司排污许可证", link: "/Detail/index/cid/070503/id/204/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070503"
        },
        {
          notices: [
            { date: "2020-01-13", title: "管理方针", link: "/Detail/index/cid/070504/id/192/detail.html" },
            { date: "2020-01-13", title: "体系建设", link: "/Detail/index/cid/070504/id/193/detail.html" },
            { date: "2019-11-08", title: "中国耀华玻璃集团有限公司质量管理体系认证", link: "/Detail/index/cid/070504/id/101/detail.html" },
            { date: "2019-11-08", title: "中国耀华玻璃集团有限公司职业健康安全管理体系认证", link: "/Detail/index/cid/070504/id/103/detail.html" },
            { date: "2019-11-08", title: "中国耀华玻璃集团有限公司环境管理体系认证", link: "/Detail/index/cid/070504/id/102/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070504"
        },
        {
          notices: [
            { date: "2019-11-05", title: "重大事故隐患管理规定", link: "/Detail/index/cid/070505/id/46/detail.html" },
            { date: "2019-11-05", title: "特种设备安全监察条例", link: "/Detail/index/cid/070505/id/45/detail.html" },
            { date: "2019-11-05", title: "建设项目职业卫生“三同时”监督管理暂行办法", link: "/Detail/index/cid/070505/id/44/detail.html" },
            { date: "2019-11-05", title: "劳动防护用品监督管理规定", link: "/Detail/index/cid/070505/id/43/detail.html" },
            { date: "2019-11-05", title: "中华人民共和国大气污染防治法(2018修正)", link: "/Detail/index/cid/070505/id/42/detail.html" }
          ],
          moreLink: "/Content/browse/cid/070505"
        },
      ],
      isFollowVisible: true // 控制是否显示关注部分，默认为显示
    };
  },
  mounted() {
    this.$emit('update-carousel', this.items);
  },
  methods: {
    handleToIndex() {
      window.location.reload();
    },
    openInCurrentTab(url) {
      window.open(url, '_self');
    },
  },
};
</script>

<style scoped lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

@import "./HomePage.less";
</style>
