<template>
<div id="main">
    <div id="subnav">
        <div class="inner">
            <ul>
                <li class="nli">
                    <h4><a href="/#/about">集团简介</a></h4>
                </li>
                <li class="nli">
                    <h4><a href="/#/about/organization">组织机构</a></h4>
                </li>
                <li class="nli">
                    <h4><a href="/#/about/honor">资质荣誉</a></h4>
                </li>
                <li class="nli">
                    <h4><a href="/#/about/culture">企业文化</a></h4>
                </li>
                <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
            </ul>
        </div>
    </div>
    <div class="custom-collapse">

        <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
            <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                <template #title>
                    <div class="title-container">
<!--                        <img :src="item.imgSrc">-->
                        <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                        <span>{{ item.companyName }}</span>
                    </div>
                </template>
                <div>来源：发布日期：{{ item.publishDate }}</div>
                <div>{{ item.content }}</div>
                <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
            </el-collapse-item>
        </el-collapse>
        <el-pagination id="pagination"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="5"
                layout="prev, pager, next, jumper"
                :total="collapseItems.length"
        />
    </div>
</div>
</template>

<script>
    export default {
        name: "aboutSubsidiary",
        data() {
            return {
                activeNames: [],
                items: [
                    {image: '5e156e89e6e27.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '耀华（秦皇岛）玻璃技术开发有限公司',
                        publishDate: '2022-12-07',
                        content: '公司成立于 2010 年 5 月 注册资金 7 亿余元人民币，占地面积 327 亩，现有员工 200 余人 ...',
                        moreLink: '/#/about/subsidiary/1'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '耀华（秦皇岛）节能玻璃有限公司',
                        publishDate: '2022-12-07',
                        content: '公司成立于 2010 年 5 月 注册资金 7 亿余元人民币，占地面积 327 亩，现有员工 200 余人 ...',
                        moreLink: '/#/about/subsidiary/2'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '河南省中联玻璃有限责任公司',
                        publishDate: '2022-12-07',
                        content: '公司坐落于河南省商丘市，占地 523 亩，注册资金 5.17 亿元，总资产 25.3 亿元，员工 660 余人。 拥...',
                        moreLink: '/#/about/subsidiary/3'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '洛玻集团洛阳龙昊玻璃有限公司',
                        publishDate: '2022-12-07',
                        content: '公司成立于2005 年 6月，位于河南省洛阳市汝阳县大安工业区，占地 420 余亩， 注册资金 4.63 亿元。 专...',
                        moreLink: '/#/about/subsidiary/4'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '凯盛晶华玻璃有限公司',
                        publishDate: '2022-12-07',
                        content: '凯盛晶华玻璃有限公司坐落于山东省德州市，占地面积 37 万平方米，资产总额 13 亿元。 现有 1 条 600T/D...',
                        moreLink: '/#/about/subsidiary/5'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '中建材佳星玻璃（黑龙江）有限公司',
                        publishDate: '2022-12-07',
                        content: '中建材佳星玻璃（黑龙江）有限公司始建于 1982 年 5 月，前身为佳木斯工农玻璃厂，位于黑龙江省佳木斯市高新技术产业园区，企业...',
                        moreLink: '/#/about/subsidiary/6'
                    },
                    {
                        name: '7',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '耀华（宜宾）玻璃有限公司',
                        publishDate: '2022-12-07',
                        content: '耀华（宜宾）玻璃有限公司成立于 2012 年 7 月，位于四川省宜宾市珙县余箐工业园区，占地 590 亩（余箐园区 450 亩，...',
                        moreLink: '/#/about/subsidiary/7'
                    },
                    {
                        name: '8',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '中建材（内江）玻璃高新技术有限公司',
                        publishDate: '2022-12-07',
                        content: '公司位于四川省内江市资中县水南镇，隶属于中国建材集团凯盛科技公司，成立于 2012 年 6 月，是一家以低辐射（Low-E）节能...',
                        moreLink: '/#/about/subsidiary/8'
                    },
                    {
                        name: '9',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '蚌埠兴科玻璃有限公司',
                        publishDate: '2022-12-07',
                        content: '蚌埠兴科玻璃有限公司是凯盛光伏材料有限公司的全资子公司，现托管于中国耀华玻璃集团有限公司。公司成立于 1999 年，位于蚌埠市国...',
                        moreLink: '/#/about/subsidiary/9'
                    },
                    {
                        name: '10',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '中建材（濮阳）光电材料有限公司',
                        publishDate: '2022-12-07',
                        content: '公司成立于2017年 3月，位于河南省濮阳县产业集聚区，注册资金 24000万元，总投资 8.03 亿，占地 308 亩，目前拥...',
                        moreLink: '/#/about/subsidiary/10'
                    },
                    {
                        name: '11',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '蚌埠凯盛玻璃有限公司',
                        publishDate: '2022-12-07',
                        content: '蚌埠凯盛玻璃有限公司位于蚌埠市淮上经济开发区，成立于 2021 年 11 月 1 日，注册资本 25000 万元，职工 290 ...',
                        moreLink: '/#/about/subsidiary/11'
                    },
                    {
                        name: '12',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '秦皇岛弘华特种玻璃有限公司',
                        publishDate: '2022-12-07',
                        content: '秦皇岛弘华特种玻璃有限公司成立于 2009 年，注册资金为 6547 万，员工人数为163人，前身为“秦皇岛耀华特种玻璃股份有限...',
                        moreLink: '/#/about/subsidiary/12'
                    },
                    {
                        name: '13',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        companyName: '凤阳凯盛硅材料有限公司',
                        publishDate: '2022-12-07',
                        content: '凤阳凯盛硅材料有限公司坐落在凤阳经济开发区产业园，成立于 2009 年 10 月 26 日，占地 200 亩，注册资金 3.33...',
                        moreLink: '/#/about/subsidiary/13'
                    },
                ],
                currentPage: 1 // 当前页数

            };
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 5;
                const endIndex = startIndex + 5;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style>
    #subnav,
    .custom-collapse {
        margin-bottom: 20px; /* 调整模块之间的垂直间距，根据需要进行调整 */
    }
    .custom-collapse {
        background-color: #f0f0f0; /* 这里更换为您想要的灰色背景色 */
    }

    #main {
        width: 60%; /* 或者您想要的任何宽度 */
        margin: 0 auto; /* 将元素水平居中 */
    }
    #main {
        background-color: white; /* 设置背景色为灰色 */
    }
    .title-container {
        display: flex;
        align-items: center; /* 垂直居中对齐 */
    }

    .title-container img {
        margin-right: 10px; /* 图片与文字之间的间距，根据需要调整 */
    }
    #collapse {
        background-color: rgba(255, 255, 255, 0);
    }
    .more {
        color: orange;
        transition: color 0.3s ease;
    }
    .more:hover {
        color: darkorange; /* Change to a slightly darker shade on hover */
    }
    #pagination {
        display: flex;
        justify-content: center;
    }
    .el-pagination .number.active {
        color: grey !important;
    }
</style>
