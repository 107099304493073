import { render, staticRenderFns } from "./Special.vue?vue&type=template&id=54a095ca&scoped=true"
import script from "./Special.vue?vue&type=script&lang=js"
export * from "./Special.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a095ca",
  null
  
)

export default component.exports