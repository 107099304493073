<template>
  <div id="main">
    <div class="subnav">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductCenter' }">主导产品</el-breadcrumb-item>
        <el-breadcrumb-item @click.native="$router.go(0)">国内销售</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="inner">
      <div class="map">
        <div class="cn"><img src="@/components/images/cnmap.png"></div>

        <div class="dq dbdq" @click="toggleList('dbdq')">
          <h4>东北地区</h4>
          <span class="img"><img src="@/components/images/dbdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['dbdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('dbdq')">关闭</span>
          <h5>东北地区</h5>
          <div class="li">
            <h6>中建材佳星玻璃（黑龙江）有限公司</h6>
            <p></p>
            <p>主要产品：优质浮法玻璃、离线Low-E玻璃、平/弯钢化玻璃、中空玻璃、夹层玻璃、装饰玻璃等</p>
            <p>电话：0086-454-6075518 6075900 6075252</p>
            <p>邮箱：872569823@qq.com</p>
            <p></p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-454-6075518 6075900 6075252</p>
          </div>
        </div>

        <div class="dq hbdq" @click="toggleList('hbdq')">
          <h4>华北地区</h4>
          <span class="img"><img src="@/components/images/hbdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['hbdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('hbdq')">关闭</span>
          <h5>华北地区</h5>
          <div class="li">
            <h6>耀华（秦皇岛）玻璃技术开发有限公司</h6>
            <p>主要产品：优质浮法白玻、钢化家电隔板玻璃、彩晶印刷玻璃</p>
            <p>地址：河北省秦皇岛市海港区西港北路 55 号</p>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
          <div class="li"><h6>耀华（秦皇岛）节能玻璃有限公司</h6>
            <p>主要产品：本体着色玻璃、在线热反射膜玻璃</p>
            <p>地址：秦皇岛市海港区揽月街 25 号</p>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
          <div class="li">
            <h6>秦皇岛弘华特种玻璃有限公司</h6>
            <p>主要产品：硼硅2.6家电玻璃、硼硅3.3耐热玻璃、硼硅4.0防火玻璃</p>
            <p>地址：河北省秦皇岛市海港区北部工业区揽月街 29 号</p>
            <p>电话：0086-335-7092443 7092444</p>
            <p>邮箱：13730350701@163.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7092443 7092444</p>
          </div>
        </div>

        <div class="dq hddq" @click="toggleList('hddq')">
          <h4>华东地区</h4>
          <span class="img"><img src="@/components/images/hddq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['hddq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('hddq')">关闭</span>
          <h5>华东地区</h5>
          <div class="li">
            <h6>凯盛晶华玻璃有限公司</h6>
            <p>主要产品：优质浮法白玻</p>
            <p>地址：山东省德州市德城区天衢工业园凯盛晶华路 666 号</p>
            <p>电话：0086-534-7858866 7858999</p>
            <p>邮箱： nijunhu1976@163.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-534-7858866 7858999</p>
          </div>
          <div class="li">
            <h6>蚌埠凯盛玻璃有限公司</h6>
            <p>主要产品：高效薄膜太阳能电池用高温玻璃、颜色节能玻璃、工业显示玻璃、汽车用玻璃</p>
            <p>地址：安徽省蚌埠市淮上区长征北路 801 号</p>
            <p>电话：0086-552-4081378</p>
            <p>邮箱：BBKSGLASS@163.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-552-4081378</p>
          </div>
          <div class="li">
            <h6>凤阳凯盛硅材料有限公司</h6>
            <p>主要产品：硼硅 4.0 防火玻璃、硼硅 3.3 耐热玻璃</p>
            <p>地址：滁州市凤阳县经济开发区产业园凤翔大道 66 号</p>
            <p>电话：0086-550-6670206</p>
            <p>邮箱：fyks@ctiec.net</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-550-6670206</p>
          </div>
          <div class="li">
            <h6>蚌埠兴科玻璃有限公司</h6>
            <p>主要产品：低辐射（Low-E）单银/双银节能玻璃、CIGS 薄膜太阳能电池背电极、阳光控制镀膜玻璃、Low-E 中空玻璃、普通中空玻璃、平/弯钢化玻璃、夹胶玻璃、防弹玻璃、防火玻璃、调光玻璃、彩釉玻璃、LED发光玻璃、电动中空百叶玻璃等</p>
            <p>地址：安徽省蚌埠市高新区中粮大道 2011 号</p>
            <p>电话：0086-552-2861008</p>
            <p>邮箱：xsb@bbxkbl.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-552-2861008</p>
          </div>
        </div>

        <div class="dq hzdq" @click="toggleList('hzdq')">
          <h4>华中地区</h4>
          <span class="img"><img src="@/components/images/hzdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['hzdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('hzdq')">关闭</span>
          <h5>华中地区</h5>
          <div class="li">
            <h6>河南省中联玻璃有限责任公司</h6>
            <p></p>
            <p>主要产品：汽车玻璃原片、光伏玻璃原片、离线 Low-E 镀膜玻璃、中空玻璃、钢化玻璃、家电玻璃等</p>
            <p>地址：商丘市睢阳产业集聚区工业大道北经二路西</p>
            <p>电话：0086-370-2597025</p>
            <p>邮箱：TV0815@126.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-370-2597025</p>
          </div>
          <div class="li">
            <h6>郑州炜盛玻璃有限公司</h6>
            <p></p>
            <p>主要产品：汽车玻璃、工程车窗、大幕墙、家电玻璃等</p>
            <p>地址：河南省郑州市新郑市龙湖镇小湖路西段</p>
            <p>电话：0086-371-69900088 13938238899</p>
            <p>邮箱：sales@wd-chen.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-371-69900088 13938238899</p>
          </div>
          <div class="li">
            <h6>湖北恒耀玻璃股份有限公司</h6>
            <p></p>
            <p>主要产品：夹层玻璃、钢化玻璃、防火玻璃、丝网玻璃、磨砂玻璃、自洁玻璃、家电玻璃</p>
            <p>地址：湖北省黄石市下陆区工人文化宫南路 18 号</p>
            <p>电话：0086-0714-6290372 13329988001</p>
            <p>邮箱：hyglass2022@163.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-0714-6290372 13329988001</p>
          </div>
        </div>

        <div class="dq hndq" @click="toggleList('hndq')">
          <h4>华南地区</h4>
          <span class="img"><img src="@/components/images/hndq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['hndq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('hndq')">关闭</span>
          <h5>华南地区</h5>
          <div class="li">
            <h6>广东省恒大玻璃有限公司</h6>
            <p></p>
            <p>主要产品：建筑玻璃、家电玻璃、工业玻璃</p>
            <p>地址：广东省广州市黄埔区南岗镇岭南大道东段 19 号</p>
            <p>电话：0086-20-86828588</p>
            <p>邮箱：jiangyongqing@evergrande.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-20-86828588</p>
          </div>
        </div>

        <div class="dq xbdq" @click="toggleList('xbdq')">
          <h4>西北地区</h4>
          <span class="img"><img src="@/components/images/xbdq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['xbdq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('xbdq')">关闭</span>
          <h5>西北地区</h5>
          <div class="li">
            <h6>国内</h6>
            <p></p>
            <p>电话：0086-831-4092715</p>
            <p>邮箱：yhybxsb@126.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-831-4092715</p>
          </div>
        </div>

        <div class="dq xndq" @click="toggleList('xndq')">
          <h4>西南地区</h4>
          <span class="img"><img src="@/components/images/xndq.png"></span>
        </div>
        <div class="list" :style="{ display: showList['xndq'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('xndq')">关闭</span>
          <h5>西南地区</h5>
          <div class="li">
            <h6>耀华（宜宾）玻璃有限公司</h6>
            <p></p>
            <p>主要产品：优质浮法白玻、离线 Low-E玻璃</p>
            <p>地址：珙县工业集中区余箐园后勤服务中心</p>
            <p>电话：0086-831-4092715</p>
            <p>邮箱：yhybxsb@126.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-831-4092715</p>
          </div>
          <div class="li">
            <h6>中建材（内江）玻璃高新技术有限公司</h6>
            <p></p>
            <p>
              主要产品：低辐射（Low-E）双银/三银节能玻璃、热反射镀膜玻璃、中空玻璃、钢化玻璃、弯钢玻璃、夹层玻璃、高端防火玻璃、高硼硅玻璃、发电幕墙玻璃、家电玻璃、光伏背板玻璃等</p>
            <p>地址：四川省内江市资中县水南镇凤翔东路 2 号</p>
            <p>电话：0086-199-3866-3771 191-4032-7812</p>
            <p>邮箱：461715121@qq.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-199-3866-3771 191-4032-7812</p>
          </div>
        </div>

        <div class="dq twd" @click="toggleList('twd')">
          <h4>台湾岛</h4>
          <span class="img"><img src="@/components/images/twd.png"></span>
        </div>
        <div class="list" :style="{ display: showList['twd'] ? 'block' : 'none' }">
          <span class="close" @click="hideList('twd')">关闭</span>
          <h5>台湾岛</h5>
          <div class="li">
            <h6>国内</h6>
            <p>电话：0086-335-7511749 7511748</p>
            <p>邮箱：internationaltrade@glassyaohua.com</p>
            <p>联系人:&nbsp;&nbsp;联系电话：0086-335-7511749 7511748</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DomesticSales",
  data() {
    return {
      // 轮播图
      items: [
        {image: '5e1571ffb71c7.jpg'},
        {image: '63903952aa8f4.png'},
      ],
      showList: {
        dbdq: false,
        hbdq: false,
        hzdq: false,
        hddq: false,
        hndq: false,
        xbdq: false,
        xndq: false,
        twd: false,
      }
    };
  },
  created() {
    this.updateCarousel(this.items);
  },
  mounted() {
  },
  methods: {
    updateCarousel(items) {
      this.$emit('update-carousel', items);
    },
    toggleList(region) {
      // 切换显示状态
      this.showList[region] = !this.showList[region];
      // 如果有其他地区的列表显示，则隐藏其他地区的列表
      Object.keys(this.showList).forEach(key => {
        if (key !== region) {
          this.showList[key] = false;
        }
      });
    },
    hideList(region) {
      this.showList[region] = false;
    },
  }
};
</script>

<style scoped lang="less">
#main {
  padding: 0 0 50px;
  width: 100%;
  background: #f5f5f5 url(@/components/images/mainbg.png) no-repeat bottom center;

  .subnav {
    display: flex;
    width: 1920px;
    height: 60px;
    margin: 0 auto;
    padding-left: 10px;
    align-items: center;
    background-color: #f0f0f0;

    a {
      text-decoration: none;

      &:hover {
        color: #e60012;
      }
    }

    span {
      cursor: pointer;
      font-weight: bold;
      color:#333;
    }

    .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner {
          &:hover {
            color: #e60012;
          }
        }
      }
    }
  }

  .inner {
    position: relative;

    .map {
      position: relative;
      width: 1200px;
      height: 861px;
      margin-bottom: 50px;

      .cn {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        img {
          display: block;
          background-color: transparent;
        }
      }

      .dq {
        position: absolute;
        cursor: pointer;
        z-index: 3;

        img {
          opacity: 0;
          transition: opacity 0.5s;
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }

      .dbdq {
        width: 233px;
        height: 340px;
        top: 10px;
        right: 168px;

        h4 {
          top: 180px;
          left: 95px;
        }
      }

      .hbdq {
        width: 455px;
        height: 448px;
        top: 19px;
        right: 315px;
        z-index: 4;

        h4 {
          top: 270px;
          left: 260px;
        }
      }

      .xbdq {
        width: 657px;
        height: 452px;
        top: 84px;
        left: 11px;
        z-index: 4;

        h4 {
          top: 250px;
          left: 320px;
        }
      }

      .xndq {
        width: 606px;
        height: 408px;
        bottom: 87px;
        left: 45px;

        h4 {
          top: 180px;
          left: 340px;
        }
      }

      .hzdq {
        width: 159px;
        height: 278px;
        bottom: 160px;
        right: 427px;
        z-index: 4;

        h4 {
          top: 115px;
          left: 55px;
        }
      }

      .hndq {
        width: 267px;
        height: 187px;
        bottom: 13px;
        right: 402px;
        z-index: 4;

        h4 {
          top: 55px;
          left: 90px;
        }
      }

      .hddq {
        width: 178px;
        height: 374px;
        bottom: 112px;
        right: 300px;

        h4 {
          top: 150px;
          left: 58px;
        }
      }

      .twd {
        width: 36px;
        height: 79px;
        bottom: 112px;
        right: 300px;

        h4 {
          top: 20px;
          left: 0;
        }
      }

      h4 {
        width: 90px;
        position: absolute;
        top: 0;
        z-index: 5;
      }

      .list {
        position: absolute;
        top: 125px;
        left: 0;
        width: 1180px;
        height: 550px;
        padding: 10px;
        border: 1px #ccc solid;
        background: white;
        z-index: 6;
        display: none;
        overflow: hidden;
        overflow-y: auto;

        h5 {
          font-size: 16px;
          color: #138bdc;
          text-align: center;
          padding-bottom: 10px;
          border-bottom: 1px #ccc solid;
        }

        .close {
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          padding: 2px 10px;
          background: #f4f4f4;
          color: #666;
          border-radius: 3px;

          &:hover {
            background: #ddd;
          }
        }

        .li {
          padding: 10px;
          width: 550px;
          float: left;
          margin: 0 5px;
          border-bottom: 1px #ccc dashed;

          p {
            color: #666;
          }

          h6 {
            font-size: 16px;
            padding-bottom: 5px;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>
