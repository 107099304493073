export const questions = [
    {
        name: '1',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '玻璃为什么是透明的？',
        content: '由于普通的磷酸盐、硼酸盐、硅酸盐的化学键键能相当大，由电子激发而引起的本身吸收处于紫外区，在可见光和近红外一般没有光吸收，因此，玻璃没有颜色。同时，玻璃分子宏观上是均匀的，不存在能引起光散射的微粒，并且玻璃表面是光滑的，也不会导致光的散射，因此玻璃是透明的。',
    },
    {
        name: '2',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '浮法玻璃相对于普通玻璃有哪些优势？',
        content: '浮法玻璃平整度高，透明度好， 浮法玻璃自动化生产，生产效率更高，浮法玻璃质量好，玻璃利用率高。',
    },
    {
        name: '3',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '本体着色吸热玻璃特点？',
        content: '本体着色吸热玻璃就是在基础玻璃成分中加入特定的着色剂，使玻璃产生各种颜色，如蓝色、茶色、灰色、绿色等。使用这种玻璃的建筑物和汽车一方面可以满足审美的要求，更重要的是这种玻璃具有吸收线的功能，使安装这种玻璃的建筑物和交通工具能减少一部分太阳辐射，减少空调安装和运行费用。',
    },
    {
        name: '4',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '玻璃的存放条件、注意事项？',
        content: '（1）玻璃必须在有顶盖的干燥房间内保管，在运输途中和装卸时需有防雨设施。玻璃在贮存、运输、装卸时，箱盖向上，箱子不得平放或斜放。\n' +
            '（2）玻璃在运输时，箱子长度方向应平行运输前进方向，并采取措施防止倾倒、滑动。\n' +
            '（3）玻璃搁置时，应采取措施防止玻璃面和边缘受到损伤，一般情况玻璃应搁置在垫木上。\n' +
            '（4）当搁置的玻璃受到迎面风压或风吹时，有可能倒塌，应根据实际情况采取措施，防止玻璃被风吹倒。\n' +
            '（5）当用人力搬运玻璃时应符合下列规定:\n' +
            '①玻璃搬运时，其原伤痕可能进一步扩大，导致玻璃破损，危及人身安全，为此应避免玻璃在搬运过程中破损。\n' +
            '②玻璃搬运时，迎风的玻璃受到风力的作用，当玻璃面积较大或风力较强时，搬运者可能因承担不了上述荷载而跌倒或坠落。为此，搬运大面积玻璃应注意风向，以确保安全。',
    },
    {
        name: '5',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '玻璃发霉的原因及预防措施？',
        content: '发霉条件：玻璃表面不平整，长期阴暗的条件下。发霉原因：最初水或潮气吸附在玻璃的表面，随后水或潮气向玻璃内扩散，表面层中的可溶性硅酸盐被水解和破坏，首先硅酸钠和硅酸钾等被水解和破坏，形成的NaOH和二氧化硅。分离出的二氧化硅生成硅氧凝胶在玻璃表面形成保护性薄膜，阻止了进一步的侵蚀。水解形成的NaOH与空气中的二氧化碳作用生成碳酸钠，聚集在玻璃表面，构成表面膜中的可溶性盐。由于它的强吸水性而发生潮解，最后形成碱液小滴，当周围的温度湿度改变时，这些碱液小滴也随之改变。如果这些碱液小滴与玻璃长期接触凝胶状硅氧薄膜部分会被溶解，而使玻璃表面发生严重的局部侵蚀，形成斑点，这时钠离子会从玻璃本体中迁移出去，与空气发生反应生成白色富碱粒子群，扫描电镜可以观察到。\n' +
            '预防措施：在玻璃表面均匀撒上足量防霉粉，放置在干燥阴凉通风位置。',
    },
    {
        name: '6',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '玻璃为什么要退火，退火不好，对玻璃有何影响？',
        content: '退火是为了最大程度的消除玻璃制品中的残余应力，消除玻璃中的光学 均匀和稳定玻璃制品的内部结构，退火不好，玻璃的脆性会增加，容易破裂。',
    },
    {
        name: '7',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '中空玻璃如何才能增加它的节能性能？',
        content: '中空玻璃中充氩气、用LOW-E玻璃做中空玻璃、适当增加中空玻璃的空气层厚度12mm为宜、采用暖边系统、在夏季时间长的地方可将白玻换成色玻',
    },
    {
        name: '8',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '中空玻璃内的空气不会让膜层氧化？',
        content: '不会，因为中空玻璃中有分子筛，而分子筛始终令空气保持干燥，所以膜层不会被氧化。',
    },
    {
        name: '9',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '在海拔高度相差1000米以上的地方，对玻璃有什么影响，应怎样处理？',
        content: '会对玻璃产生影响，因为气压有差值，造成中空玻璃内外压强不一，导致中空玻璃的损伤。应在中空玻璃上插上毛细管。',
    },
    {
        name: '10',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '中空玻璃的两篇基片厚度有什么需求？',
        content: '两片基片的厚度相差不应超过3mm。',
    },
    {
        name: '11',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: '为什么空调旁边的玻璃容易破裂？',
        content: '因为热应力而破坏的,玻璃吸收热量,使玻璃本体膨胀;而处于铝框内部玻璃不能受到辐射,致使玻璃本体受热不均,内部热应力形成,玻璃中区热膨胀使边区产生张应力,超过抗张强度就会破裂',
    },
    {
        name: '12',
        imgSrc: require('@/../uploads/qicon2.jpg'),
        openImgSrc: require('@/../uploads/qicon.jpg'),
        questionName: 'LOW-E玻璃可减少多少紫外线？？',
        content: '相比热反射玻璃一般可减少约14%的紫外线，相比白玻减少25%的紫外线。。',
    },
];
