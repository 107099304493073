<template>
  <div id="header">
    <div class="inner">
      <div class="logo">
        <a href="/">
          <img src="@/../uploads/5f116cc5c98d1.jpg" alt="Logo">
        </a>
      </div>

      <div class="nav">
        <ul class="nav-list">
          <!-- 导航菜单项 -->
          <el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                   active-text-color="#de1c31" active-color="#de1c31" @select="handleSelect">
            <el-menu-item index="/">首页</el-menu-item>
            <el-submenu index="1">
              <template slot="title">关于耀华</template>
              <el-menu-item index="/about">集团简介</el-menu-item>
              <el-menu-item index="/about/organization">组织机构</el-menu-item>
              <el-menu-item index="/about/honor">资质荣誉</el-menu-item>
              <el-menu-item index="/about/culture">企业文化</el-menu-item>
              <el-menu-item index="/about/history">耀华历史</el-menu-item>
              <el-menu-item index="/about/subsidiary">子公司</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">新闻中心</template>
              <el-menu-item index="/news/notice/">通知公告</el-menu-item>
              <el-menu-item index="/news/companyNews/">企业新闻</el-menu-item>
              <el-menu-item index="/news/vReport/">微报道</el-menu-item>
              <el-menu-item index="/news/imgNews/">图片新闻</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">主导产品</template>
              <el-menu-item index="/product/ProductCenter">产品中心</el-menu-item>
              <el-menu-item index="/product/DomesticSales">国内销售</el-menu-item>
              <el-menu-item index="/product/ForeignSales">国际销售</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">科技创新</template>
              <el-menu-item index="/technology/center/1">国家企业技术中心</el-menu-item>
              <el-menu-item index="/technology/center/2">博士后科研工作站</el-menu-item>
              <el-menu-item index="/technology/center/3">工程技术创新中心</el-menu-item>
              <el-menu-item index="/technology/achievement">科研成果</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">企业管理</template>
              <el-menu-item index="/management/hr/">人力资源</el-menu-item>
              <el-menu-item index="/management/purchase/">采购招标</el-menu-item>
              <el-menu-item index="/management/security/">安全环保</el-menu-item>
              <el-menu-item index="/management/system/">管理体系</el-menu-item>
              <el-menu-item index="/management/statute/">政策法规</el-menu-item>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">党建工作</template>
              <el-menu-item index="/party/learn20/">学习二十大</el-menu-item>
              <el-menu-item index="/party/mentor/">导师带徒</el-menu-item>
              <el-menu-item index="/party/special/">专题专栏</el-menu-item>
              <el-menu-item index="/party/youthWork/">青年工作</el-menu-item>
              <el-menu-item index="/party/communication/">耀华通讯</el-menu-item>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title">联系我们</template>
              <el-menu-item index="/contact/ContactInformation">联系方式</el-menu-item>
              <el-menu-item index="/contact/OnlineMessage">在线留言</el-menu-item>
              <el-menu-item index="/contact/AfterSalesService">售后服务</el-menu-item>
              <el-menu-item index="/contact/CommonQuestion">常见问题</el-menu-item>
            </el-submenu>
          </el-menu>
        </ul>
      </div>
    </div>

    <!-- 二维码 -->
    <div>
      <transition name="follow-transition" @before-enter="beforeEnter" @enter="enter" @after-leave="afterLeave">
        <div v-if="isFollowVisible" class="follow">
          <span class="close" @click="closePage"></span>
          <h5>关注我们</h5>
          <div class="wx">
            <span class="p">微信公众号</span>
            <span class="img"><img src="@/../uploads/5da8198ce6877.png" alt="微信公众号"></span>
          </div>
          <div class="wb">
            <span class="p">官方微博</span>
            <span class="img"><img src="@/../uploads/5e65be660c335.png" alt="官方微博"></span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      activeIndex: '/',
      searchKeyword: '',
      isFollowVisible: true // 控制是否显示关注部分，默认为显示
    }
  },
  created() {
    this.activeIndex = this.$route.path;
  },
  methods: {
    closePage() {
      // 在这里实现关闭整体页面的逻辑
      this.isFollowVisible = false;
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "scale(1)";
    },
    enter(el, done) {
      setTimeout(() => {
        el.style.transition = "all 0.5s ease";
        el.style.transform = "scale(0.1)";
        el.style.opacity = 0;
      }, 50);
      setTimeout(() => {
        done();
      }, 500);
    },
    afterLeave() {
      // 在元素消失后的处理
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = key;
    },
  },
};
</script>

<style scoped lang="less">
#header {
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s;

  .inner {
    width: 1500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .logo {
      img {
        height: auto;
        object-fit: cover;
      }
    }

    .nav {
      ul.nav-list {
        .el-menu-demo {
          background-color: white;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          align-items: center;

          .el-menu-demo .el-menu-item,
          .el-menu-demo .el-submenu__title {
            white-space: nowrap;
            font-size: 14px;
            margin-right: 5px;
          }

          .el-menu-demo .el-submenu__title {
            color: #333;
          }

          .el-menu-demo .el-menu {
            border: none;
          }
        }
      }
    }
  }
}

// 关注我们
.follow {
  position: fixed;
  top: 30%;
  right: 10px;
  width: 120px;
  height: 265px;
  *height: 285px;
  background: white;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  *box-shadow: 0 2px 5px rgb(204, 204, 204);
  border-radius: 3px;
  z-index: 8;
}

.follow h5 {
  width: 100px;
  text-align: center;
  padding: 8px 0;
  border-bottom: 1px #ccc solid;
  margin: auto;
  color: #333;
  font-weight: normal;
}

.follow span {
  display: block;
}

.follow img {
  width: 67px;
  height: 67px;
}

.follow .wx span.p {
  height: 20px;
  line-height: 20px;
  padding: 8px 0 10px 22px;
  background: url(../../components/images/follow.png) no-repeat 10px center;
}

.follow .wb span.p {
  height: 20px;
  line-height: 20px;
  padding: 8px 0 10px 10px;
  background: url(../../components/images/follow-03.png) no-repeat 10px center;
}

.follow span.close {
  display: block;
  position: absolute;
  top: -10px;
  left: -15px;
  width: 30px;
  height: 30px;
  background: url(../../components/images/close.png) no-repeat center center;
  cursor: pointer;
}

.follow-transition-enter-active, .follow-transition-leave-active {
  transition: all 0.5s ease;
}

.follow-transition-enter, .follow-transition-leave-to /* .follow-transition-leave-active in <2.1.8 */ {
  transform: scale(1);
  opacity: 1;
}

.follow-transition-leave, .follow-transition-enter-to /* .follow-transition-enter-active in <2.1.8 */ {
  transform: scale(0.1);
  opacity: 0;
}
</style>
