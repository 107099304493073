<template>
  <div id="main">

    <div id="subnav">
      <div class="inner">
        <ul>
          <li class="nli">
            <h4><a href="/#/contact/ContactInformation">联系方式</a></h4>
          </li>
          <li class="nli">
            <h4><a href="/#/contact/OnlineMessage">在线留言</a></h4>
          </li>
          <li class="nli">
            <h4><a href="/#/contact/AfterSalesService">售后服务</a></h4>
          </li>
          <li>
            <h4><a href="/#/contact/CommonQuestion">常见问题</a></h4>
          </li>
        </ul>
      </div>
    </div>

    <div class="inner">
      <div class="left l"><img src="@/components/images/jishu.png"></div>
      <div class="form r">
        <div id="message">
          <!-- <form action="/Index/Content/message" method="post" id="message" name="message"> -->
          <div class="title">
            <h4>售后服务</h4>
            <span>请把您的问题留下，我们会及时回复</span>
          </div>
          <div><label><input type="text" name="name" v-model="formData.name" placeholder="姓名"></label></div>
          <div><label><input type="tel" name="tel" v-model="formData.tel" placeholder="电话"></label></div>
          <div class="select">
            <el-select v-model="selectedGlass" placeholder="请选择玻璃类型">
              <el-option
                  v-for="item in glassOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="textarea">
            <textarea name="content" class="textarea" v-model="formData.content" placeholder="请把您的需求留下，我们会尽快与您联系"></textarea>
          </div>
          <div class="yzm">
            <label><input type="text" name="code" ref="inputCode" v-model="formData.code" placeholder="验证码"></label>
            <span class="img">
              <canvas ref="captchaCanvas" width="100" height="40" @click="refreshCaptcha"
                      style="cursor:pointer;"></canvas>
            </span>
          </div>
          <div class="submit">
            <el-button plain @click="clickOpen">提 交</el-button>
            <!-- <label><input type="submit" id="submit" value="提 交"></label> -->
          </div>
          <!--  </form> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'AfterSalesService',
  data() {
    return {
      items: [
        {image: '5e0d91686ddb4.jpg'}
      ],
      selectedGlass: '',
      glassOptions: [
        { value: '平板玻璃', label: '平板玻璃' },
        { value: '镀膜玻璃', label: '镀膜玻璃' },
        { value: '特种玻璃', label: '特种玻璃' },
        { value: '深加工玻璃', label: '深加工玻璃' },
        { value: '汽车玻璃', label: '汽车玻璃' },
        { value: '光伏玻璃', label: '光伏玻璃' },
        { value: '电子玻璃', label: '电子玻璃' }
      ],
      currentCaptcha: '',
      formData: {
        name: '',
        tel: '',
        content: '',
        code: '',
        selectedGlass: '',
      },
    }
  },
  mounted() {
    this.$emit('update-carousel', this.items);
    // 初始化验证码
    this.refreshCaptcha();
  },
  methods: {
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    // 生成随机颜色
    randomColor(min, max) {
      let r = this.randomNum(min, max);
      let g = this.randomNum(min, max);
      let b = this.randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    },
    // 刷新验证码
    refreshCaptcha() {
      let canvas = this.$refs.captchaCanvas;
      let ctx = canvas.getContext('2d');
      // 绘制验证码
      this.currentCaptcha = this.drawCaptcha(ctx, canvas);
    },
    // 绘制验证码
    drawCaptcha(ctx, canvas) {
      let captcha = '';
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // 绘制背景
      ctx.fillStyle = this.randomColor(180, 255);
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 绘制文字
      for (let i = 0; i < 4; i++) {
        let char = String.fromCharCode(this.randomNum(65, 90));
        captcha += char;
        ctx.font = this.randomNum(20, 30) + 'px Arial';
        ctx.fillStyle = this.randomColor(50, 160);
        ctx.fillText(char, 10 + i * 20, this.randomNum(20, 30));
      }

      // 绘制干扰线
      for (let i = 0; i < 4; i++) {
        ctx.strokeStyle = this.randomColor(40, 180);
        ctx.beginPath();
        ctx.moveTo(this.randomNum(0, canvas.width), this.randomNum(0, canvas.height));
        ctx.lineTo(this.randomNum(0, canvas.width), this.randomNum(0, canvas.height));
        ctx.stroke();
      }

      // 绘制干扰点
      for (let i = 0; i < 40; i++) {
        ctx.fillStyle = this.randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(this.randomNum(0, canvas.width), this.randomNum(0, canvas.height), 1, 0, 2 * Math.PI);
        ctx.fill();
      }

      return captcha;
    },
    // 模拟提交
    clickOpen() {
      // 获取用户输入的验证码
      let inputCode = this.$refs.inputCode.value.trim();
      // 判断验证码是否为空
      if (!inputCode) {
        const h = this.$createElement;
        this.$notify.error({
          title: '错误',
          message: h('i', '请输入验证码'),
        });
        return; // 如果验证码为空，不执行后续逻辑
      }

      // 判断验证码是否正确
      if (inputCode.toUpperCase() === this.currentCaptcha.toUpperCase()) {
        const h = this.$createElement;
        this.$notify({
          title: '成功',
          type: 'success',
          message: h('i', '提交成功'),
        });
        this.submitForm();
      } else {
        const h = this.$createElement;
        this.$notify.error({
          title: '错误',
          message: h('i', '验证码错误，请重新输入'),
        });
        // 刷新验证码
        this.refreshCaptcha();
      }
    },
    // 提交成功后清空表单
    submitForm() {
      this.formData.name = '';
      this.formData.tel = '';
      this.formData.content = '';
      this.formData.code = '';
      this.selectedGlass = '';
    }
  },
};
</script>

<style lang="less">
.form {
  width: 680px;
  padding: 35px 35px 0 0;

  .title {
    border-bottom: 1px #ccc solid;
    color: #666;
    padding-bottom: 5px;
    margin-bottom: 10px;

    h4 {
      padding-right: 10px;
      font-size: 30px;
      color: #333;
      display: inline;
    }
  }

  .input input,
  input {
    width: 660px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border: 1px #ccc solid;
    margin-bottom: 10px;
  }

  textarea {
    color: #666;
    width: 660px;
    height: 200px;
    max-width: 660px;
    max-height: 200px;
    min-width: 660px;
    min-height: 200px;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px #ccc solid;
  }

  .select {
    width: 680px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 5px;
    padding: 0;
    border: 1px #ccc solid;

    .el-select {
      width: 680px;

      .el-input__inner {
        width: 680px;
        margin: 0;
        padding: 0 0 0 5px;
        border: none;
        border-radius: 0;
      }
    }
  }

  .yzm {
    width: 232px;
    position: relative;

    input {
      width: 130px;
      padding-right: 100px;
    }

    .img {
      display: block;
      width: 87px;
      height: 40px;
      position: absolute;
      top: 1px;
      right: 1px;
    }
  }

  .submit {
    .el-button:hover,
    .el-button:focus {
      border-color: #e60012;
      color: #e60012;
    }

    input {
      width: 130px;
      height: 40px;
      text-align: center;
      color: white;
      background: #03a9f4;
      border: none;
      font-size: 14px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
</style>
