<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4>
                            <a href="/#/management/hr">人力资源</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/purchase">采购招标</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/security">安全环保</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/system">管理体系</a>
                        </h4>
                    </li>
                    <li class="nli ">
                        <h4>
                            <a href="/#/management/statute">政策法规</a>
                        </h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="top">
            <div class="mbxdh">
                <ul>
                    <li><a href="/">首页</a></li> &gt;
                    <li><a href="/#/management/hr">企业管理</a></li> &gt;
                    <li><a href="/#/management/purchase">采购招标</a></li>
                </ul>
            </div>
        </div>
        <div class="custom-collapse">

            <el-collapse v-model="activeNames" @change="handleChange" id="collapse">
                <el-collapse-item v-for="(item, index) in paginatedItems" :key="index" :name="item.name">
                    <template #title>
                        <div class="title-container">
                            <!--                        <img :src="item.imgSrc">-->
                            <img :src="item.expanded ? item.openImgSrc : item.imgSrc">
                            <span>{{ item.titleName }}</span>
                        </div>
                    </template>
                    <div>来源：发布日期：{{ item.publishDate }}</div>
                    <div>{{ item.content }}</div>
                    <div><a :href="item.moreLink" class="more" style="display: inline;">了解更多</a></div>
                </el-collapse-item>
            </el-collapse>
            <el-pagination id="pagination"
                           @current-change="handleCurrentChange"
                           :current-page="currentPage"
                           :page-size="10"
                           layout="prev, pager, next, jumper"
                           :total="collapseItems.length"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "managementPurchase",
        data() {
            return {
                activeNames: [],
                items: [
                    {image: '5e7435be5c0db.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true,
                collapseItems: [
                    {
                        name: '1',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '中国耀华玻璃集团有限公司 废旧物资处理招标公告',
                        publishDate: '2023-02-09',
                        content: '中国耀华玻璃集团有限公司 废旧物资处理招标公告 中国耀华玻璃集团有限公司（以下简称耀华...',
                        moreLink: '/#/management/purchase'
                    },
                    {
                        name: '2',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '采购招标——碎玻璃需求信息',
                        publishDate: '2019-11-05',
                        content: '根据生产需要，近期我公司需要采购一批碎玻璃，欢迎能长期稳定提供相应资源的企业联系耀华集团采购部。 联系电话：0335...',
                        moreLink: '/#/management/purchase'
                    },
                    {
                        name: '3',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '采购招标——生石灰需求信息',
                        publishDate: '2019-11-05',
                        content: '根据生产需要，近期我公司需要采购一批生石灰，欢迎能长期稳定提供相应资源的企业联系耀华集团采购部。 联系电话：0...',
                        moreLink: '/#/management/purchase'
                    },
                    {
                        name: '4',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '采购招标——白云石需求信息',
                        publishDate: '2019-11-05',
                        content: '根据生产需要，近期我公司需要采购一批白云石，欢迎能长期稳定提供相应资源的企业联系耀华集团采购部。 联系电话：0335...',
                        moreLink: '/#/management/purchase'
                    },
                    {
                        name: '5',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '采购招标——石灰石需求信息',
                        publishDate: '2019-11-05',
                        content: '根据生产需要，近期我公司需要采购一批石灰石，欢迎能长期稳定提供相应资源的企业联系耀华集团采购部。 联系电话：0335...',
                        moreLink: '/#/management/purchase'
                    },
                    {
                        name: '6',
                        imgSrc: require('@/../uploads/qicon2.jpg'),
                        openImgSrc: require('@/../uploads/qicon.jpg'),
                        titleName: '采购招标——硅砂需求信息',
                        publishDate: '2019-10-17',
                        content: '根据生产需要，近期我公司需要采购一批硅砂，欢迎能长期稳定提供相应资源的企业联系耀华集团采购部。 联系电话：0335-...',
                        moreLink: '/#/management/purchase'
                    },
                ],
                currentPage: 1 // 当前页数
            }
        },
        computed: {
            paginatedItems() {
                // 根据当前页数和每页显示数量对折叠项数组进行分页
                const startIndex = (this.currentPage - 1) * 10;
                const endIndex = startIndex + 10;
                return this.collapseItems.slice(startIndex, endIndex);
            }
        },
        methods: {
            handleChange(names) {
                // 处理展开状态的改变
                // 在这里你可以根据需要更新数据模型中的 expanded 字段
                // 例如，根据 names 数组中的值来判断哪些项是展开的
                this.activeNames = names;
                // 你也可以根据 activeNames 数组中的项来更新数据模型中的 expanded 字段
                this.paginatedItems.forEach(item => {
                    item.expanded = this.activeNames.includes(item.name);
                });
            },
            handleCurrentChange(val) {
                // 处理当前页数变化
                this.currentPage = val;
            },


        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style scoped>
    #subnav,
    .custom-collapse {
        margin-bottom: 20px; /* 调整模块之间的垂直间距，根据需要进行调整 */
    }
    .custom-collapse {
        background-color: #f0f0f0; /* 这里更换为您想要的灰色背景色 */
    }

    #main {
        width: 60%; /* 或者您想要的任何宽度 */
        margin: 0 auto; /* 将元素水平居中 */
    }
    #main {
        background-color: white; /* 设置背景色为灰色 */
    }
    .title-container {
        display: flex;
        align-items: center; /* 垂直居中对齐 */
    }

    .title-container img {
        margin-right: 10px; /* 图片与文字之间的间距，根据需要调整 */
    }
    #collapse {
        background-color: rgba(255, 255, 255, 0);
    }
    .more {
        color: orange;
        transition: color 0.3s ease;
    }
    .more:hover {
        color: darkorange; /* Change to a slightly darker shade on hover */
    }
    #pagination {
        display: flex;
        justify-content: center;
    }
    .el-pagination .number.active {
        color: grey !important;
    }
</style>
