export const vReportItems = [
    {
        title: '耀华集团成员企业开展“五一”节前安全检查',
        publishDate: '2023-04-27',
        content: '耀华集团成员企业开展“五一”节前安全检查 耀华玻璃2023-04-27 21:46发表于河北为切实做好...',
        imgSrc: require('@/../uploads/646c310c611be.png')
    },
    {
        title: '斗志昂扬 热血澎湃 | 耀华集团春季运动会圆满结束',
        publishDate: '2023-04-26',
        content: '斗志昂扬 热血澎湃 | 耀华集团春季运动会圆满结束 耀华玻璃2023-04-26 19:27发表于河北 ...',
        imgSrc: require('@/../uploads/646c2f01f0d3f.png')
    },
    {
        title: '降本增效在行动 ⑧｜规范作业的“指导书”--可视化操作规程',
        publishDate: '2023-04-19',
        content: '降本增效在行动 ⑧｜规范作业的“指导书”--可视化操作规程 耀华玻璃2023-04-19 16:49发表于河北...',
        imgSrc: require('@/../uploads/646c2d919c6ed.png')
    },
    {
        title: '降本增效在行动 ⑦｜预防问题的“警报器”--质量控制地图',
        publishDate: '2023-04-18',
        content: '降本增效在行动 ⑦｜预防问题的“警报器”--质量控制地图 耀华玻璃2023-04-18 18:55发表于河北...',
        imgSrc: require('@/../uploads/646c2cb6ecaac.png')
    },
    {
        title: '降本增效在行动 ⑥｜中联玻璃过程控制的“指南针”—工程师日志',
        publishDate: '2023-04-17',
        content: '降本增效在行动 ⑥｜中联玻璃过程控制的“指南针”—工程师日志 耀华玻璃2023-04-17 18:07发表于河北...',
        imgSrc: require('@/../uploads/646c2c0c88ce6.png')
    },
    {
        title: '中国机械冶金建材工会领导到耀华调研',
        publishDate: '2023-04-12',
        content: '中国机械冶金建材工会领导到耀华调研 耀华玻璃2023-04-12 19:15发表于河北 4月11日，中国...',
        imgSrc: require('@/../uploads/646c2b9086be9.png')
    },
    {
        title: '中联玻璃荣获国家级“绿色工厂”',
        publishDate: '2023-04-06',
        content: '中联玻璃荣获国家级“绿色工厂” 耀华玻璃2023-04-06 19:28发表于河北 近日，国家工业和信息...',
        imgSrc: require('@/../uploads/646c2afa84113.png')
    },
    {
        title: '奏响春之声，弘华特玻退城进园项目开工',
        publishDate: '2023-03-22',
        content: '奏响春之声，弘华特玻退城进园项目开工 耀华玻璃2023-03-22 18:21发表于河北 3月21日上午...',
        imgSrc: require('@/../uploads/646c2999a048f.png')
    },
    {
        title: '合作共赢 共创未来 | 耀华集团2023年客户恳谈会成功举办',
        publishDate: '2023-03-15',
        content: '合作共赢 共创未来 | 耀华集团2023年客户恳谈会成功举办 耀华玻璃2023-03-15 21:38发表于河南 ...',
        imgSrc: require('@/../uploads/646c28fd5aca8.png')
    },
    {
        title: '连续三届参加全国两会，今年彭寿代表提出4件建议',
        publishDate: '2023-03-13',
        content: '连续三届参加全国两会，今年彭寿代表提出4件建议 耀华玻璃2023-03-13 15:15发表于河北 ...',
        imgSrc: require('@/../uploads/newimg.jpg')
    },
    {
        title: '锚定目标，凝心聚力|耀华集团召开2023年2月份经营分析会',
        publishDate: '2023-03-07',
        content: '锚定目标，凝心聚力|耀华集团召开2023年2月份经营分析会 耀华玻璃2023-03-07 20:15发表于河北 ...',
        imgSrc: require('@/../uploads/646c26b0673b2.png')
    },
    {
        title: '奋楫争先 ⑤ | 聚焦管理提升效益--前海联盟业务培训顺利举办',
        publishDate: '2023-03-06',
        content: '奋楫争先 ⑤ | 聚焦管理提升效益--前海联盟业务培训顺利举办 耀华玻璃2023-03-06 17:57发表于河北...',
        imgSrc: require('@/../uploads/646c2615ec1b0.png')
    },
]
