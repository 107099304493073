<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div class="top">
                <div router class="mbxdh">
                    <ul>
                        <li><a href="">首页</a></li> &gt;
                        <li><a href="/#/about">关于耀华</a></li> &gt;
                        <li><a href="/#/about/honor">资质荣誉</a></li>
                    </ul>
                </div>
            </div>
            <div id="content">
                <div class="content zizhi">
                    <div class="cot1">
                        <p>
                            &emsp;&emsp;中国耀华玻璃集团有限公司创建于1922年，是中国乃至远东第一家采用机器连续生产平板玻璃的企业，也是中国民族资本与当时国际上先进的玻璃生产技术相结合、较早采用股份公司组织形式的企业，被誉为“中国玻璃工业摇篮”。
                        </p>
                        <p>
                            &emsp;&emsp;在1995年度中国工业企业综合评价最优500家中名列第35位和建材行业第1名，在按营业额排序的1995年度中国500-有最大工业企业中位于第311位，在按资产净值排序 的“中国的脊梁”国有企业500强评选中位于279位。在企业自身发展的同时，为中国玻璃工业的发展输送了大批技术、管理人才和专业技术，为国家经济建设、为平板玻璃开辟国际市场作出了较大的贡献。先后荣获全国企业管理优秀奖、全国“五一”劳动奖状和全国思想政治工作优秀企业等荣誉称号。在第五十届国际统计大会上，被授予最高荣耀称号——“中国玻璃生产之王”。
                        </p>
                    </div>

                    <el-carousel :interval="4000" type="card" height="225px">
                        <el-carousel-item v-for="(item, index) in imgList" :key="index">
                            <img class="littleImg" :src="item.src" alt="carousel-image">
                            <p class="description">{{ item.description }}</p>
                        </el-carousel-item>
                    </el-carousel>

                    <div class="cot3">
                        <ul>
                            <li><a href="/#/about/honor/1" target="_blank">荣获2018-2019年度全国建材企业文化建设经典案例</a></li>
                            <li><a href="/#/about/honor/2" target="_blank">耀华集团质量管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/3" target="_blank">耀华集团职业健康安全管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/4" target="_blank">耀华集团环境管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/5" target="_blank">耀技质量管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/6" target="_blank">北方环境管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/7" target="_blank">北方职业健康安全管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/8" target="_blank">北方质量管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/9" target="_blank">弘华环境管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/10" target="_blank">弘华职业健康安全管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/11" target="_blank">弘华质量管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/12" target="_blank">弘耀环境管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/13" target="_blank">弘耀职业健康安全管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/14" target="_blank">弘耀质量管理体系认证证书</a></li>
                            <li><a href="/#/about/honor/15" target="_blank">河北省耀华玻璃镀膜工程技术研究中心</a></li>
                            <li><a href="/#/about/honor/16" target="_blank">博士后科研工作站</a></li>
                            <li><a href="/#/about/honor/17" target="_blank">被认定第四批国家企业技术中心</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "aboutHistory",
        props: {
            speed: Number,
            direction: String,
        },
        data() {
            return {
                imgList: [
                    { src: require('@/../uploads/5dc9fbd1cefec.jpg'), description: '荣获2018-2019年度全国建材企业文' },
                    { src: require('@/../uploads/5dc4c33cad3f5.jpg'), description: '耀华集团质量管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c2b7bc22a.jpg'), description: '耀华集团职业健康安全管理体系认证证书' },
                    { src: require('@/../uploads/5dc4be7b8de72.jpg'), description: '耀华集团环境管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c4257bc31.jpg'), description: '耀技质量管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c536441cd.jpg'), description: '北方环境管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c569752de.jpg'), description: '北方职业健康安全管理体系认证证书' },
                    { src: require('@/../uploads/5dc4e0b5909b2.jpg'), description: '北方质量管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c698404d0.jpg'), description: '弘华环境管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c6a8befe4.jpg'), description: '弘华职业健康安全管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c6ba098e2.jpg'), description: '弘华质量管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c7929cee9.jpg'), description: '弘耀环境管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c7a41c756.jpg'), description: '弘耀职业健康安全管理体系认证证书' },
                    { src: require('@/../uploads/5dc4c7bb01372.jpg'), description: '弘耀质量管理体系认证证书' },
                    { src: require('@/../uploads/5dc4df56ab7b5.jpg'), description: '河北省耀华玻璃镀膜工程技术研究中心' },
                    { src: require('@/../uploads/5dc4ddadecc5b.jpg'), description: '博士后科研工作站' },
                    { src: require('@/../uploads/5dc4d59b1179b.jpg'), description: '被认定第四批国家企业技术中心' },
                ],
                timer: null,
                theSpeed: this.speed,
                imgWidth: 260,
                theDirection: this.direction,
                items: [
                    {image: '5e156e89e6e27.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true // 控制是否显示关注部分，默认为显示
            }
        },
        methods: {
            clickLeft() {
                this.theDirection = 'left';
            },
            clickRight() {
                this.theDirection = 'right';
            },
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>


<style scoped lang='less'>

    /*资质荣誉*/
    /* 本例子css */

    .zizhiimg {
        width:1200px;
        overflow: hidden;
        position: relative;
        border-top: 5px solid #f4f4f4;
        border-bottom: 5px solid #f4f4f4;
        background: white;
        padding: 40px 0 30px;
        margin: 30px auto;
    }
    .zizhiimg .hd .prev, .zizhiimg .hd .next {
        display: block;
        position: absolute;
        top: 40px;
        width: 30px;
        height: 186px;
        overflow: hidden;
        cursor: pointer;
    }
    .zizhiimg .hd .prev {
        left: 0;
        background:url(../../components/images/prev.jpg) no-repeat center center;
    }
    .zizhiimg .hd .next {
        right: 0;
        background:url(../../components/images/next.jpg) no-repeat center center;
    }
    .zizhiimg .bd {
        padding: 0 0 0 45px;
    }
    .zizhiimg .bd ul {
        overflow: hidden;
        zoom: 1;
    }
    .zizhiimg .bd ul li {
        margin: 0 10px;
        float: left;
        _display: inline;
        overflow: hidden;
        text-align: center;
    }
    .zizhiimg .bd ul li .pic {
        text-align: center;
    }
    .zizhiimg .bd ul li .pic img {
        width: 255px;
        height: 192px;
        display: block;
        border: 1px solid #ccc;
    }
    .zizhiimg .bd ul li .pic a:hover img {
        border-color: #999;
    }
    .zizhiimg .bd ul li .title {
        line-height: 24px;
    }
    .zizhi .cot1 {
        /*text-indent:2em;*/
        line-height:2em;
    }
    .zizhi .cot3 {
        padding-bottom:50px;
    }
    .zizhi .cot3 li {
        padding:8px 0 8px 30px;
        display:inline-block;
        width:505px;
        margin:0 30px;
        vertical-align:top;
        background:url(../../components/images/liicon.png) no-repeat 10px 17px;
    }
    .zizhi .cot3 li {
        *display:inline;
    }
    .cot1,
    .cot3 {
        margin-bottom: 30px; /* 调整模块之间的垂直间距，根据需要进行调整 */
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: transparent;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
    }
    .el-carousel {
        max-width: 800px; /* 设置最大宽度为400px，你可以根据需要调整 */
        margin: 0 auto; /* 水平居中 */
    }
    .el-carousel__item {
        text-align: center; /* 水平居中内容 */
    }
    .item-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .description {
        position: absolute; /* 设置绝对定位 */
        bottom: 0; /* 底部对齐 */
        left: 0; /* 左对齐 */
        width: 100%; /* 宽度100%，与图片等宽 */
        background-color: rgba(0, 0, 0, 0); /* 背景色，黑色带透明度 */
        color: black; /* 字体颜色为白色 */
        padding: 10px; /* 内边距，增加可读性 */
        box-sizing: border-box; /* 内边距不增加宽度 */
        transform: translateY(1%); /* 向下平移，确保不覆盖图片 */
    }
    .carousel-container {
        position: relative;
    }

    .carousel-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 225px; /* 设置与 el-carousel 相同的高度 */
    }

    .carousel-item-wrapper {
        position: relative;
    }

    .littleImg {
        max-width: 100%; /* 保持图片宽度不超过容器 */
    }

    .description {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center; /* 居中文字 */
        background-color: rgba(0, 0, 0, 0); /* 背景颜色，可根据需要调整透明度 */
        color: black; /* 文字颜色 */
        padding: 10px; /* 设置文字与容器的间距 */
    }

</style>
