<template>
    <div>
        <!-- 头部 -->
        <header>
            <HeaderNavigation/>
        </header>
        <div id="main">
            <div class="inner">
                <div class="left l">
                    <div id="l_img">
                        <a class="example-image-link" href="@/../uploads/5e65ad4c55b25.jpg" data-lightbox="example-1" title="第一版">
                            <div>
                                <div :style="{ backgroundImage: 'url(' + require('@/../uploads/5e65ad4548fe1.jpg') + ')' }" class="jqthumb" style="width: 448px; height: 648px;"></div>
                            </div>

                        </a>
                    </div>
                    <div id="l_btm">
                        <span>2019年12月10日第12期</span>
                        <span>第一版</span>
                        <a class="download" href="/#/party/communication" target="_blank">PDF下载</a>
                        <a class="syb" href="javascript:;">上一版</a>
                        <a class="xyb" href="/#/party/communication">下一版</a>
                    </div>
                </div>
                <div class="right r">
                    <div id="r_top">
                        <div class="btn">
                            <span class="select">选择需要查看的年份</span>
                            <span class="wq">往期回顾</span>
                        </div>
                        <ul>
                            <li>
                                <a href="/#/party/communication">2019年</a>
                            </li>
                        </ul>
                    </div>
                    <div id="r_bm">
                        <div class="bmh">版面目录</div>
                        <div class="box">
                            <div class="cbrq">2019年12月10日第12期</div>
                            <ul>
                                <li>
                                    <a href="/#/party/communication">第一版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第二版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li>
                                    <a href="/#/party/communication">第三版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第四版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="box">
                            <div class="cbrq">2019年11月10日第11期</div>
                            <ul>
                                <li>
                                    <a href="/#/party/communication">第一版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第二版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li>
                                    <a href="/#/party/communication">第三版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第四版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="box">
                            <div class="cbrq">2019年10月10日第10期</div>
                            <ul>
                                <li>
                                    <a href="/#/party/communication">第一版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第二版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li>
                                    <a href="/#/party/communication">第三版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第四版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="box">
                            <div class="cbrq">2019年9月10日 第9期</div>
                            <ul>
                                <li>
                                    <a href="/#/party/communication">第一版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第二版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li>
                                    <a href="/#/party/communication">第三版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第四版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="box">
                            <div class="cbrq">2019年8月10日 第8期</div>
                            <ul>
                                <li>
                                    <a href="/#/party/communication">第一版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第二版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li>
                                    <a href="/#/party/communication">第三版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第四版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="box">
                            <div class="cbrq">2019年7月10日 第7期</div>
                            <ul>
                                <li>
                                    <a href="/#/party/communication">第一版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第二版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li>
                                    <a href="/#/party/communication">第三版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                                <li style="background: rgb(245, 245, 245);">
                                    <a href="/#/party/communication">第四版</a>
                                    <a class="pdf" href="/#/party/communication" target="_blank"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="clear"></div>
                        <div class="page">
                            <a class="prev" href="javascript:void(0);">上一页</a>
                            <a class="next" href="/#/party/communication">下一页</a>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <!-- 底部 -->
        <footer>
            <FooterNavigation/>
        </footer>
    </div>
</template>

<script>
    import HeaderNavigation from "@/components/top/HeaderNavigation";
    import FooterNavigation from "@/components/bottom/FooterNavigation";

    export default {
        name: "partyCommunication",
        data() {
            return {
            }
        },
        methods: {
        },
        components: {
            HeaderNavigation,
            FooterNavigation,
        },
    }
</script>

<style scoped>
    a:hover {
        text-decoration:none;
    }
    #main {
        background: rgba(0, 0, 0, 0) url(../../components/images/dzb.png) no-repeat top center;
        padding: 70px 0 50px;
        width: 100%;
    }
    #main .left {
        width:450px;
    }
    #main .left #l_img {
        border:1px #9c0c0c solid;
        width:448px;
        height:648px;
        margin-bottom:11px;
    }
    #main .left #l_btm {
        height:36px;
        background:#d9f3fb;
        line-height:36px;
        padding:0 10px;
    }
    #main .left #l_btm span {
        margin-right:5px;
    }
    #main .left #l_btm .download {
        padding-left:25px;
        background:url(../../components/images/pdficon.png) no-repeat left center;
        margin-right:5px;
    }
    #main .left #l_btm .syb {
        padding-left:20px;
        color:#1d68bc;
        background:url(../../components/images/jt.png) no-repeat left center;
    }
    #main .left #l_btm .xyb {
        padding-right:20px;
        color:#1d68bc;
        background:url(../../components/images/jt-02.png) no-repeat right center;
    }
    #main .right  {
        width:736px;

    }
    .inner {
        background-color: transparent !important;
    }
    #r_bm {
        background:white;
    }
    #r_top {
        background: rgba(0, 0, 0, 0);
    }
    #r_top ul {
        position:absolute;
        top:65px;
        left:0;
        background:white;
        width:235px;
        z-index:9;
        height:260px;
        overflow:hidden;
        overflow-y:auto;
        padding-bottom:10px;
        display:none;
    }
    #r_top ul li a{
        display:block;
        padding:0 15px;
        line-height:24px;
    }
    #r_top ul li a:hover {
        background:#f5f5f5;
    }
    #r_top .btn {
        width:330px;
        height:35px;
        line-height:35px;
        padding:30px 0;
    }
    #r_top .btn span {
        display:block;
        float:left;
        padding:0 15px;
        cursor:pointer;
    }
    #r_top .btn .select {
        width:190px;
        padding-right:30px;
        color:#999;
        background:white url(../../components/images/dateicon.png) no-repeat right center;
    }
    #r_top .btn .wq {
        background-color: transparent !important;
    }
    #r_bm .bmh {
        height:32px;
        line-height:32px;
        background:#1d68bc;
        color:white;
        text-align:center;
    }
    #r_bm .box {
        width:368px;
        float:left;
        padding-bottom:14px;
    }
    #r_bm .cbrq {
        background:#beeaf8;
        height:36px;
        line-height:36px;
        padding:0 30px;
    }
    #r_bm .box li {
        padding:0 30px;
        height:32px;
        line-height:32px;
        position:relative;
    }
    #r_bm .box li span {
        cursor:pointer;
    }
    #r_bm .box li a.pdf {
        display:block;
        width:32px;
        height:32px;
        background:url(../../components/images/pdficon.png) no-repeat right center;
        position:absolute;
        top:0;
        right:25px;
    }
    #r_bm .page {
        height:36px;
        line-height:36px;
        background:#beeaf8;
        text-align:center;
    }
    #r_bm .page a {
        color:#1d68bc;
        margin:0 5px;
    }
    #r_bm .page a.prev {
        padding-left:20px;
        background:url(../../components/images/jt.png) no-repeat left center;
    }
    #r_bm .page a.next {
        padding-right:20px;
        background:url(../../components/images/jt-02.png) no-repeat right center;
    }
</style>
